import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";

import { GetReportedIssues } from "../../utils/GetAPIUtils";

import DOMPurify from 'isomorphic-dompurify';
const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050; 
  }
`;

const ReportedIssueDetails = (props) => {

    const gContext = useContext(GlobalContext);
    const [reasonId, setReasonId] = useState(null);
    const [error, setError] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [reportedIssues, setReportedIssues] = useState([]);







    async function getUtils() {
        const response = await GetReportedIssues();
       
        if (response.data && response.data.issueReportList) {
          
            let list = [];
            //response.data.reportedIssues.filter(item => item["userid"] == 1).map((value) => (
            //  list.push({ value: value.id, label: value.reason })));

            // response.data.issueReportList.map((value) => (
            //    list.push({ userid: value.userid, avatar: value.avatar,fname:value.firstname,lname:value.lastname,date:value.datecreated,title:value.title,cat:value.issuecategory })));

            // console.log(list);
            setReportedIssues(response.data.issueReportList);
         

        }
    }

    function toggleModal() {

        props.togglemodal();
    }



    useEffect(() => {
        getUtils();
    }, []);


    return (
        <ModalStyled
            {...props}
            size="lg"
            centered
            show={props.modalvisible}
            onHide={toggleModal}
        >
            <Modal.Body className="p-0">
                <button
                    type="button"
                    className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
                    onClick={toggleModal}
                >
                    <i className="fas fa-times"></i>
                </button>
                {props.issue != null &&
                    <div className="row no-gutters w-100" >

                        <div className="col-md-12 col-12 w-100 px-5 py-5">

                            <div className="container px-0 py-0">
                                <div className="row justify-content-center">
                                    <div className="col-xl-12 col-lg-12  ">
                                        <div className="font-size-7 font-weight-semibold    text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                                            Reported Issue
                                        </div>
                                    </div>



                                    <div className="col-xl-12 col-lg-12  mx-auto ">
                                        <div className="bg-white rounded-4 border border-mercury shadow-9 pb-10">
                                            {/* <!-- Single Featured Job --> */}
                                            <div className="pt-9 pl-sm-9 pl-5 pr-sm-9 pr-5  border-bottom border-width-1 border-default-color light-mode-texts">
                                                <div className="row mb-0">
                                                    <div className="col-lg-6">

                                                        <a className="font-size-5 text-black-2 font-weight-semibold" rel="noopener noreferrer" target="blank">
                                                            Reported By
                                                        </a>

                                                    </div>

                                                    <div className="col-lg-6 pl-13 ">

                                                        <a className="font-size-5 text-black-2 font-weight-semibold" rel="noopener noreferrer" target="blank">
                                                            Reporter Email
                                                        </a>

                                                    </div>

                                                    <div className="col-lg-6">

                                                        <a className="font-size-4 text-gray mb-0 " rel="noopener noreferrer" target="blank">
                                                            {props.issue.firstname + " " + props.issue.lastname}
                                                        </a>

                                                    </div>
                                                    <div className="col-lg-6  pl-13">

                                                        <a className="font-size-4 text-gray mb-0 " rel="noopener noreferrer" target="blank">
                                                            {props.issue.email}
                                                        </a>

                                                    </div>
                                                    <div className="col-lg-6  pt-5 ">

                                                        <a className="font-size-5 text-black-2 font-weight-semibold" rel="noopener noreferrer" target="blank">
                                                            Issue
                                                        </a>

                                                    </div>
                                                    <div className="col-lg-6 pl-13 pt-5 ">

                                                        <a className="font-size-5 text-black-2 font-weight-semibold" rel="noopener noreferrer" target="blank">
                                                        Issue Category
                                                        </a>

                                                    </div>

                                                    <div className=" d-none col-md-6 text-right  mt-md-n1">
                                                        {/* <!-- media date start --> */}
                                                        <div className="media justify-content-md-end">
                                                            <a className="font-size-5 text-black-2 font-weight-semibold" rel="noopener noreferrer" target="blank">
                                                                Issue Category
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div></div>
                                                <div className="row mb-6">
                                                    <div className="col-lg-6">

                                                        <a className=" font-size-4 text-gray mb-0 " rel="noopener noreferrer" target="blank">
                                                            {props.issue.title}
                                                        </a>

                                                    </div>
                                                    <div className="col-lg-6 pl-13">

                                                        <a className=" font-size-4 text-gray mb-0 " rel="noopener noreferrer" target="blank">
                                                        {props.issue.issuecategory}
                                                        </a>

                                                    </div>

                                  
                                                    <div className=" d-none col-md-6 text-left pt-7 pt-md-0 mt-md-n1">
                                                        {/* <!-- media date start --> */}
                                                        <div className=" mb-0 ">
                                                            <a className=" font-size-4 text-gray" rel="noopener noreferrer" target="blank">
                                                                {props.issue.issuecategory}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div className="row bg-white  m-0 mt-1 border-bottom border-width-1 border-default-color">
                                                    <div className=" col-12   ">

                                                        <div className="job-details-content pt-3  pr-sm-9 pr-6 pb-5    light-mode-texts">
                                                            <div className="row">

                                                                <div className=" pr-lg-0 pl-lg-0 mb-md-0 mt-6 mb-6  ">
                                                                    <div className="media justify-content-md-start">

                                                                        <a className="font-size-5 text-black-2 font-weight-semibold" rel="noopener noreferrer" target="blank">
                                                                            Description
                                                                        </a>
                                                                    </div>

                                                                    <div className=" pb-7 mt-5">
                                                        <div className=" font-size-4 text-gray mb-0 " style={{ textOverflow: 'ellipsis', overflow: 'hidden', whitespace: 'nowrap' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.issue.desc).replaceAll(`<p>`, ``).replaceAll(`</p>`, ``) }}>

                                                        </div>  {(DOMPurify.sanitize(props.issue.desc).replaceAll(`<p>`, ``).replaceAll(`</p>`, ``).length > 1000) && 
                                                        <div className="mt-n7  mb-7 text-right "><i className="fa-solid fa-ellipsis mr-5"></i> </div>}
                                                    </div>
                                                                    <a className= "d-none font-size-4 text-gray mb-0 font-weight-normal  " dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.issue.desc) }} style={{ textOverflow: 'ellipsis', width: '745px', height: '73px', overflow: 'hidden', whitespace: 'nowrap' }}>

                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>


                    </div>
                }

            </Modal.Body>
        </ModalStyled>
    );
};

export default ReportedIssueDetails;
