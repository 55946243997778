import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import { GetAdminFeedbackQuery } from "../../utils/GetAPIUtils";
import ReactCountryFlag from "react-country-flag";
import ModalFlagReason from "./ModalFlagReason";
import { getMonthDateYear } from "../../utils/addDays";
import TablePagination from "../../components/Table/TablePagination";
import tableHelper from "../../components/Table/tableHelper";

export default function Experts(props) {

    const gContext = useContext(GlobalContext);
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [feedback, setFeedback] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentValue, setCurrentValue] = useState({ index: null, id: null, code: "" });
    const [currentUser, setCurrentUser] = useState(null);
    const [profileInfo, setProfileInfo] = React.useState({});
    const [page, setPage] = useState(1);
    const { slice, range } = tableHelper(feedback, page, gContext.paginationSize);
    async function LoadFeedback() {
        try {
            gContext.setLoaderModalVisible(true);
            const result = await GetAdminFeedbackQuery();
            if (result.data.adminSiteFeedbackList && result.data.adminSiteFeedbackList.responseData.code == "200") {
                
                let data = JSON.parse(result.data.adminSiteFeedbackList.responseData.message);
               
                if (data.length > 0) {
                    
                    setFeedback(data);
                    

                }
                else {
                    gContext.setLoaderModalVisible(false);
                }
            } else {
                gContext.setLoaderModalVisible(false);
            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
        }
    }
     
    useEffect(() => {
        document.title = "Feedback | Syncuppro";
        LoadFeedback();
    }, []);
    return (
        <>
            <div
                className="dashboard-main-container mt-24 mt-lg-31 min-h-100vh"
                id="dashboard-body"
            >
                <div className="container">
                    <div className="mb-15 mb-lg-10">
                        <div className="row">
                            <div className="font-size-4 font-weight-semibold  bg-athens  w-100  text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">

                            </div>
                            <div className="col-xxxl-12  ">
                                <div className="contact-form bg-white shadow-8 ">

                                    <div className="d-flex font-size-6 font-weight-semibold mb-11   bg-gray-opacity-3 pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5 w-100">
                                        <div className="w-50">  <span>Feedback</span> </div> <div className="w-50 text-right"> <span className="rounded bg-green pointer font-size-6 w-10 text-right  " onClick={() => { LoadFeedback(); }}> <i className="fa fa-refresh m-5 text-white"></i></span>    </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <label
                                            htmlFor="namedash"
                                            className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                        >
                                        </label>
                                        <div className="bg-white  pt-5   pb-9 px-2">
                                            <div className="table-responsive ">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr className="bg-gray-opacity-2 border border-color-2 py-5">
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Platform
                                                            </th>  <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Browser
                                                            </th>   <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Location
                                                            </th> <th
                                                                scope="col"
                                                                className="pl-5  table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Feedback
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="pl-5   table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Rating
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="pl-5   table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Created By
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="pl-5   table-y-middle  border-0 font-size-4 font-weight-bold py-5 text-center"
                                                            >
                                                                Created Date
                                                            </th>


 
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {slice && slice.map((data, index) => {
                                                            return (
                                                                 <tr className="border border-color-2" key={index}>

                                                                    <td className="table-y-middle py-5 min-width-px-135">
                                                                        {data.platform}                                                                    </td>  
                                                                    <td className="table-y-middle py-5 min-width-px-135">

                                                                        {data.browser}   
                                                                    </td>  
                                                                    <td className="table-y-middle py-5 min-width-px-135">
                                                                        {data.location}   
                                                                    </td>     <td className="table-y-middle py-5 min-width-px-135">
                                                                        {decodeURI(data.feedback)}   
                                                                    </td>     <td className="table-y-middle py-5 min-width-px-135">   {data.rating}   
                                                                    </td>     <td className="table-y-middle py-5 min-width-px-135">
                                                                        {data.created_by}   
                                                                    </td>  
                                                                    <td className="table-y-middle py-5 min-width-px-135">
                                                                        {data.date_created ? getMonthDateYear(data.date_created):""}   
                                                                    </td>  
                                                                 
                                                                </tr>)
                                                        })}


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
                <TablePagination range={range} slice={slice} setPage={setPage} page={page} />
            </div>


        </>
    );
};