import React, { useState, useContext, useEffect } from "react";
import { Collapse } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";

const imgL = "assets/image/logo-main-black.png";

const Sidebar = (props) => {

    const gContext = useContext(GlobalContext);
    const [openMenu, setOpenMenu] = useState({ newUser: true, allUser: false,deletedUser:false }); 
    const logout = () => {
        gContext.logout();
    }
    function deleteProfile() {
       
        gContext.setconfirmModal({ header: "Profile delete confirmation", ["text"]: "Are you sure you want to delete your account?  You'll lose all your data on this platform.", ["confirmed"]: false, showButton: true  });

        gContext.toggleConfirmModal();
    }
   
    /* To bind on load */
    useEffect(() => {
        gContext.setVisibleProfileMessage(false); 
    }, [gContext.currentPage]);
    return (
        <>
            <Collapse in={gContext.showSidebarDashboard}>
                <div className="dashboard-sidebar-wrapper pt-11" id="sidebar">
                    <div className="brand-logo px-11">

                        <a>
                            <img src={imgL} alt="" />
                        </a>

                    </div>
                    <div className="my-10 px-11"> 

                    </div>
                    <div   className="overflow-scroll">
                        <ul className="list-unstyled dashboard-layout-sidebar ">


                            <li className="">

                                <a className={`px-10 py-1 my-5 font-size-4 font-weight-semibold pointer flex-y-center ${(gContext.currentPage === "1") ? 'active' : ''}`} onClick={() => {
                                    gContext.setVisibleProfileMessage(false); gContext.setCurrentPage("1");
                                }}>
                                    <i className="icon icon-layout-11 mr-7"></i>Dashboard
                </a>

                            </li>
                            
                            
                            <li className="nav-item  dropdown">

                                <a className={`px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center nav-link  dropdown-toggle gr-toggle-arrow  ${(gContext.currentPage === "2") ? 'active' : ''}`} href="#" onClick={() => { gContext.setVisibleProfileMessage(false); setOpenMenu({ ...openMenu, ["newUser"]: !openMenu.newUser}); gContext.setCurrentPage("4"); }}>
                                    <i className="fa fa-users mr-7"></i> New Users  
                                </a>

                            </li>
                            {openMenu.newUser && <>
                                 <li className=" pl-10">

                                <a className={`px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center  ${(gContext.currentPage === "4") ? 'active' : ''}`} href="#" onClick={() => {
                                    gContext.setCurrentPage("4"); gContext.setVisibleProfileMessage(false);
                                }}>
                                    <i className="fa  fa-user-tie mr-5"></i>   Experts
                                    </a>

                            </li> <li className=" pl-10">

                                <a className={`px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center  ${(gContext.currentPage === "3") ? 'active' : ''}`} href="#" onClick={() => {
                                    gContext.setCurrentPage("3"); gContext.setVisibleProfileMessage(false);
                                }}>
                                    <i className="fa fa-user-group mr-3"></i>   Clients 
                                </a>

                            </li> 
                      </>} 


                            <li className="nav-item  dropdown">

                                <a className={`px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center nav-link  dropdown-toggle gr-toggle-arrow  ${(gContext.currentPage === "2") ? 'active' : ''}`} href="#" onClick={() => { gContext.setVisibleProfileMessage(false); setOpenMenu({ ...openMenu, ["allUser"]: !openMenu.allUser }); gContext.setCurrentPage("6"); }}>
                                    <i className="fa fa-users mr-7"></i> All Users
                                </a>

                            </li>
                            {openMenu.allUser && <>
                                <li className=" pl-10">

                                    <a className={`px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center  ${(gContext.currentPage === "6") ? 'active' : ''}`} href="#" onClick={() => {
                                        gContext.setCurrentPage("6"); gContext.setVisibleProfileMessage(false);
                                    }}>
                                        <i className="fa  fa-user-tie mr-5"></i>   Experts
                                    </a>

                                </li> <li className=" pl-10">

                                    <a className={`px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center  ${(gContext.currentPage === "7") ? 'active' : ''}`} href="#" onClick={() => {
                                        gContext.setCurrentPage("7"); gContext.setVisibleProfileMessage(false);
                                    }}>
                                        <i className="fa fa-user-group mr-3"></i>   Clients
                                </a>

                                </li>



                            </>} 

                            <li className="">

                                <a className={`px-10 py-1 my-5 font-size-4 font-weight-semibold pointer flex-y-center ${(gContext.currentPage === "11") ? 'active' : ''}`} onClick={() => {
                                    gContext.setVisibleProfileMessage(false); gContext.setCurrentPage("11");
                                }}>
                                    <i className="fa   fa-briefcase mr-7 "></i>Jobs
                </a>

                            </li> 

                              <li className="">

                                <a className={`px-10 py-1 my-5 font-size-4 font-weight-semibold pointer flex-y-center ${(gContext.currentPage === "1") ? 'active' : ''}`} onClick={() => {
                                    gContext.setVisibleProfileMessage(false); gContext.setCurrentPage("12");
                                }}>
                                    <i className="fa   fa-briefcase mr-7"></i>Company
                </a>

                            </li>
                            <li className="nav-item  dropdown">

                                <a className={`px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center nav-link  dropdown-toggle gr-toggle-arrow  ${(gContext.currentPage === "2") ? 'active' : ''}`} href="#" onClick={() => { gContext.setVisibleProfileMessage(false); setOpenMenu({ ...openMenu, ["deletedUser"]: !openMenu.deletedUser }); gContext.setCurrentPage("9"); }}>
                                    <i className="fa fa-users mr-7 text-red"></i> Deleted Users
                                </a>

                            </li>
                            {openMenu.deletedUser && <>
                                <li className=" pl-10">

                                    <a className={`px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center  ${(gContext.currentPage === "9") ? 'active' : ''}`} href="#" onClick={() => {
                                        gContext.setCurrentPage("9"); gContext.setVisibleProfileMessage(false);
                                    }}>
                                        <i className="fa  fa-user-tie mr-5 text-red"></i>   Experts
                                    </a>

                                </li> <li className=" pl-10">

                                    <a className={`px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center  ${(gContext.currentPage === "10") ? 'active' : ''}`} href="#" onClick={() => {
                                        gContext.setCurrentPage("10"); gContext.setVisibleProfileMessage(false);
                                    }}>
                                        <i className="fa fa-user-group mr-3 text-red"></i>   Clients
                                </a>

                                </li>



                            </>}

                            <li className="">

                                <a className={`px-10 py-1 my-5 font-size-4 font-weight-semibold pointer flex-y-center ${(gContext.currentPage === "13") ? 'active' : ''}`} onClick={() => {
                                    gContext.setVisibleProfileMessage(false); gContext.setCurrentPage("13");
                                }}>
                                    <i className="fa text-green fa-feedback mr-7 "></i>Feedback
                </a>

                            </li> 


                            <li className="">

                                <a className={`px-10 py-1 my-5 font-size-4 font-weight-semibold pointer flex-y-center ${(gContext.currentPage === "8") ? 'active' : ''}`} onClick={() => {
                                    gContext.setVisibleProfileMessage(false); gContext.setCurrentPage("8");
                                }}>
                                    <i className="fa text-orange fa-flag mr-7 "></i>Reported Issues
                </a> 

                            </li> 


                            <li className="">

                                <a className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center pointer" onClick={logout}>
                                    <i className="fa text-red fa-sign-out-alt mr-7"></i>Logout
                </a>

                            </li>
                             <div className="my-10 px-11 d-none">

                                <a className={`btn btn-red btn-md  rounded-5 w-100 text-uppercase text-white`} onClick={(e) => {
                                    deleteProfile();
                                }}>  <span className="mr-3 d-inline-block">  <i className="fas fa-trash ml-2"></i> </span> Delete my account
              </a>
                            </div>  
                        </ul>
                       

                    </div>

                  


                </div>
            </Collapse>
            <a
                href="/#"
                className="sidebar-mobile-button"
                onClick={(e) => {
                    e.preventDefault();
                    gContext.toggleSidebarDashboard();
                }}
            >
                <i className="icon icon-sidebar-2"></i> <span className="px-3">{(gContext.showSidebarDashboard) ? "  Hide Side Menu" : "  Show Side Menu"}</span>
            </a>
        </>
    );
};

export default Sidebar;
