export const titleCase = str => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};
export const getEncodedEmail = email => {
    try {
        if (email != "") { return email.substring(0, 2) + "*********@" + email.split("@")[1] }
        else { return ""; }
    }
    catch (e) {
        return "";
    }
};
