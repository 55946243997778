import React  from "react"; 
import DatePicker from "react-datepicker";
import { getMonth, getYear } from 'date-fns';
import range from "lodash/range";
import "react-datepicker/dist/react-datepicker.css";
  
const years = range(1990, getYear(new Date()) + 10, 1);
const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
const handleDateChangeRaw = (e) => {
    e.preventDefault();
}
const CustomDatePicker = ({
    ...rest
}) => {
    return <DatePicker onChangeRaw={handleDateChangeRaw} isClearable={!rest.disabled}
        renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
        }) => (
                <div
                    
                >
                    <button className="border-0 bg-green text-white px-2 mr-1 font-weight-semibold" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                        {"<"}
                    </button>
                    <select className="border  mr-1"
                        value={getYear(date)}
                        onChange={({ target: { value } }) => changeYear(value)}
                    >
                        {years.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <select className="border"
                        value={months[getMonth(date)]}
                        onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                        }
                    >
                        {months.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <button onClick={increaseMonth} className="border-0 bg-green text-white px-2 ml-1 font-weight-semibold" disabled={nextMonthButtonDisabled}>
                        {">"}
                    </button>
                </div>
            )}
        {...rest}
    />

};

export default CustomDatePicker;
