

import reactS3 from 'react-s3';
const user = (localStorage.getItem("connect")) ? JSON.parse(localStorage.getItem("connect")) : null;
window.Buffer = window.Buffer || require("buffer").Buffer;
const createFile = (file, name) => {
    try {
        // If this call fails, we go for Blob
        return new File(file, name);
    } catch (e) {
        // If we reach this point a new File could not be constructed
        var myBlob = new Blob(file);
        myBlob.lastModified = new Date();
        myBlob.name = name;
        return myBlob;
    }
};
export const createDirName = () => {
    if (user) {
        return (user.payload["custom:usertype"] == "1") ? "experts/" + user.payload["sub"] : "clients/" + user.payload["sub"];
    }
    else
        return '';

};
export const getFileName = (file, mode) => {
    const fileName = mode + '_' + Date.now() + '.' + file.name.split('.').pop();
    if (user) {
        return (user.payload["custom:usertype"] == "1") ? "experts/" + user.payload["sub"] + "/" + fileName : "clients/" + user.payload["sub"] + "/" + fileName;
    }
    else
        return '';
}

export const FileUpload = (file, mode,sub) => {

    const fileName = mode;
    //const fileName = mode + '_' + Date.now() + '.' + file.name.split('.').pop();
    const file1 = createFile([file], mode);


    const config = {
        bucketName: process.env.REACT_APP_BUCKET_NAME,
        dirName: "admin/" + sub  ,
        region: process.env.REACT_APP_DESIRED_REGION,
        accessKeyId: process.env.REACT_APP_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
    }
    let location = '';
    reactS3.uploadFile(file1, config)
        .then(data => {
            console.log(data);
            location = data.location;
        }).catch(err => { console.error(err); location = ""; });
    return location;
}

export const getExpertFile = (file) => {
    if (file) {
        const fileName = file.name.split('.')[0].replace(' ', '_') + '_' + Date.now() + '.' + file.name.split('.').pop();
        console.log(fileName);
        const file1 = createFile([file], fileName);
        console.log(file1);
        return file1;
    }
    else
        return null;

}

export const ExpertFileUpload = (file, mode) => {

    const config = {
        bucketName: process.env.REACT_APP_BUCKET_NAME,
        dirName: createDirName() + "/" + mode,
        region: process.env.REACT_APP_DESIRED_REGION,
        accessKeyId: process.env.REACT_APP_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
    }
    let location = '';
    reactS3.uploadFile(file, config)
        .then(data => {
            console.log(data);
            location = data.location;
        }).catch(err => { console.error(err); location = ""; });
    return location;
}
