import React, { useState, useEffect, useContext, useRef } from "react";
import ScrollToTop from "react-scroll-to-top";
import styled, { ThemeProvider } from "styled-components";
//import Head from "next/head";
import AOS from "aos";

import Header from "./Header/Header";
import Footer from "./Footer/Footer"; 
import ModalSignIn from "./ModalSignIn/ModalSignIn"; 
import ModalLoader from "./ModalLoad/ModalLoad";  
import ModalConfirm from "./ModalConfirm/ModalConfirm";  
import Cookies from "./Cookies/Cookies";
import GlobalContext from "../context/GlobalContext"; 
import GlobalStyle from "../utils/globalStyle"; 
import { get, merge } from "lodash";

// the full theme object
import { theme as baseTheme } from "../utils";
 

// options for different color modes
const modes = { light: "light", dark: "dark" };

// merge the color mode with the base theme
// to create a new theme object
const getTheme = (mode) =>
  merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, mode, baseTheme.colors),
  }); 

const Layout = ({ children, pageContext }) => {
  const gContext = useContext(GlobalContext);

    const [visibleLoader, setVisibleLoader] = useState(true);
    const [showCookies, setShowCookies] = useState(false);
    const [showScrollup, setShowScrollup] = useState(false);

  useEffect(() => {
    AOS.init({ once: true });
      setVisibleLoader(false);
      setShowCookies(document.cookie.indexOf("accepted_cookies=") < 0);

  }, []);

   

  // Navbar style based on scroll
  const eleRef = useRef();

  
  useEffect(() => {
    window.addEventListener(
      "popstate",
      function (event) {
        // The popstate event is fired each time when the current history entry changes.
        gContext.closeOffCanvas();
      },
      false
    );
    window.addEventListener(
      "pushState",
      function (event) {
        // The pushstate event is fired each time when the current history entry changes.
        gContext.closeOffCanvas();
      },
      false
    );
  }, [gContext]);
 
    const handleScrollupButton =()=> {

        
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 0) {
            setShowScrollup(true)
        }
        else  {
            setShowScrollup(false)
        }
    }
    const scrollToTop=()=> {

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
           
        });
    }
 
  if (pageContext.layout === "bare") {
    return (
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <div data-theme-mode-panel-active data-theme="light">
          <GlobalStyle /> 
          
                
                <div className="site-wrapper overflow-hidden ShowScrollup" ref={eleRef}>
                    {children}
                    <Cookies/>
          </div> 
          <ModalSignIn /> 
                <ModalLoader /> 
                <ModalConfirm/>
        </div>
      </ThemeProvider>
    );
  }

  if (pageContext.layout === "dashboard") {
    return (
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <div data-theme-mode-panel-active data-theme="light" >
          <GlobalStyle />
          
                 
          <div
            className="site-wrapper overflow-hidden bg-default-2"
            ref={eleRef}
                > 
                        <Header isDark={gContext.headerDark} /> 
                        {children} 
                    <Cookies /> 
                        <ScrollToTop smooth="true" component={< i className="fa fa-arrow-up text-white  font-size-7"></i>} className="btn-hover scroll-up media-icon     bg-green rounded-5  text-white  font-size-7" />
                     
          </div> 
                <ModalConfirm />
                <ModalSignIn /> 
                <ModalLoader/>
        </div>
      </ThemeProvider>
    );
  }
   
  return (
    <>
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <div data-theme-mode-panel-active data-theme="light">
          <GlobalStyle />  
          <div className="site-wrapper overflow-hidden" ref={eleRef}>
            <Header isDark={gContext.headerDark} />
            {children}
                      <Cookies />
                    
                      <div style={{ display: showScrollup ? 'inline' : 'none' }} className="btn-hover scroll-up media-icon px-5 py-5 bg-green rounded-5" onClick={scrollToTop}>
                          
                      </div>
                      <ScrollToTop smooth="true" component={< i className="fa fa-arrow-up text-white  font-size-7"></i> } className="btn-hover scroll-up media-icon     bg-green rounded-5  text-white  font-size-7"/>
                      <ModalConfirm />
                      <ModalSignIn />
                      <ModalLoader />
          </div>

        </div>
      </ThemeProvider>
    </>
  );
};

export default Layout;
