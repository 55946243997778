import React from "react";
import StarRatings from 'react-star-ratings';
 
 
const StarRating = ({ 
    ...rest
}) => {
    return (
        <StarRatings
            starDimension="18px" 
            starSpacing="2px"
            starRatedColor="#569d11" isSelectable="false"
            starSelectingHoverColor="#569d11"
            {...rest}
        />
    );
};
export default StarRating;