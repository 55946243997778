import React, { useContext, useState, useEffect } from "react"; 
import CountUp from "react-countup";
import LazyLoad from "react-lazyload";
import PageWrapper from "../../components/PageWrapper"; 
import GlobalContext from "../../context/GlobalContext"; 
import { GetAdminDashboardCount } from "../../utils/GetAPIUtils";
export default function DashboardMain (){
    const gContext = useContext(GlobalContext); 
    const [adminDashboardCount, setAdminDashboardCount] = useState([]);
    const [totalClients, setTotalClients] = useState(null);
    const [totalExperts, setTotalExperts] = useState(null);
    const [totalActiveContracts, setTotalActiveContracts] = useState(null);
    const [totalActiveUsers, setTotalActiveUsers] = useState(null); 
    const [totalFlaggedUsers, setTotalFlaggedUsers] = useState(null);
    const [totalJobs, setTotalJobs] = useState(null);
    const [totalOffers, setTotalOffers] = useState(null);
    const [totalProposals, setTotalProposals] = useState(null);
    async function getUtils() {
     
      try{
      gContext.setLoaderModalVisible(true);


      const response = await GetAdminDashboardCount();
      

      if (response.data && response.data.adminDashboardCount) {

         // console.log("response.data.adminDashboardCount", response.data.adminDashboardCount);
          setAdminDashboardCount(response.data.adminDashboardCount);
          setTotalClients(response.data.adminDashboardCount.totalClients);
          setTotalExperts(response.data.adminDashboardCount.totalExperts);
          setTotalActiveUsers(response.data.adminDashboardCount.totalActiveUsers);
          setTotalActiveContracts(response.data.adminDashboardCount.totalActiveContracts);
          setTotalFlaggedUsers(response.data.adminDashboardCount.totalFlaggedUsers);
          setTotalJobs(response.data.adminDashboardCount.totalJobs);
          setTotalOffers(response.data.adminDashboardCount.totalOffers);
          setTotalProposals(response.data.adminDashboardCount.totalProposals);
          
           
         
          
         
          gContext.setLoaderModalVisible(false);
      }
  }
  catch (e)
  {   console.log(e);
      gContext.setLoaderModalVisible(false);
  }
      gContext.setLoaderModalVisible(false);
  }

    useEffect(() => {
        document.title = "Dashboard | Syncuppro";
        getUtils();
    }, []); 
  return (
    <>
      <PageWrapper
        headerConfig={{
          button: "profile",
          isFluid: true,
          bgClass: "bg-default",
          reveal: false,
        }}
      >
       
        <div className="dashboard-main-container mt-25 mt-lg-31 min-h-100vh">
          <div className="container">
            <div className="row mb-7">
             
              <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6" >
                {/* <!-- Single Category --> */}
                <a
                  href="/#"
                  className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                >
                  <div className="text-blue bg-blue-opacity-1 circle-56 font-size-6 mr-7">
                                      <i className="fas fa-user"></i>
                  </div>
                  {/* <!-- Category Content --> */}
                  <div className="">
                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                      <LazyLoad>
                        <span className="counter">
                          <CountUp duration={6} end={(totalClients) ? totalClients : 0}  />
                        </span>
                      </LazyLoad>
                    </h5>
                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                          Clients
                    </p>
                  </div>
                </a>
                {/* <!-- End Single Category --> */}
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                {/* <!-- Single Category --> */}
                <a
                  href="/#"
                  className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                >
                  <div className="text-pink bg-pink-opacity-1 circle-56 font-size-6 mr-7">
                                      <i className="fas fa-user-tie">  </i>
                  </div>
                  {/* <!-- Category Content --> */}
                  <div className="">
                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                      <LazyLoad>
                        <span className="counter">
                          <CountUp duration={4} end={(totalExperts) ? totalExperts : 0} />
                        </span>
                      </LazyLoad>
                    </h5>
                    <p className="font-size-4 font-weight-normal text-gray mb-0">
             Experts
                    
                    </p>
                  </div>
                </a>
                {/* <!-- End Single Category --> */}
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                {/* <!-- Single Category --> */}
                <a
                  href="/#"
                  className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                >
                  <div className="text-red bg-red-opacity-1 circle-56 font-size-6 mr-7">
                  <i className="fas fa-flag"></i>
                  
                  </div>
                  {/* <!-- Category Content --> */}
                  <div className="">
                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                      <LazyLoad>
                        <span className="counter">
                          <CountUp
                            duration={4}
                            end={(totalFlaggedUsers) ? totalFlaggedUsers : 0}
                          />
                        </span>
                        
                      </LazyLoad>
                    </h5>
                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                     Flagged
                    </p>
                  </div>
                </a>
                {/* <!-- End Single Category --> */}
              </div>
                          <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                              {/* <!-- Single Category --> */}
                              <a
                                  href="/#"
                                  className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                               >
                                  <div className="text-green bg-green-opacity-1 circle-56 font-size-6 mr-7">
                                      <i className="fas fa-briefcase"></i>

                                  </div>
                                  {/* <!-- Category Content --> */}
                                  <div className="">
                                      <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                          <LazyLoad>
                                              <span className="counter">
                                                  <CountUp
                                                      duration={4}
                                                      end={(totalJobs) ? totalJobs : 0}
                                                  />
                                              </span>

                                          </LazyLoad>
                                      </h5>
                                      <p className="font-size-4 font-weight-normal text-gray mb-0">
                                          Jobs
                    </p>
                                  </div>
                              </a>
                              {/* <!-- End Single Category --> */}
                           </div>
                           

                           <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                              {/* <!-- Single Category --> */}
                              <a
                                  href="/#"
                                  className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                               >
                                  <div className="text-orange bg-orange-opacity-1 circle-56 font-size-6 mr-7">
                                      <i className="fas fa-file-contract"></i>

                                  </div>
                                  {/* <!-- Category Content --> */}
                                  <div className="">
                                      <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                          <LazyLoad>
                                              <span className="counter">
                                                  <CountUp
                                                      duration={4}
                                                      end={(totalActiveContracts) ? totalActiveContracts : 0}
                                                  />
                                              </span>

                                          </LazyLoad>
                                      </h5>
                                      <p className="font-size-4 font-weight-normal text-gray mb-0">
                                          Active Contracts
                    </p>
                                  </div>
                              </a>
                              {/* <!-- End Single Category --> */}
                           </div>

                           <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                              {/* <!-- Single Category --> */}
                              <a
                                  href="/#"
                                  className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                               >
                                  <div className="text-yellow bg-yellow-opacity-1 circle-56 font-size-6 mr-7">
                                      <i className="fas fa-users"></i>

                                  </div>
                                  {/* <!-- Category Content --> */}
                                  <div className="">
                                      <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                          <LazyLoad>
                                              <span className="counter">
                                                  <CountUp
                                                      duration={4}
                                                      end={(totalActiveUsers) ? totalActiveUsers : 0}
                                                  />
                                              </span>

                                          </LazyLoad>
                                      </h5>
                                      <p className="font-size-4 font-weight-normal text-gray mb-0">
                                          Active Users
                    </p>
                                  </div>
                              </a>
                              {/* <!-- End Single Category --> */}
                           </div>
              
                          <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                              {/* <!-- Single Category --> */}
                              <a
                                  href="/#"
                                  className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                              >
                                  <div className="text-blue bg-blue-opacity-1 circle-56 font-size-6 mr-7">
                                      <i className="fas fa-briefcase"></i>

                                  </div>
                                  {/* <!-- Category Content --> */}
                                  <div className="">
                                      <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                          <LazyLoad>
                                              <span className="counter">
                                                  <CountUp
                                                      duration={4}
                                                      end={(totalProposals) ? totalProposals : 0}
                                                  />
                                              </span>

                                          </LazyLoad>
                                      </h5>
                                      <p className="font-size-4 font-weight-normal text-gray mb-0">
                                          Proposals
                                      </p>
                                  </div>
                              </a>
                              {/* <!-- End Single Category --> */}
                          </div>
                          <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                              {/* <!-- Single Category --> */}
                              <a
                                  href="/#"
                                  className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                              >
                                  <div className="text-green bg-green-opacity-1 circle-56 font-size-6 mr-7">
                                      <i className="fas fa-file-contract"></i>

                                  </div>
                                  {/* <!-- Category Content --> */}
                                  <div className="">
                                      <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                          <LazyLoad>
                                              <span className="counter">
                                                  <CountUp
                                                      duration={4}
                                                      end={(totalOffers) ? totalOffers : 0}
                                                  />
                                              </span>

                                          </LazyLoad>
                                      </h5>
                                      <p className="font-size-4 font-weight-normal text-gray mb-0">
                                          Offers
                                      </p>
                                  </div>
                              </a>
                              {/* <!-- End Single Category --> */}
                          </div>

              
            </div>

            <div
                        className="accordion rounded-10 border  pl-1  mt-10"

                      >
                       
                         
                    </div>


            
            
          </div>
        </div> 
      </PageWrapper>
    </>
  );
};