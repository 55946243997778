import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import { AdminUsers, AdminJobStatusUpdate, FlagUser, AdminfeatureStatusUpdate } from "../../utils/apiCalls";
import ReactCountryFlag from "react-country-flag";
import ModalFlagReason from "./ModalFlagReason";
import { GetAdminJobsList } from "../../utils/GetAPIUtils";
import { getMonthDateYear } from "../../utils/addDays";
import { slice } from "lodash";
import TablePagination from "../../components/Table/TablePagination";
import tableHelper from "../../components/Table/tableHelper";

export default function Clients(props) {

    const gContext = useContext(GlobalContext);
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const domainURL = process.env.REACT_APP_DOMAIN;
    const [jobs, setJobs] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentValue, setCurrentValue] = useState({ index: null, id: null, code: "" });
    const [currentUser, setCurrentUser] = useState(null);
    const [page, setPage] = useState(1);
    const { slice, range } = tableHelper(jobs, page, gContext.paginationSize);

    function sociallink(link) {

        var reg = new RegExp("^(http|https)://", "i");

        var match = reg.test(link);
        //alert(link);
        if (match == true) {
            return link;
        }
        else {
            link = 'https://' + link;
            return link;
        }



    }
    async function LoadJobs() {
        try {
            gContext.setLoaderModalVisible(true);
            const result = await GetAdminJobsList();

            if (result.data.adminJobsList.responseData.code == "200") {

                let data = JSON.parse(result.data.adminJobsList.responseData.message);              
                setJobs(data);                

            } else {
                gContext.setLoaderModalVisible(false);
            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
        }
    }
    async function submitflag(flagId) {
        if (currentUser) {
            try {
                gContext.setLoaderModalVisible(true);
                const result = await FlagUser(flagId, currentUser, 2);

                if (result.data.flagUser && result.data.flagUser.responseData.code == "200") {
                    LoadJobs();
                    gContext.setLoaderModalVisible(false);

                } else {
                    gContext.setLoaderModalVisible(false);
                }

                gContext.setLoaderModalVisible(false);
            }
            catch (e) { 
                gContext.setLoaderModalVisible(false);
            }
        }
        else
            setCurrentUser(null);
    }
    function toggleModal(userId) {
        setCurrentUser(userId);
        setShowModal(!showModal);
    }
    async function UpdateStatus(status ,jobId) {
        try {
           
            gContext.setLoaderModalVisible(true);
            const result = await AdminJobStatusUpdate(status,jobId);
          
            if (result.data.adminJobStatusUpdate && result.data.adminJobStatusUpdate.responseData.code == 200) { 
               
                    LoadJobs(); 
            }
            else {
                gContext.setLoaderModalVisible(false);
            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
         
            gContext.setLoaderModalVisible(false);
        }
    }
    const statusUpdate = (status, jobId) => {
        if (jobId) {
           
            let code = (status === true) ? "feature" : (status === false) ? "unfeature" : "";
           
            gContext.setconfirmModal({ header: "", ["text"]: "Do you want to " + code + " job?", ["confirmed"]: false, id: jobId, showButton: true, mode: "post", code: status});
            gContext.toggleConfirmModal();
        }
    }
    
     
    useEffect(() => {
        if (gContext.confirmModal.confirmed) {

            if (gContext.confirmModal.mode == "unflag") {
                submitflag(-1);
                setCurrentUser(null);
            }
            else {
                UpdateStatus(gContext.confirmModal.code, gContext.confirmModal.id);
            } 
            gContext.setconfirmModal({
                ...gContext.confirmModal, ["confirmed"]: false, ["id"]: null
            });

        }
        else { setCurrentValue({ index: null, id: null }); }
    }, [gContext.confirmModal]);

    useEffect(() => {
        document.title = "All Clients | Syncuppro";
        LoadJobs();
    }, []);
    return (
        <>
            <div
                className="dashboard-main-container mt-24 mt-lg-31 min-h-100vh"
                id="dashboard-body"
            >
                <div className="container">
                    <div className="mb-15 mb-lg-10">
                        <div className="row">
                            <div className="font-size-4 font-weight-semibold  bg-athens  w-100  text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">

                            </div>
                            <div className="col-xxxl-12  ">


                                <div className="contact-form bg-white shadow-8 ">
                                    <div className="d-flex font-size-6 font-weight-semibold mb-11   bg-gray-opacity-3 pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5 w-100">
                                        <div className="w-50">  <span>Jobs</span> </div> <div className="w-50 text-right"> <span className="rounded bg-green pointer font-size-6 w-10 text-right  " onClick={() => { LoadJobs(); }}> <i className="fa fa-refresh m-5 text-white"></i></span>    </div>
                                    </div>



                                    <div className="col-lg-12">
                                        <label
                                            htmlFor="namedash"
                                            className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                        >
                                        </label>
                                        <div className="bg-white  pt-5   pb-9 px-2">
                                            <div className="table-responsive ">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr className="bg-gray-opacity-2 border border-color-2 py-5">
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Title
                        </th>
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Location
                        </th>
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Weekly Hours
                        </th>  <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Project Length
                        </th>
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                              Posted date
                        </th>
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                
                        </th>


                                                            
                                                        </tr>
                                                    </thead>
                                                    <tbody> 
                                                        {slice && slice.map((data, index) => {
                                                            const { id, title, city, state,dateposted, length, countrycode, hours, sub, guid } = data;
                                                            
                                                            return (
                                                                <tr className="border border-color-2" key={index}>
                                                                    <th
                                                                        scope="row"
                                                                        className="pl-6 border-0 py-5  max-width-px-235 "
                                                                    >

                                                                        <div className="width-100 media" >
                                                                            <a data-toggle="tooltip" title={title} className="font-size-4 max-width-px-235 ellipsis mb-0 font-weight-semibold text-black-2 pointer" target="_blank" rel="noopener noreferrer" href={domainURL+"job/" + guid} >
                                                                                {title}
                                                                            </a> <i className="fa-solid fa-arrow-up-right-from-square font-size-3 text-green pl-3 text-right justify-content-md-end"></i>
                                                                        </div>

                                                                    </th>
                                                                    <td className="table-y-middle py-5 max-width-px-185">
                                                                        <h3 data-toggle="tooltip" title={city + ' ' + state + ' ' + (countrycode)? countrycode:""} className="font-size-4 font-weight-normal text-black-2 mb-0 max-width-px-185 ellipsis">
                                                                            <ReactCountryFlag
                                                                                countryCode={(countrycode) ? countrycode : ""}
                                                                                svg
                                                                                style={{
                                                                                    width: '2em',
                                                                                    height: '1em',
                                                                                }}
                                                                                title={countrycode}
                                                                            />         {city + ' '} {state} {' ' + (countrycode) ? countrycode : ""}
                                                                        </h3>
                                                                    </td>

                                                                    <td className="table-y-middle py-5 min-width-px-155">
                                                                        <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                            {hours}
                                                                        </h3>
                                                                    </td>
                                                                    <td className="table-y-middle py-5 min-width-px-155">
                                                                        <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                            {length}
                                                                        </h3>
                                                                    </td>
                                                                    <td className="table-y-middle py-5 min-width-px-155">
                                                                        <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                            {(dateposted)?getMonthDateYear(dateposted):""}
                                                                        </h3>
                                                                    </td>

                                                                    <td className="table-y-middle    pr-0   text-center media justify-content-md-end">
                                                                         <div data-toggle="tooltip " className="text-right" >
                                                                            {!data.featured && <button className="border font-size-3 font-weight-bold text-green-2 px-5 rounded-5  mr-1 border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" data-toggle="tooltip" title="Click to feature the user" onClick={() => statusUpdate(true,id)}  >
                                                                                Feature
                                                                            </button>}
                                                                            {data.featured && <button className="border font-size-3 font-weight-bold text-red px-5 rounded-5  mr-1 border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" data-toggle="tooltip" title="Click to unfeature the user" onClick={() => statusUpdate(false,id)} >
                                                                                UnFeature
                                                                            </button>}</div>



                                                                         
                                                                    </td>


                                                                     
                                                                </tr>)
                                                        })}


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TablePagination range={range} slice={slice} setPage={setPage} page={page} />
                <ModalFlagReason submitflag={submitflag} modalvisible={showModal} togglemodal={toggleModal} />
            </div>
        </>
    );
};