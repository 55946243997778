import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import { AdminUsers, AdminStatusUpdate, FlagUser, AdminfeatureStatusUpdate } from "../../utils/apiCalls";
import ReactCountryFlag from "react-country-flag";
import { GetCountry } from "../../utils/GetAPIUtils";
import ModalFlagReason from "./ModalFlagReason";
import TablePagination from "../../components/Table/TablePagination";
import tableHelper from "../../components/Table/tableHelper";
import CustomDatePicker from "../../components/Core/CustomDatePicker";
import { Select } from "../../components/Core";
import { getMonthDateYear } from "../../utils/addDays";

export default function Clients(props) {

    const gContext = useContext(GlobalContext);
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [allusers, setAllusers] = useState([]);
    const [experts, setExperts] = useState([]);
    const [countriesList, setCountriesList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [values, setValues] = useState({ profile: { value: "", label: "All" }, assessment: { value: "", label: "All" }, featured: { value: "", label: "All" }, flagged: { value: "", label: "All" }, country: null, name: "", fromDate: null, toDate: null });
    const [currentValue, setCurrentValue] = useState({ index: null, id: null, code: "" });
    const [status, setStatus] = useState([{ value: "", label: "All" }, { value: "true", label: "Completed" }, { value: "false", label: "Not Completed" }]);
    const [currentUser, setCurrentUser] = useState(null);
    const [page, setPage] = useState(1);
    const { slice, range } = tableHelper(experts, page, gContext.paginationSize);
    async function LoadCountry() {
        try {
            if (countriesList.length <= 1) {
                const resultCountry = await GetCountry();
                if (resultCountry.data && resultCountry.data.countries) {
                    let countlist = [];
                    let usCountry = resultCountry.data.countries.filter(item => item.code == "US");
                    let list = resultCountry.data.countries.filter(item => item.code != "US");
                   
                    usCountry.map((value) => (
                        countlist.push({ code: value.code, value: value.id, label: value.name })));


                    list.map((value) => (
                        countlist.push({ code: value.code, value: value.id, label: value.name })));
                    setCountriesList(countlist);
                }
            }
        } catch (e) {
            console.log(e);

        }
    }
    const handleChange = (e, field) => {
        setValues({ ...values, [field]: e.target.value });
    }
    const handleDateChange = (name, value) => {

        setValues({ ...values, [name]: value });
    }
    function submitFilter() {

        if ((values.profile == null || values.profile?.value == "")  && (values.featured == null || values.featured?.value == "") && (values.flagged == null || values.flagged?.value == "") && values.name == "" && values.country == null && !values.fromDate && !values.toDate) {
            setExperts(allusers);
        }
        else {
            let data = allusers;

            let list, final = data;
            let  featured, flagged, name, country, regDate;
            if (values.profile == null || values.profile.value != "") {
                list = (values.profile.value == "true") ? data.filter(item => item["profilecomplete"] == true) : data.filter(item => (item["profilecomplete"] == false));
                final = list;
            }
           
            if (values.featured == null || values.featured.value != "") {
                featured = (values.featured.value == "true") ? final.filter(item => item["featured"] == true) : final.filter(item => (item["featured"] == false || item["featured"] == null));
                final = featured;
            }
            if (values.flagged == null || values.flagged.value != "") {
                flagged = (values.flagged.value == "true") ? final.filter(item => item["flagged"] == true) : final.filter(item => (item["flagged"] == false || item["flagged"] == null));
                final = flagged;
            }
            if (values.name == null || values.name != "") {

                name = final.filter(item => item["company"]?.toLowerCase().match(values.name.toLowerCase()));
                final = name;
            }
            if (values.country != null) {
                country = final.filter(item => item["countrycode"] == values.country.code);
                final = country;
            }

            if (values.fromDate && values.toDate) {

                regDate = final.filter(item => (new Date(item["datecreated"]) >= new Date(values.fromDate) && new Date(item["datecreated"]) <= new Date(values.toDate)));
                final = regDate;
            }
            if (values.fromDate && !values.toDate) {
                regDate = final.filter(item => new Date(item["datecreated"]) >= new Date(values.fromDate) && new Date(item["datecreated"]) <= new Date());
                final = regDate;
            }
            if (!values.fromDate && values.toDate) {
                regDate = final.filter(item => new Date(item["datecreated"]) <= new Date(values.toDate));
                final = regDate;
            }

            setExperts(final);
        }
    }
    function sociallink(link) {

        var reg = new RegExp("^(http|https)://", "i");

        var match = reg.test(link);
        //alert(link);
        if (match == true) {
            return link;
        }
        else {
            link = 'https://' + link;
            return link;
        }



    }
    const handleSelectChange = (e,field) => {
     
        setValues({ ...values, [field]: e });        
    }
    async function LoadClient() {
        try {
            gContext.setLoaderModalVisible(true);
            const result = await AdminUsers("all", 2);
            if (result.data.adminUsers.responseData.code == "200") {

                let data = JSON.parse(result.data.adminUsers.responseData.message);
                
                if (data.length > 0) {
                    let list = data.filter(item => item["delete"] == false);
                    setExperts(list);
                    setAllusers(list);
                    setValues({ profile: { value: "", label: "All" }, assessment: { value: "", label: "All" }, featured: { value: "", label: "All" }, flagged: { value: "", label: "All" }, country: null, name: "", fromDate: null, toDate: null });
                }
                else {
                    gContext.setLoaderModalVisible(false);
                }

            } else {
                gContext.setLoaderModalVisible(false);
            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
        }
    }
    async function submitflag(flagId) {
        if (currentUser) {
            try {
                gContext.setLoaderModalVisible(true);
                const result = await FlagUser(flagId, currentUser, 2);

                if (result.data.flagUser && result.data.flagUser.responseData.code == "200") {
                    LoadClient();
                    gContext.setLoaderModalVisible(false);

                } else {
                    gContext.setLoaderModalVisible(false);
                }

                gContext.setLoaderModalVisible(false);
            }
            catch (e) {
               
                gContext.setLoaderModalVisible(false);
            }
        }
        else
            setCurrentUser(null);
    }
    function toggleModal(userId) {
        setCurrentUser(userId);
        setShowModal(!showModal);
    }
    async function UpdateStatus(status, statusType, userId) {
        try {
             
            gContext.setLoaderModalVisible(true);
            const result = (status == "feat"  ) ? await AdminfeatureStatusUpdate(userId, 2, status, statusType, gContext.confirmModal.featuredLogo, gContext.confirmModal.featuredType):await AdminStatusUpdate(status, statusType, userId, 2);
          
            if (result.data.adminStatusUpdate && result.data.adminStatusUpdate.responseData.code == "200") {
                LoadClient();
            }
            else {
                gContext.setLoaderModalVisible(false);
            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            
            gContext.setLoaderModalVisible(false);
        }
    }
    const statusUpdate = (status, statusType, userId) => {
        if (userId) {
            if (status == "unflag")
                setCurrentUser(userId);
            let code = (status === "feat") ? "feature" : (status === "unfeat") ? "unfeature" : status;
            gContext.setconfirmModal({ header: "", ["text"]: "Do you want to " + code + "?", ["confirmed"]: false, id: userId, showButton: true, mode: status, code: statusType, page: "client" });
            gContext.toggleConfirmModal();
        }
    }
    
    const statusfeatueUpdate = (status, statusType, userId,sub) => {
        if (userId) {
            if (status == "unflag")
                setCurrentUser(userId);
            let code = (status === "feat") ? "feature" : (status === "unfeat") ? "unfeature" : status;
            gContext.setconfirmModal({ header: "", ["text"]: "Do you want to " + code + "?", ["confirmed"]: false, id: userId, showButton: true, mode: status, code: statusType, userType: status, sub: sub });
            gContext.toggleConfirmModal();
        }
    }
    useEffect(() => {
        if (gContext.confirmModal.confirmed) {

            if (gContext.confirmModal.mode == "unflag") {
                submitflag(-1);
                setCurrentUser(null);
            }
            else {
                UpdateStatus(gContext.confirmModal.mode, gContext.confirmModal.code, gContext.confirmModal.id);
            } 
            gContext.setconfirmModal({
                ...gContext.confirmModal, ["confirmed"]: false, ["id"]: null
            });

        }
        else { setCurrentValue({ index: null, id: null }); }
    }, [gContext.confirmModal]);

    useEffect(() => {
        document.title = "All Clients | Syncuppro";
        LoadCountry();
        LoadClient();
    }, []);
    return (
        <>
            <div
                className="dashboard-main-container mt-24 mt-lg-20 min-h-100vh"
                id="dashboard-body"
            >
                <div className="container">
                    <div className="mb-15 mb-lg-10">
                        <div className="row">
                            <div className="font-size-4 font-weight-semibold  bg-athens  w-100  text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                            
                            </div>

                            <div className="col-lg-3 text-left">
                                <label className="form-label  "><i className="fa fa-filter m-2 text-green"></i>Company</label>
                            </div>
                            <div className="col-lg-3 text-left">
                                <label className="form-label "><i className="fa fa-filter m-2 text-green"></i>Location</label>
                            </div>
                            <div className="col-lg-3 text-left"><i className="fa fa-filter m-2 text-green"></i>Featured Status</div>
                            <div className="col-lg-3 text-left"><i className="fa fa-filter m-2 text-green"></i>Flagged Status</div>
                            <div className="col-lg-3 text-left ">
                                <div className="forn-group-input">
                                    <input
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        placeholder="Name" defaultValue={values.name} value={values.name} onChange={(e) => (handleChange(e, "name"))}
                                    />

                                </div>


                            </div>
                            <div className="col-lg-3 text-left  ">
                                <div className="forn-group-input">

                                    <Select options={countriesList} placeholder="All"
                                        value={values.country}
                                        className={'form-control pl-0 arrow-3 w-100 font-size-4 d-flex align-items-center w-100 mb-4 '}
                                        border={false} onChange={(e) => handleSelectChange(e, "country")}
                                    />

                                </div>
                            </div>

                            <div className="col-lg-3 text-left ">
                                <div className="forn-group-input">

                                    <Select options={[{ value: "", label: "All" }, { value: "true", label: "Featured" }, { value: "false", label: "Not Featured" }]}
                                        placeholder="Select..." value={values.featured}
                                        className={'form-control pl-0 arrow-3 w-100 font-size-4 d-flex align-items-center w-100 mb-4 '}
                                        border={false} onChange={(e) => handleSelectChange(e, "featured")}
                                    />

                                </div>


                            </div>
                            <div className="col-lg-3 text-left ">
                                <div className="forn-group-input">

                                    <Select options={[{ value: "", label: "All" }, { value: "true", label: "Flagged" }, { value: "false", label: "Not Flagged" }]}
                                        placeholder="Select..." value={values.flagged}
                                        className={'form-control pl-0 arrow-3 w-100 font-size-4 d-flex align-items-center w-100 mb-4 '}
                                        border={false} onChange={(e) => handleSelectChange(e, "flagged")}
                                    />

                                </div>
                            </div>

                            <div className="col-lg-3 text-left">
                                <label className="form-label  "><i className="fa fa-filter m-2 text-green"></i> Profile Status</label>
                            </div>
                           
                            <div className="col-lg-3 text-left"><i className="fa fa-filter m-2 text-green"></i>Reg Date - Start</div>
                            <div className="col-lg-3 text-left"><i className="fa fa-filter m-2 text-green"></i>Reg Date - End</div>
                            <div className="col-lg-3 text-left"></div>
                            <div className="col-lg-3 text-left ">
                                <div className="forn-group-input">

                                    <Select options={status}
                                        placeholder="Select..." value={values.profile}
                                        className={'form-control pl-0 arrow-3 w-100 font-size-4 d-flex align-items-center w-100 mb-4 '}
                                        border={false} onChange={(e) => handleSelectChange(e, "profile")}
                                    />

                                </div>


                            </div>
                           

                            <div className="col-lg-3 text-left">
                                <div className="forn-group-input">

                                    <CustomDatePicker className="form-control"
                                        selected={values.fromDate}
                                        onChange={(date) => (handleDateChange('fromDate', date))}
                                        selectsStart
                                        startDate={values.fromDate}
                                        endDate={values.toDate}
                                        maxDate={new Date()}
                                    />

                                </div>


                            </div>
                            <div className="col-lg-3 text-left  ">
                                <div className="forn-group-input">

                                    <CustomDatePicker className="form-control"
                                        selected={values.toDate}
                                        onChange={(date) => (handleDateChange('toDate', date))}
                                        selectsEnd
                                        startDate={values.fromDate}
                                        endDate={values.toDate}
                                        minDate={values.fromDate}
                                        maxDate={new Date()}

                                    />
                                </div>


                            </div>
                            <div className="col-lg-3 text-left  ">

                            </div>
                            <div className="col-lg-12   text-right m-b-0 mt-5 mb-lg-10 align-right ">
                                <button type="button"
                                    className="btn btn-green  m-b-0  mt-1  mx-5  font-size-3 font-weight-bold rounded-5 border-0 text-uppercase" onClick={() => {
                                        setValues({ profile: { value: "", label: "All" }, assessment: { value: "", label: "All" }, featured: { value: "", label: "All" }, flagged: { value: "", label: "All" }, country: null, name: "", fromDate: null, toDate: null });
                                    }}>
                                    Clear
                                </button>
                                <button type="button"
                                    className="btn btn-green  m-b-0  mt-1  font-size-3 font-weight-bold rounded-5 border-0 text-uppercase" onClick={() => {
                                        submitFilter();
                                    }}>
                                    Submit
                                </button>
                            </div>



                            <div className="col-lg-6 text-left m-b-0 ">
                               
                                   
                                


                            </div>
                            <div className="col-xxxl-12  ">


                                <div className="contact-form bg-white shadow-8 ">

                                    <div className="d-flex font-size-6 font-weight-semibold mb-11   bg-gray-opacity-3 pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5 w-100">
                                        <div className="w-50">  <span>All Clients</span> </div> <div className="w-50 text-right"> <span className="rounded bg-green pointer font-size-6 w-10 text-right  " onClick={() => { LoadClient(); }}> <i className="fa fa-refresh m-5 text-white"></i></span>    </div>  
                  </div>



                                    <div className="col-lg-12">
                                        <label
                                            htmlFor="namedash"
                                            className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                        >
                                        </label>
                                        <div className="bg-white  pt-5   pb-9 px-2">
                                            <div className="table-responsive ">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr className="bg-gray-opacity-2 border border-color-2 py-5">
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Company
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                E-mail
                                                            </th> 
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Reg Date
                        </th>    <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Location
                        </th> <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Website
                        </th>
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle   border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Certificate
                        </th>
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle   border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Feature Logo
                        </th>

                                                             <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5 mr-0" 
                                                            ></th>


                                                        </tr>
                                                    </thead>
                                                    <tbody> 
                                                        {slice && slice.map((data, index) => {

                                                            return (
                                                                <tr className="border border-color-2" key={index}>

                                                                    <td className="table-y-middle py-5 min-width-px-135">
                                                                        <a className="media min-width-px-235 align-items-center pointer" target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_DOMAIN+'adminclient/' + data.sub}  >
                                                                            <div className="circle-36 mr-6">
                                                                                <img src={data.logo ? s3URL + data.logo : "assets/image/avatar.jpg"} alt="" className=" circle-36 w-100" />
                                                                            </div>
                                                                            {(data.company && data.company !="") ? <>   <h4 data-toggle="tooltip" style={{ wordWrap: "break-word" }} title={data.company} className="font-size-4 mb-0 font-weight-semibold text-black-2 pointer ellipsis min-width-px-185"   >
                                                                                {data.company}
                                                                            </h4> <i className="fa fa-arrow-up-right-from-square font-size-3 text-green pl-5"></i></>
                                                                                : <>   <h4 data-toggle="tooltip" style={{ wordWrap: "break-word" }} title={data.email ? data.email.split('@')[0] : "N/A"} className="font-size-4 mb-0 font-weight-semibold text-black-2 pointer ellipsis min-width-px-185"   >
                                                                                    {data.email ? data.email.split('@')[0] : "N/A"}
                                                                                </h4> <i className="fa fa-arrow-up-right-from-square font-size-3 text-green pl-5"></i></>}
                                                                        </a>
                                                                    </td>
                                                                    <td className="table-y-middle py-5 min-width-px-125">

                                                                        {data.email}
                                                                    </td>
                                                                    <td className="table-y-middle py-5 min-width-px-125">

                                                                        {getMonthDateYear(data.datecreated)}
                                                                    </td>
                                                                    <td className="table-y-top py-5 min-width-px-235 ">
                                                                        {data.city ? <h3 data-toggle="tooltip" title={data.city + ',' + data.state + ',' + data.countrycode} className=" ellipsis min-width-px-184 font-size-4 font-weight-normal text-black-2 pointer mb-0">
                                                                            <ReactCountryFlag
                                                                                countryCode={data.countrycode}
                                                                                svg
                                                                                style={{
                                                                                    width: '2em',
                                                                                    height: '1em', wordWrap: "break-word"
                                                                                }}
                                                                                title={data.countrycode}
                                                                            />         {data.city + ' '}, {data.state} ,{' ' + data.countrycode}
                                                                        </h3> : <span>N/A</span>}
                                                                    </td>
                                                                    <td className="table-y-middle py-5 min-width-px-135">{data.website && <div className="row"><div className="col-12 text-center"><a href={sociallink(data.website)} rel="noopener noreferrer" className="text-green-2 font-weight-semibold" target="_blank" data-toggle="tooltip" title="Click here to view.">Website</a>
                                                                          </div>

                                                                    </div>}</td>
                                                                    <td className="table-y-middle py-5 min-width-px-135  ">
                                                                        {data.certfilename &&
                                                                            <div className="row"><div className="col-12 text-center">  <a href={s3URL + data.certfilename} rel="noopener noreferrer" className="text-green-2 font-weight-semibold" target="_blank" data-toggle="tooltip" title="Click here to view.">Certificate</a>
                                                                                   </div></div>

                                                                        }

                                                                    </td>
                                                                    <td className="table-y-middle    pr-0   text-center  ">

                                                                        <img src={s3URL + data.featuredlogo} alt="" style={{width:"100px",backgroundColor:"gray"}}  />
                                                                    </td>
                                               
                                                                    <td className="table-y-middle    pr-0   text-center media justify-content-md-end">
                                                                        {data.idverified && data.profilecomplete && <div data-toggle="tooltip " className="text-right" > {!data.featured && <button className="border font-size-3 font-weight-bold text-green-2 px-5 rounded-5  mr-1 border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" data-toggle="tooltip" title="Click to feature the user" onClick={() => statusfeatueUpdate("feat", "feat", data.id,data.sub)}  >
                                                                            Feature
                              </button>}
                                                                            {data.featured && <button className="border font-size-3 font-weight-bold text-red px-5 rounded-5  mr-1 border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" data-toggle="tooltip" title="Click to unfeature the user" onClick={() => statusUpdate("unfeat", "feat", data.id)} >
                                                                                UnFeature
                              </button>}</div>}



                                                                        <div data-toggle="tooltip " className="text-right" > {!data.flagged && <button className="border font-size-3 font-weight-bold text-red px-5 rounded-5  mr-1 border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" data-toggle="tooltip" title="Click to flag." onClick={(e) => toggleModal(data.id)}  >
                                                                            Flag
                              </button>}
                                                                            {data.flagged && <button className="border font-size-3 font-weight-bold text-green-2 px-5 rounded-5  mr-1 border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" data-toggle="tooltip" title="Click to unflag." onClick={() => statusUpdate("unflag", "unflag", data.id)}>
                                                                                UnFlag
                              </button>}</div>
                                                                    </td>

                                                                </tr>)
                                                        })}


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TablePagination range={range} slice={slice} setPage={setPage} page={page} />
                <ModalFlagReason submitflag={submitflag} modalvisible={showModal} togglemodal={toggleModal} />
            </div>
        </>
    );
};