

export const getCountry = /* GraphQL */ `
  query getCountry {
   countries {
        id
        code
        name
    }
}
`;

export const getComplianceTypes = /* GraphQL */ `
  query CTQuery {
  complianceTypes {
    code
    id
  }
}
`;

export const getIndustryTypes = /* GraphQL */ `
 query ITQuery {
  industryTypes {
    id
    name
  }
}
`;

export const getSkills = /* GraphQL */ `
  query SSQuery {
  skills {
    id
    skill
  }
}
`;

export const getExpertDegrees = /* GraphQL */ `
  query EDQuery {
  expertDegrees {
    degree
    id
  }
}`;

export const getExpLevels = /* GraphQL */ `
query ELQuery {
  expLevels {
    id
    level
  }
}

`;
export const getSocialProviders = /* GraphQL */ `
query SLQuery {
  socialProviders {
    id
    link
  }
}

`;

export const getLanguages = /* GraphQL */ `
query LangQuery {
    languages {
        id
        language
    }
}

`;
export const getLanguagesProficiencyLevel = /* GraphQL */
    `query LPQuery {
  languageProficiencyLevel {
    id
    level
  }
} 
`;
export const getWeeklyHrs = /* GraphQL */
    `query WHQuery {
    weeklyHours {
        hours
        id
    }
}
`;

export const getProjectLength = ` 
  query MyQuery {
  projectLengths {
    id
    length
   }
}`;
export const getComplianceCategory = ` 
   query MyQuery {
  complianceCategory {
    category
    code
    id
  }
}`;

export const flagReasons = `
query MyQuery {
    flagReasons {
        id
        reason
        usertypeid
    }
}`;



export const issueReportList =
 `query MyQuery {
  issueReportList {
    avatar
    datecreated
    desc
    firstname
    issuecategory
    lastname
    title
    userid
    status
    id
    sub
    email
  }
}`;

export const adminDashboardCount = 
`query MyQuery {
  adminDashboardCount {
    totalActiveContracts
    totalActiveUsers
    totalClients
    totalExperts
    totalFlaggedUsers
    totalJobs
    totalProposals
    totalOffers

  }
}`;
export const adminJobsList = 
`query AdminJobsList {
    adminJobsList {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;

export const adminCompanyList = 
`query adminCompanyList {
  adminCompanyList {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;

export const AdminFeedbackQuery = 
`query AdminFeedbackQuery {
    adminSiteFeedbackList {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;