import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import { AdminUsers, AdminStatusUpdate, FlagUser } from "../../utils/apiCalls";
import ReactCountryFlag from "react-country-flag";
import ModalFlagReason from "./ModalFlagReason";
import { getMonthDateYear } from "../../utils/addDays";

export default function Experts(props) {

    const gContext = useContext(GlobalContext);
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [experts, setExperts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentValue, setCurrentValue] = useState({ index: null, id: null, code: "" });
    const [currentUser, setCurrentUser] = useState(null);
    const [profileInfo, setProfileInfo] = React.useState({});
    async function LoadExpert() {
        try {
            gContext.setLoaderModalVisible(true);
            const result = await AdminUsers("all", 1);
            if (result.data.adminUsers && result.data.adminUsers.responseData.code == "200") {
                let data = JSON.parse(result.data.adminUsers.responseData.message);
                
                if (data.length > 0) {
                    let list = data.filter(item => item["delete"] == true);
                    setExperts(list);
                     

                }
                else {
                    gContext.setLoaderModalVisible(false);
                }
            } else {
                gContext.setLoaderModalVisible(false);
            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
        }
    }
    async function UpdateStatus(status, statusType, userId) {
        try {
            gContext.setLoaderModalVisible(true);
            const result = await AdminStatusUpdate(status, statusType, userId, 1);
        
            if (result.data.adminStatusUpdate && result.data.adminStatusUpdate.responseData.code == "200") {
             LoadExpert();

            } else {
                gContext.setLoaderModalVisible(false);
            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
        }
    }
    async function submitflag(flagId) {
        if (currentUser) {
            try {
                gContext.setLoaderModalVisible(true);
                const result = await FlagUser(flagId, currentUser, 1);

                if (result.data.flagUser && result.data.flagUser.responseData.code == "200") {
                    LoadExpert();
                    gContext.setLoaderModalVisible(false);

                } else {
                    gContext.setLoaderModalVisible(false);
                }

                gContext.setLoaderModalVisible(false);
            }
            catch (e) {
                gContext.setLoaderModalVisible(false);
            }
        }
        else
            setCurrentUser(null);
    }
    function toggleModal(userId) {
        setCurrentUser(userId);
        setShowModal(!showModal);
    }
    const statusUpdate = (status, statusType, userId) => {

        if (userId) {
            if (status == "unflag")
                setCurrentUser(userId);
            let code = (status === "feat") ? "feature" : (status === "unfeat") ? "unfeature" : status;
            gContext.setconfirmModal({ header: " ", ["text"]: "Do you want to " + code + "?", ["confirmed"]: false, id: userId, showButton: true, mode: status, code: statusType });
            gContext.toggleConfirmModal();
        }
    }

    useEffect(() => {
        if (gContext.confirmModal.confirmed) {

            if (gContext.confirmModal.mode == "unflag") {
                submitflag(-1);
                setCurrentUser(null);
            }
            else {
                UpdateStatus(gContext.confirmModal.mode, gContext.confirmModal.code, gContext.confirmModal.id);
            }

            gContext.setconfirmModal({
                ...gContext.confirmModal, ["confirmed"]: false, ["id"]: null
            });

        }
        else { setCurrentValue({ index: null, id: null }); }
    }, [gContext.confirmModal]);
    useEffect(() => {
        document.title = "Deleted Experts | Syncuppro";
        LoadExpert();
    }, []);
    return (
        <>
            <div
                className="dashboard-main-container mt-24 mt-lg-31 min-h-100vh"
                id="dashboard-body"
            >
                <div className="container">
                    <div className="mb-15 mb-lg-23">
                        <div className="row">
                            <div className="font-size-4 font-weight-semibold  bg-athens  w-100  text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">

                            </div>
                            <div className="col-xxxl-12  ">
                                <div className="contact-form bg-white shadow-8 ">

                                    <div className="d-flex font-size-6 font-weight-semibold mb-11   bg-gray-opacity-3 pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5 w-100">
                                        <div className="w-50">  <span>Deleted Experts</span> </div> <div className="w-50 text-right"> <span className="rounded bg-green pointer font-size-6 w-10 text-right  " onClick={() => { LoadExpert(); }}> <i className="fa fa-refresh m-5 text-white"></i></span>    </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <label
                                            htmlFor="namedash"
                                            className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                        >
                                        </label>
                                        <div className="bg-white  pt-5   pb-9 px-2">
                                            <div className="table-responsive ">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr className="bg-gray-opacity-2 border border-color-2 py-5">
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Name
                                                            </th>   <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                E-mail
                                                            </th><th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Reg Date
                                                            </th>   <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Location
                                                            </th> <th
                                                                scope="col"
                                                                className="pl-5 d-none table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Photo ID
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="pl-5  d-none table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Certificates
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="pl-5  d-none table-y-middle  border-0 font-size-4 font-weight-bold py-5 text-center"
                                                            >

                                                            </th>


 
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {experts.map((data, index) => {
                                                            return (
                                                                 <tr className="border border-color-2" key={index}>

                                                                    <td className="table-y-middle py-5 min-width-px-135">
                                                                        <a className="media min-width-px-235 align-items-center pointer" target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_DOMAIN+'adminexpert/' + data.sub}  >
                                                                            <div className="circle-36 mr-6">
                                                                                <img src={s3URL + data.avatar} alt="" className=" circle-36 w-100" />
                                                                            </div>
                                                                            <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2 pointer"   >
                                                                                {data.firstname + ' ' + data.lastname}
                                                                            </h4><i className="fa fa-arrow-up-right-from-square font-size-3 text-green pl-5"></i>
                                                                        </a>
                                                                    </td>  

                                                                    <td className="table-y-middle py-5 min-width-px-135">
                                                                        { data.email}
                                                                    </td> 
                                                                 <td className="table-y-middle py-5 min-width-px-125">

                                                                        {getMonthDateYear(data.datecreated)}
                                                                    </td>   <td className="table-y-middle py-5 min-width-px-125">
                                                                        <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                            <ReactCountryFlag
                                                                                countryCode={data.countrycode}
                                                                                svg
                                                                                style={{
                                                                                    width: '2em',
                                                                                    height: '1em',
                                                                                }}
                                                                                title={data.countrycode}
                                                                            />         {data.city + ' '} {data.state} {' ' + data.countrycode}
                                                                        </h3>
                                                                    </td>
                                                                    <td className="table-y-middle py-5 min-width-px-135 text-center  d-none">
                                                                        <div className="row"><div className="col-12 text-center media"> <a href={s3URL + data.photo} rel="noopener noreferrer" className="text-green-2 font-weight-semibold" target="_blank" data-toggle="tooltip" title="Click here to view.">Photo ID</a>

                                                                        </div></div>



                                                                    </td>


                                                                   
                                                                    <td className="table-y-middle py-5 min-width-px-235 text-center  d-none ">
                                                                    {(data.expertCertificate) && data.expertCertificate.map((data, index) => {

return (                                                               
                                                                       <div className="row">{(data.certname) && <>
                                                                       <div className="col-9 text-left"> 
                                                                       <a href={(data.certfilename) ? s3URL + data.certfilename : data.certurl}
                                                                        rel="noopener noreferrer" className="text-green-2 font-weight-semibold" 
                                                                        target="_blank" data-toggle="tooltip" title="Click here to view.">{data.certname}</a></div>
                                                                          </>}</div> )
                                                                    })}
                                                                     
                                                                      </td> 

                                                                    <td className="d-none py-5 text-center  d-none">
                                                                    {(data.expertCertificate) && data.expertCertificate.map((data, index) => {

                                                                        return (

                                                                            <li key={index} className="table-y-middle text-left ">

                                                                                {(!data.certname) ?'-': 
                                                                                <>
                                                                                <a href={(data.certfilename) ? s3URL + data.certfilename : data.certurl} rel="noopener noreferrer" 
                                                                                className="text-green-2 font-weight-semibold" target="_blank" data-toggle="tooltip" title="Click here to view.">{data.certname}</a>
                                                                                    </>}

                                                                            </li>
                                                                        )
                                                                    })}
                                                                   </td>

                                                                   

                                                                 
                                                                    <td className="table-y-middle   d-none  pr-0   text-center media justify-content-md-end">
                                                                        {data.idverified && data.profilecomplete && <div data-toggle="tooltip " className="text-right" >
                                                                            {!data.featured && <button className="border font-size-3 font-weight-bold text-green-2 px-5 rounded-5  mr-1 border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" data-toggle="tooltip" title="Click to feature the user" onClick={() => statusUpdate("feat", "feat", data.id)}  >
                                                                                Feature
                                                                            </button>}
                                                                            {data.featured && <button className="border font-size-3 font-weight-bold text-red px-5 rounded-5  mr-1 border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" data-toggle="tooltip" title="Click to unfeature the user" onClick={() => statusUpdate("unfeat", "feat", data.id)} >
                                                                                UnFeature
                                                                            </button>}</div>}



                                                                        <div data-toggle="tooltip " className="text-right  d-none" > {!data.flagged && <button className="border font-size-3 font-weight-bold text-red px-5 rounded-5  mr-1 border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" data-toggle="tooltip" title="Click to flag." onClick={(e) => toggleModal(data.id)}  >
                                                                            Flag
                                                                        </button>}
                                                                            {data.flagged && <button className="border  d-none font-size-3 font-weight-bold text-green-2 px-5 rounded-5  mr-1 border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" data-toggle="tooltip" title="Click to unflag." onClick={() => statusUpdate("unflag", "unflag", data.id)}>
                                                                                UnFlag
                                                                            </button>}</div>
                                                                    </td>

                                                                </tr>)
                                                        })}


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> <ModalFlagReason submitflag={submitflag} modalvisible={showModal} togglemodal={toggleModal} />
            </div>


        </>
    );
};