import {
    Switch, Routes, Route, Link, Navigate
} from "react-router-dom";

import Layout from "./components/Layout";
import { GlobalProvider } from "./context/GlobalContext";
import "./assets/fonts/fontawesome-5/css/all.min.css";


import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import "../node_modules/aos/dist/aos.css";

import "./assets/fonts/icon-font/css/style.css";


import "./scss/bootstrap.scss";
import "./scss/main.scss";

import MainPage from './pages/index.js';

import Dashboard from './pages/dashboard.js';
import Error404 from './pages/404.js';
 
import AuthProvider from './context/AuthProvider.js';

import { BrowserRouter } from 'react-router-dom';
import ProfileView from './pages/ProfileView.js';
const user = localStorage.getItem('connect');

function App() {
    return (

        <BrowserRouter>
            <div className="App" id="app">
                <AuthProvider> </AuthProvider>
                <Routes>
                    <Route path="/" element={<GlobalProvider><Layout pageContext={{}} ><MainPage zone="5" navType={(user) ? "profile" : "account"} /></Layout>   </GlobalProvider>} />                     
                    <Route path="/dashboard" element={<GlobalProvider><Layout pageContext={{ layout: "dashboard" }} ><Dashboard navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />                     
                    <Route path="/Auth" element={<GlobalProvider></GlobalProvider>} /> 
                    <Route path="/*" element={<GlobalProvider><Layout pageContext={{}}><Error404 navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/error" element={<GlobalProvider><Layout pageContext={{}}><Error404 navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/Expert/:SUB" element={<GlobalProvider><Layout pageContext={{}} ><ProfileView userType="Expert" navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/Client/:SUB" element={<GlobalProvider><Layout pageContext={{}} ><ProfileView userType="Client" navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                </Routes>
            </div></BrowserRouter>
    );
}

export default App;