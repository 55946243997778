import React, { useState, useEffect } from "react";
import { logoutUser, GetExpertProfile, GetClientProfile, GetExpertPreviewLoad } from "../utils/apiCalls";
import   useTabActive  from "../context/RefreshTab.js";
import { useLocation } from 'react-router-dom';
const GlobalContext = React.createContext();

const GlobalProvider = ({ children }) => {
    const [userInfo, setUserInfo] = useState({ email: "", userType: "", sub: "", profileInfo: null, userID: null });
    const [userLocation, setUserLocation] = useState(null);
    const [themeDark, setThemeDark] = useState(false);
    const [showSidebarDashboard, setShowSidebarDashboard] = useState(true);
    const [applicationModalVisible, setApplicationModalVisible] = useState(false);
    const [signInModalVisible, setSignInModalVisible] = useState(false);
    const [contactModalVisible, setContactModalVisible] = useState(false);
    const [forgotPasswordModalVisible, setForgotPasswordModalVisible] = useState(false);
    const [errorModalVisible, setErrorModalVisible] = useState(false);
    const [errorModal, setErrorModal] = useState('');
    const [infoModalVisible, setInfoModalVisible] = useState(false);
    const [loaderModalVisible, setLoaderModalVisible] = useState(false);
    const [generalModalVisible, setGeneralModalVisible] = useState(false);
    const [general, setGeneral] = useState('expert');
    const [videoModalVisible, setVideoModalVisible] = useState(false);
    const [visibleOffCanvas, setVisibleOffCanvas] = useState(false);
    const [currentPage, setCurrentPage] = useState('');
    const [countriesList, setCountriesList] = useState([{}]);
    const [visibleProfileMessage, setVisibleProfileMessage] = useState(false); 
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [confirmModal, setconfirmModal] = useState({ text: "", confirmed: false });
    const [jobsDetail, setJobsDetail] = useState({ currentJobId: null });
    const [refreshDashboard, setRefreshDashboard] = useState("");
    const [paginationSize, setPaginationSize] = useState(10);

    const pathName = useLocation().pathname;
    const [header, setHeader] = useState({
        theme: "light",
        bgClass: "default",
        variant: "primary",
        align: "left",
        isFluid: false,
        button: "cta", // profile, account, null
        buttonText: "Get started free", // profile, account, null
        reveal: true,
    });
    const [footer, setFooter] = useState({
        theme: "dark",
        style: "style1", //style1, style2
    });

    const toggleRefreshDashboard = (mode) => {
        setRefreshDashboard(mode);
    };

    useEffect(() => {

        const timezoneOffset = (new Date()).getTimezoneOffset(); 
        console.log(timezoneOffset); 
        const dateAsString = (new Date()).toString();
        const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
        console.log(timezone);
        var matches = timezone.match(/\b(\w)/g);
        var abbreviations = matches.join(''); 
        console.log("abbreviations", abbreviations);
        if (localStorage.getItem("connect")) {
            const user = JSON.parse(localStorage.getItem("connect"));
            getProfileData(user.payload["custom:usertype"], user.payload["email"], user.payload["sub"]);
        }

    }, []);
    async function getProfileInfo(userType, email, sub) {


       if (userType == "3") {
            try {

                const result = await GetClientProfile();

                if (result.data.clientPerLoad.responseData.code == "200") {
                    let response = result.data.clientPerLoad.responseData.message;
                    const data = JSON.parse(response);
                    setUserInfo({ email: email, userType: userType, sub: sub, ["profileInfo"]: response, userID: (data.length > 0) ? data[0].id : null, idVerified: data[0].idverified, profileComplete: data[0].profilecomplete  });
                      
                }
                else { 
                    setUserInfo({ email: email, userType: userType, sub: sub, profileInfo: null, userID: null, idVerified: false, profileComplete: false });
                     
                }
            }
            catch (e) { 
                setUserInfo({ email: email, userType: userType, sub: sub, profileInfo: null, userID: null,idVerified: false, profileComplete: false  });
               
            }
        }
    }

    async function getProfileData(userType, email, sub) {
        if (userType == "3") {
            try {

                const result = await GetClientProfile();
                setCurrentPage("1")
                console.log(result);
                if (result.data.clientPerLoad && result.data.clientPerLoad.responseData.code == "200") {
                    let response = result.data.clientPerLoad.responseData.message;
                    const data = JSON.parse(response);
                    
                    setUserInfo({ email: email, userType: userType, sub: sub, ["profileInfo"]: response, userID: (data.length > 0) ? data[0].id : null, idVerified: data[0].idverified, profileComplete: data[0].profilecomplete });
                    console.log(data);
                    if (pathName.toLowerCase() == "/auth" && data.length > 0) {
                        if (data[0].idverified && data[0].profilecomplete) {
                            window.location.href = "/dashboard";
                        } else { window.location.href = "/dashboard"; setCurrentPage("1");}
                            
                    }
                   
                }
                else {
                    setUserInfo({ email: email, userType: userType, sub: sub, profileInfo: null, userID: null });
                    if (pathName.toLowerCase() == "/auth")
                    {
                        window.location.href = "/dashboard";
                        setCurrentPage("1")
                    } 
                }
            }
            catch (e) {  
                setUserInfo({ email: email, userType: userType, sub: sub, profileInfo: null, userID: null }); 
            } 
        }
    }

    const toggleTheme = () => {
        setThemeDark(!themeDark);
    }; 
      

    const toggleSidebarDashboard = () => {
        setShowSidebarDashboard(!showSidebarDashboard);
    };
    const toggleConfirmModal = () => {
        setConfirmModalVisible(!confirmModalVisible);
    };
    const toggleVideoModal = () => {
        setVideoModalVisible(!videoModalVisible);
    };

    const toggleApplicationModal = () => {
        setApplicationModalVisible(!applicationModalVisible);
    };

    const toggleSignInModal = () => {
        setSignInModalVisible(!signInModalVisible);
    };
 

    const toggleGeneralModal = () => {
        setGeneralModalVisible(!generalModalVisible);
    };

    const toggleOffCanvas = () => {
        setVisibleOffCanvas(!visibleOffCanvas);
    };

    const closeOffCanvas = () => { 
        setVisibleOffCanvas(false);
    };

    const toggleErrorModal = () => {

        setErrorModalVisible(!errorModalVisible);
    };
    const toggleInfoModal = () => {
        setInfoModalVisible(!infoModalVisible);
    };
    const toggleContactModal = () => {
        setContactModalVisible(!contactModalVisible);
    };
    const toggleForgotPasswordModal = () => {
        setForgotPasswordModalVisible(!forgotPasswordModalVisible);
    };

    function logout() {
        logoutUser();
        localStorage.removeItem('connect');
        window.location.href = "/";
    }  
    useEffect(() => {
        if (currentPage == 5) {
            if (localStorage.getItem("connect")) {
                const user = JSON.parse(localStorage.getItem("connect"));
                getProfileInfo(user.payload["custom:usertype"], user.payload["email"], user.payload["sub"]);
            }} 
    }, [currentPage]); 
    return (
        <>  
        <GlobalContext.Provider
            value={{
                themeDark,
                toggleTheme,
                showSidebarDashboard,
                toggleSidebarDashboard,
                videoModalVisible,
                toggleVideoModal,
                applicationModalVisible,
                toggleApplicationModal,
                signInModalVisible,
                toggleSignInModal, 
                toggleGeneralModal,
                generalModalVisible, 
                errorModalVisible,
                general,
                setGeneral,
                setErrorModal,
                errorModal,
                toggleErrorModal,
                infoModalVisible,
                toggleInfoModal,
                toggleContactModal,
                contactModalVisible,
                toggleForgotPasswordModal,
                forgotPasswordModalVisible,
                visibleOffCanvas,
                toggleOffCanvas,
                closeOffCanvas,
                header,
                setHeader,
                footer,
                setFooter,
                countriesList, setCountriesList, currentPage, setCurrentPage,
                paginationSize, setPaginationSize,loaderModalVisible, setLoaderModalVisible,  setUserInfo ,userLocation, setUserLocation,  jobsDetail, setJobsDetail,userInfo, setUserInfo, logout, getProfileInfo, setVisibleProfileMessage, visibleProfileMessage, toggleConfirmModal, confirmModalVisible, setConfirmModalVisible, confirmModal, setconfirmModal,toggleRefreshDashboard, refreshDashboard, setRefreshDashboard
            }}
        >
            {children}
            </GlobalContext.Provider>
            {useTabActive()}
        </>
    );
};

export default GlobalContext;
export { GlobalProvider };
