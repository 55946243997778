import React, { useState, useEffect } from "react";
 
export default function Cookies() {
    const [showCookies, setShowCookies] = useState(false);

    useEffect(() => {
        
        setShowCookies(document.cookie.indexOf("accepted_cookies=") < 0);

    }, []);
    const handleCookiesClose = () => {
        setShowCookies(false);
    };
    const handleCookiesAccept = () => {
        document.cookie = "accepted_cookies=yes;";
        setShowCookies(false);
    };
  return (
    <>
          <div  className={` ${(showCookies) ? '' : 'd-none'}     cookie-overlay p-5`}>
              <div className="d-flex pb-5 ">
                  <div className="mr-3">We use cookies to improve user experience and analyze website traffic. By clicking "Accept", you agree to our website's cookie use as described in our <a target='_blank' href="/policy">'Cookie Policy'</a>.</div>
                  <i className="fas fa-times ml-auto close-cookies" onClick={handleCookiesClose} ></i>
              </div>
              <button className="btn btn-primary  accept-cookies" onClick={handleCookiesAccept}>Accept</button>
              <button className="btn btn-outline-primary  accept-cookies mx-2" onClick={handleCookiesClose}>Close</button>
          </div>
    </>
  );
};

