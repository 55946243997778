import React, { useContext, useEffect } from "react";

import PageWrapper from "../components/PageWrapper";
import { useParams } from "react-router-dom"; 
import ExpertProfileView from "../sections/Dashboard/ExpertProfileView";
import ClientProfileView from "../sections/Dashboard/ClientProfileView";
import GlobalContext from "../context/GlobalContext";
export default function ProfileViewPage(props) {
    const { SUB } = useParams();
    const gContext = useContext(GlobalContext);
    useEffect(() => { 
        document.title = "Profile | Syncuppro";
    }, []); 
    return (
        <>
            <PageWrapper headerConfig={{
                bgClass: "dynamic-sticky-bg",
                button: props.navType
            }}>
                {props.userType == "Expert" && <ExpertProfileView sub={SUB} mode={props.mode}/>}
                {props.userType == "Client" && <ClientProfileView sub={SUB} />}

            </PageWrapper>
        </>
    );
};
