 
import { AdminFeedbackQuery, getComplianceCategory, getCountry, getComplianceTypes, getIndustryTypes, getSkills, getExpertDegrees, getProjectLength, getExpLevels, getSocialProviders, getLanguages, getLanguagesProficiencyLevel, getWeeklyHrs, flagReasons, issueReportList, adminDashboardCount, adminJobsList,adminCompanyList} from './../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';

  
export async function GetCountry() {  
    
    let response;
        await API.graphql(graphqlOperation(getCountry)).then((result) => {
        response = result;
    }).catch((error) => { response = error; }); 
    return response; 
}   
export async function GetComplianceTypes() { 
   
    let response;
    await API.graphql(graphqlOperation(getComplianceTypes)).then((result) => {
        response = result;
    }).catch((error) => { response = error; });
    return response;
}   
export async function GetIndustryTypes() {

    let response;
    await API.graphql(graphqlOperation(getIndustryTypes)).then((result) => {
        response = result;
    }).catch((error) => { response = error; });
    return response;
}
export async function GetSkills() {

    let response;
    await API.graphql(graphqlOperation(getSkills)).then((result) => {
        response = result;
    }).catch((error) => { response = error; });
    return response;
}
export async function GetExpertDegrees() {

    let response;
    await API.graphql(graphqlOperation(getExpertDegrees)).then((result) => {
        response = result;
    }).catch((error) => { response = error; });
    return response;
}
export async function GetExpLevels() {

    let response;
    await API.graphql(graphqlOperation(getExpLevels)).then((result) => {
        response = result;
    }).catch((error) => { response = error; });
    return response;
}
export async function GetSocialProviders() {
    let response;
    await API.graphql(graphqlOperation(getSocialProviders)).then((result) => {
        response = result;
    }).catch((error) => { response = error; });
    return response;
}
export async function GetLanguages() {

    let response;
    await API.graphql(graphqlOperation(getLanguages)).then((result) => {
        response = result;
    }).catch((error) => { response = error; });
    return response;
}
export async function GetLanguagesProficiencyLevel() {
    let response;
    await API.graphql(graphqlOperation(getLanguagesProficiencyLevel)).then((result) => {
        response = result;
    }).catch((error) => { response = error; });
    return response;
}
export async function GetWeeklyHrs() {
    let response;
    await API.graphql(graphqlOperation(getWeeklyHrs)).then((result) => {
        response = result;
    }).catch((error) => { response = error; });
    return response;
}
export async function GetProjectLength() {
    let response;
    await API.graphql(graphqlOperation(getProjectLength)).then((result) => {
        response = result;
    }).catch((error) => {
        response = error;
    });
    return response;

}
export async function GetComplianceCategory() {
    let response;
    await API.graphql(graphqlOperation(getComplianceCategory)).then((result) => {
        response = result;
    }).catch((error) => {
        response = error;
    });
    return response;

}

export async function GetFlagReasons() {
    let response;
    await API.graphql(graphqlOperation(flagReasons)).then((result) => {
        response = result;
    }).catch((error) => {
        response = error;
    });
    return response;

}


export async function GetReportedIssues() {
    let response;
    await API.graphql(graphqlOperation(issueReportList)).then((result) => {
        response = result;
        
    }).catch((error) => {
        response = error;
       
    }); //adminDashboardCount
    return response;

}

export async function GetAdminDashboardCount() {
    let response;
    await API.graphql(graphqlOperation(adminDashboardCount)).then((result) => {
        response = result;
        console.log('res',response);
        
    }).catch((error) => {
        response = error;
       
    }); //adminDashboardCount
    return response;

}
export async function GetAdminJobsList() {
    let response;
    await API.graphql(graphqlOperation(adminJobsList)).then((result) => {
        response = result;
        console.log('res', response);

    }).catch((error) => {
        response = error;

    }); //adminDashboardCount
    return response;

}


export async function GetAdminCompanyList() {
    let response;
    await API.graphql(graphqlOperation(adminCompanyList)).then((result) => {
        response = result;
        console.log('reslist', response);

    }).catch((error) => {
        response = error;

    }); //adminDashboardCount
    return response;

}

export async function GetAdminFeedbackQuery() {
    let response;
    await API.graphql(graphqlOperation(AdminFeedbackQuery)).then((result) => {
        response = result;
        console.log('reslist', response);

    }).catch((error) => {
        response = error;

    }); //adminDashboardCount
    return response;

}