import React, { useState, useEffect, useContext} from "react"; 
import { getCountry } from './../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import axios from 'axios';
import GlobalContext from "../../context/GlobalContext";
const imgP = "assets/image/patterns/globe-pattern.png";


const axiosGraphQL = axios.create({
    baseURL: process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
    headers: {
        "x-api-key": process.env.REACT_APP_AWS_APIKEY,
    },
});

 

const Hero = () => {
    const gContext = useContext(GlobalContext);
    const [toggleSearchMenu, setToggleSearchMenu] = React.useState(false);
    const [searchMode, setSearchMode] = React.useState('job');
    const [countriesList, setCountriesList] = useState([{}]);
    const [values, setValues] = React.useState({ countryId: null, country:null, keyword: '' });
    useEffect(() => {

        getCountries();

    }, []);
    const handleChange = (e, name) => {

        const value = e;
        switch (name) {
            case "country":
                setValues({ ...values, ["country"]: value.value, ["countryId"]: value });
                break;
            case "keyword":
                setValues({ ...values, ["keyword"]: e.target.value });
                break;
            default:
                break;
        }
    }
 
    function navigateSearch() {
        console.log(values);
        if (searchMode == 'job') {
            localStorage.setItem('jobSearch', JSON.stringify(values));
           // window.location.href = "/searchjob";
            window.open("/searchjob", '_blank');
        }
        else
            window.location.href = "/searchexpert";


    }
    async function getCountries() {
        const result = await API.graphql(graphqlOperation(getCountry));
        if (result.data && result.data.countries) {
            setCountriesList(result.data.countries)
            console.log(result.data.countries);           
            let countlist = [];
            result.data.countries.map((value) => (
                countlist.push({ code:value.code,value: value.id, label: value.name })));
            setCountriesList(countlist);
            gContext.setCountriesList(countlist);
            console.log(countlist);
        }
         
    }
    function toggleSearch() {
        let current = toggleSearchMenu;
        setToggleSearchMenu(!current);

    }
    function toggleSearchMode(mode)
    {
        setSearchMode(mode);
        toggleSearch();
        document.getElementById("keyword").focus();

    }
    function showSearchOption()
    {
        setToggleSearchMenu(true);
        console.log(toggleSearchMenu);
    }
    return (
        <>
            {/* <!-- Hero Area --> */}
            <div className="position-relative z-index-1 bg-squeeze-opacity-2 pt-32 pb-25 dark-mode-texts min-h-90vh">
                <div className="pos-abs-tr h-100">
                    <img src={imgP} alt="" className="h-100 w-100 " />
                </div>
                <div className="container position-static">
                    <div className="row position-relative align-items-center position-static"> 
                   
                    </div>
                </div>
            </div>
        </>
    );
};

export default Hero;
