import React, { useContext } from "react";
import { Link } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import Logo from "../Logo/Logo";
import { HashLink } from "react-router-hash-link";
const imgM = "assets/image/l1/png/message.png";
const appVersion = process.env.REACT_APP_VERSION;
const prevyear = new Date().getFullYear();
const currentyear = new Date().getFullYear()+1;
//const copyrightyear;

//var  copyrightyear;




const Footer = () => {
    const gContext = useContext(GlobalContext);
    return (
        <>
            <footer className="footer bg-ebony-clay dark-mode-texts">
                <div className="container">
                    {/* <!-- Cta section --> */}
                    <div className="pt-11 pt-lg-20 pb-13 pb-lg-20 border-bottom border-width-1 border-default-color-2">
                        <div className="row justify-content-center ">
                            <div
                                className="col-xl-7 col-lg-12"
                                data-aos="fade-right"
                                data-aos-duration="1000"
                            >
                                {/* <!-- cta-content start --> */}
                                <div className="pb-xl-0 pb-9 text-xl-left text-center">
                                    <h2 className="text-white font-size-8 mb-4">
                                        Most comprehensive job portal
                  </h2>
                                    <p className="text-hit-gray font-size-5 mb-0">
                                        We must explain to you how all this mistaken idea of
                                        denouncing
                  </p>
                                </div>
                                {/* <!-- cta-content end --> */}
                            </div>
                            <div
                                className="col-xl-5 col-lg-12"
                                data-aos="fade-left"
                                data-aos-duration="1000"
                            >
                                {/* <!-- cta-btns start --> */}
                                <div className="btns d-flex justify-content-xl-end justify-content-center align-items-xl-center flex-wrap h-100  mx-n4">
                                    {gContext.userInfo.email == '' && <> <a
                                        className="btn btn-outline-gallery btn-xl mx-4 mt-6 text-uppercase"
                                        href="/#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            gContext.toggleSignInModal();
                                        }}
                                    >
                                        <i class="fa fa-sign-in-alt mr-2 pt-1" > </i> Log in
                  </a>
                                        <a
                                            className="btn btn-green btn-h-60 btn-xl mx-4 mt-6 text-uppercase"
                                            href="/#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                gContext.toggleSignUpModal();
                                            }}
                                        >
                                            Sign Up
                  </a></>}
                                </div>
                                {/* <!-- cta-btns end --> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container  pt-12 pt-lg-19 pb-10 pb-lg-19">
                    <div className="row">
                        <div className="col-lg-5 col-sm-6 mb-lg-0 mb-9">
                            {/* <!-- footer logo start --> */}
                            <Logo white className="footer-logo mb-14" />
                            {/* <!-- footer logo End --> */}
                            {/* <!-- media start --> */}
                            <div className="media mb-11">
                                <img src={imgM} className="align-self-center mr-3" alt="" />
                                <div className="media-body pl-5">
                                    <p className="mb-0 font-size-4 text-white">Contact us at</p>

                                    <a className="mb-0 font-size-4 font-weight-bold">
                                        contact@syncuppro.com
                                      </a>

                                </div>
                            </div>
                            {/* <!-- media start --> */}
                            {/* <!-- widget social icon start --> */}

                            {/* <!-- widget social icon end --> */}
                        </div>
                        <div className="col-lg-7 col-md-6">
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-3 col-xs-6">
                                    <div className="footer-widget widget2 mb-md-0 mb-13">
                                        {/* <!-- footer widget title start --> */}
                                        <p className="widget-title font-size-4 text-gray mb-md-8 mb-7">
                                            Company
                    </p>
                                        {/* <!-- footer widget title end --> */}
                                        {/* <!-- widget social menu start --> */}
                                        <ul className="widget-links pl-0 list-unstyled list-hover-primary">
                                            <li className="mb-6">

                                                <HashLink className="heading-default-color font-size-4 font-weight-normal" to="/#whoweare">
                                                    Who we are
                          </HashLink>

                                            </li>
                                            <li className="mb-6">

                                                <HashLink className="heading-default-color font-size-4 font-weight-normal" to="/#howitworks">
                                                    How it works
                          </HashLink>

                                            </li>
                                            <li className="mb-6">

                                                <a className="heading-default-color font-size-4 font-weight-normal" target="_blank" rel="noopener noreferrer" href="/contact"  >
                                                    Contact us
                          </a>

                                            </li>
                                            <li className="mb-6">

                                                <a className="heading-default-color font-size-4 font-weight-normal" href="/faq" target="_blank" rel="noopener noreferrer" onClick={window.scrollTo(0, 0)} >
                                                    FAQ
                          </a>

                                            </li>
                                            <li className="mb-6">

                                                <a className="heading-default-color font-size-4 font-weight-normal" onClick={(e) => {
                                                    e.preventDefault();
                                                    gContext.setErrorModal("Value is not valid");
                                                    gContext.toggleErrorModal();
                                                }}>

                                                </a>

                                            </li>

                                        </ul>
                                        {/* <!-- widget social menu end --> */}
                                    </div>
                                </div>


                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-3 col-xs-6">
                                    <div className="footer-widget widget4">
                                        {/* <!-- footer widget title start --> */}
                                        <p className="widget-title font-size-4 text-gray mb-md-8 mb-7">
                                            Legal
                    </p>
                                        {/* <!-- footer widget title end --> */}
                                        <ul className="widget-links pl-0 list-unstyled list-hover-primary">
                                            <li className="mb-6">

                                                <a className="heading-default-color font-size-4 font-weight-normal" href="/Policy" rel="noopener noreferrer" target="_blank">
                                                    Privacy Policy
                          </a>

                                            </li>
                                            <li className="mb-6">

                                                <a className="heading-default-color font-size-4 font-weight-normal" href="/terms" rel="noopener noreferrer" target="_blank">
                                                    Terms &amp; Conditions
                          </a>

                                            </li>
                                            <li className="mb-6">


                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-3 col-xs-6">
                                    <div className="footer-widget widget4">
                                        <div className="social-icons">
                                            <ul className="pl-0 list-unstyled d-flex align-items-end ">
                                                <li className="d-flex flex-column justify-content-center px-3 mr-3 font-size-4 heading-default-color">
                                                    Follow us on:
                                                  </li>
                                                <li className="d-flex flex-column justify-content-center px-3 mr-3">

                                                    <a className="hover-color-primary heading-default-color">
                                                        <i className="fab fa-facebook-f font-size-3 pt-2"></i>
                                                    </a>

                                                </li>
                                                <li className="d-flex flex-column justify-content-center px-3 mr-3">

                                                    <a className="hover-color-primary heading-default-color">
                                                        <i className="fab fa-twitter font-size-3 pt-2"></i>
                                                    </a>

                                                </li>
                                                <li className="d-flex flex-column justify-content-center px-3 mr-3">

                                                    <a className="hover-color-primary heading-default-color">
                                                        <i className="fab fa-linkedin-in font-size-3 pt-2"></i>
                                                    </a>

                                                </li>
                                            </ul>
                                        </div>
                                        </div>
                                        </div>
                                    </div>
                                 </div>
                    </div>
                </div>



                <div className="container  pt-12 pt-lg-19 pb-10 pb-lg-19">
                    <div className="row">
                        <div className="col-lg-5 col-sm-6 mb-lg-0 mb-9">
                            {/* <!-- footer logo start --> */}
                            <i className="fa-solid fa-copyright"></i> &nbsp;
                                    <span className="widget-title font-size-4 text-gray mb-md-8 mb-7mb-0 font-size-4 text-white">
                                     Copyright  {(prevyear == 2022 ? prevyear : prevyear +'-'+currentyear)} </span>
                            {/* <!-- footer logo End --> */}
                            {/* <!-- media start --> */}
                           
                            {/* <!-- media start --> */}
                            {/* <!-- widget social icon start --> */}

                            {/* <!-- widget social icon end --> */}
                        </div>
                        <div className="col-lg-7 col-md-6">
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-3 col-xs-6">
                                    <div className="footer-widget widget2 mb-md-0 mb-13">
                                        {/* <!-- footer widget title start --> */}
                                       
                    
                                        {/* <!-- footer widget title end --> */}
                                        {/* <!-- widget social menu start --> */}
                                       
                                        {/* <!-- widget social menu end --> */}
                                    </div>
                                </div>


                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-3 col-xs-6">
                                    <div className="footer-widget widget4">
                                        {/* <!-- footer widget title start --> */}
                                        
                                        {/* <!-- footer widget title end --> */}
                                        
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-3 col-xs-6">
                                    <div className="footer-widget widget4">
                                        <div className="social-icons">
                                            <ul className="pl-0 list-unstyled d-flex align-items-end ">
                                                <li className="d-flex flex-column justify-content-right px-3 mr-3 font-size-4 heading-default-color">
                                                   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                  </li>
                                                
                                                
                                                <li className="d-flex flex-column  px-3 mr-3">

                                                <span className="widget-title font-size-4 text-gray mr-13 font-size-4 text-white">{appVersion}</span>

                                                </li>
                                            </ul>
                                        </div>
                                        </div>
                                        </div>
                                    </div>
                                 </div>
                    </div>
                </div> 
                
            </footer>
        </>
    );
};

export default Footer;