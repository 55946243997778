import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { Container, Dropdown } from "react-bootstrap";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useWindowSize } from "../../hooks/useWindowSize";
import GlobalContext from "../../context/GlobalContext";
import Offcanvas from "../Offcanvas/Offcanvas";
import NestedMenu from "../NestedMenu/NestedMenu";
import { GetExpertPreviewLoad, GetClientPreviewProfile } from "../../utils/apiCalls";
import { device } from "../../utils";
import Logo from "../Logo/Logo";
import { menuItems } from "./menuItems";
import ReactCountryFlag from "react-country-flag";

 

const SiteHeader = styled.header`
  .dropdown-toggle::after {
    opacity: 0;
  }

  padding: 5px 0 5px 0;
  position: absolute !important;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  @media ${device.lg} {
    position: fixed !important;
    transition: 0.6s;
    &.scrolling {
      transform: translateY(-100%);
      transition: 0.6s;
    }
    &.reveal-header {
      transform: translateY(0%);
      box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
      z-index: 9999;
      background: ${({ dark, theme }) => (dark ? theme.colors.dark : "#fff")};
    }
  }

`;

const ToggleButton = styled.button`
  color: ${({ dark, theme }) =>
        dark ? theme.colors.lightShade : theme.colors.heading}!important;
  border-color: ${({ dark, theme }) =>
        dark ? theme.colors.lightShade : theme.colors.heading}!important;
`;

const Header = () => {
    const gContext = useContext(GlobalContext);
    const [showScrolling, setShowScrolling] = useState(false);
    const [showReveal, setShowReveal] = useState(false);
    const [userName, setUserName] = useState('');
    const [country, setcountryCode] = useState(null);
    const [avatar, setAvatar] = useState('../assets/image/avatar.png');
    const size = useWindowSize();
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const appVersion = process.env.REACT_APP_VERSION;
    useScrollPosition(({ prevPos, currPos }) => {
        if (currPos.y < 0) {
            setShowScrolling(true);
        } else {
            setShowScrolling(false);
        }
        if (currPos.y < 0) {
            setShowReveal(true);
        } else {
            setShowReveal(false);
        }
    });
  
    async function ClientPreviewInfo() {
        try { 
            const result = await GetClientPreviewProfile(null);
            
            if (result.data.clientAllLoad.responseData.code == "200") {

                let profile = JSON.parse(result.data.clientAllLoad.responseData.message);
             
                if (profile && profile.length > 0) {  
                    setcountryCode((profile[0].code) ? profile[0].code : "US"); 
                    setAvatar((profile[0].logo != "") ? s3URL + profile[0].logo : "../assets/image/avatar.png");

                    gContext.setUserLocation({ code: profile[0].code, value: profile[0].countryid, label: profile[0].countryname });

                }
                else
                    gContext.setUserLocation(null); 
            }

        }
        catch (e) { 
        }

    }
    useEffect(() => {
        
            ClientPreviewInfo();
        
    }, [ ]);
    useEffect(() => {
      
       
             ClientPreviewInfo();
            const data = JSON.parse(gContext.userInfo.profileInfo);
            setUserName((data && data.length > 0 && data[0] != null) ? ((data[0].firstname) ? data[0].firstname : "") + " " + ((data[0].lastname) ? data[0].lastname.substring(0, 1) : "") : (gContext.userInfo.email) ? gContext.userInfo.email.split('@')[0] : '');
            
    }, [gContext.userInfo]);


    return (
        <>
            <SiteHeader
                className={`site-header site-header--sticky   py-0 py-xs-0 sticky-header mobile-sticky-enable ${
                    gContext.header.bgClass
                    } ${
                    gContext.header.align === "left"
                        ? "site-header--menu-left "
                        : gContext.header.align === "right"
                            ? "site-header--menu-right "
                            : "site-header--menu-center "
                    }
        ${gContext.header.theme === "dark" ? "dark-mode-texts" : " "} ${
                    showScrolling ? "scrolling" : ""
                    } ${
                    gContext.header.reveal &&
                        showReveal &&
                        gContext.header.theme === "dark"
                        ? "reveal-header bg-blackish-blue"
                        : gContext.header.reveal && showReveal
                            ? "reveal-header"
                            : ""
                    }`}
            >
                <Container
                    fluid={gContext.header.isFluid}
                    className={gContext.header.isFluid ? "pr-lg-9 pl-lg-9" : ""}
                >
                    <nav className="navbar site-navbar  offcanvas-active navbar-expand-lg px-0 py-md-0 py-xl-0 py-xxl-0 py-xs-5 py-lg-0  ">
                        {/* <!-- Brand Logo--> */}
                        <div className="brand-logo">
                            <Logo white={gContext.header.theme === "light"} />
                        </div>
                        <div className="text-green  font-size-7    font-weight-bold pl-3">Admin</div>
                        <div className="collapse navbar-collapse">
                            <div className="navbar-nav-wrapper">
                                <ul className="navbar-nav main-menu d-none d-lg-flex">
                                    {menuItems.map(
                                        (
                                            { label, isExternal = false, name, items, ...rest },
                                            index
                                        ) => {
                                            const hasSubItems = Array.isArray(items);
                                            return (
                                                <React.Fragment key={name + index}>
                                                    {hasSubItems ? (
                                                        <li className="nav-item dropdown" {...rest}>
                                                            <a
                                                                className="nav-link dropdown-toggle gr-toggle-arrow"
                                                                role="button"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                                href="/#"
                                                                onClick={(e) => e.preventDefault()}
                                                            >
                                                                {label}
                                                                <i className="icon icon-small-down"></i>
                                                            </a>
                                                            <ul className="gr-menu-dropdown dropdown-menu ">
                                                                {items.map((subItem, indexSub) => {
                                                                    const hasInnerSubItems = Array.isArray(
                                                                        subItem.items
                                                                    );
                                                                    return (
                                                                        <React.Fragment
                                                                            key={subItem.name + indexSub}
                                                                        >
                                                                            {hasInnerSubItems ? (
                                                                                <li className="drop-menu-item dropdown">
                                                                                    <a
                                                                                        className="dropdown-toggle gr-toggle-arrow"
                                                                                        role="button"
                                                                                        data-toggle="dropdown"
                                                                                        aria-expanded="false"
                                                                                        aria-haspopup="true"
                                                                                        href="/#"
                                                                                        onClick={(e) => e.preventDefault()}
                                                                                    >
                                                                                        {subItem.label}
                                                                                        <i className="icon icon-small-down"></i>
                                                                                    </a>
                                                                                    <ul className="gr-menu-dropdown dropdown-menu dropdown-left">
                                                                                        {subItem.items.map(
                                                                                            (itemInner, indexInnerMost) => (
                                                                                                <li
                                                                                                    className="drop-menu-item"
                                                                                                    key={
                                                                                                        itemInner.name +
                                                                                                        indexInnerMost
                                                                                                    }
                                                                                                >
                                                                                                    {itemInner.isExternal ? (
                                                                                                        <a
                                                                                                            href={`${itemInner.name}`}
                                                                                                            target="_blank"
                                                                                                            rel="noopener noreferrer"
                                                                                                        >
                                                                                                            {itemInner.label}
                                                                                                        </a>
                                                                                                    ) : (

                                                                                                            <a>{itemInner.label}</a>

                                                                                                        )}
                                                                                                </li>
                                                                                            )
                                                                                        )}
                                                                                    </ul>
                                                                                </li>
                                                                            ) : (
                                                                                    <li className="drop-menu-item">
                                                                                        {subItem.isExternal ? (
                                                                                            <a
                                                                                                href={`${subItem.name}`}
                                                                                                target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                            >
                                                                                                {subItem.label}
                                                                                            </a>
                                                                                        ) : (

                                                                                                <a>{subItem.label}</a>

                                                                                            )}
                                                                                    </li>
                                                                                )}
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </li>
                                                    ) : (
                                                            <li className="nav-item" {...rest}>
                                                                {isExternal ? (
                                                                    <HashLink
                                                                        className="nav-link"
                                                                        href={`${name}`}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        {label}
                                                                    </HashLink>
                                                                ) : (

                                                                        <HashLink
                                                                            className="nav-link"
                                                                            role="button"
                                                                            aria-expanded="false"
                                                                            to={`${name}`}
                                                                        >
                                                                            {label}
                                                                        </HashLink>

                                                                    )}
                                                            </li>
                                                        )}
                                                </React.Fragment>
                                            );
                                        }
                                    )}

                                  
                                </ul>
                            </div>
                        </div>
                       
                        {(appVersion) && <div className="bg-yellow-opacity-5 font-weight-bold px-5 mt-5 mb-5 text-red d-none">{appVersion}</div>}
                        {gContext.header.button === "cta" && (
                            <div className="header-btn ml-auto ml-lg-0 mr-6 mr-lg-0 d-none d-xs-block">

                                <a className={`btn btn-${gContext.header.variant}`} onClick={(e) => {
                                    e.preventDefault();
                                    gContext.toggleApplicationModal();
                                }}>
                                    {gContext.header.buttonText}
                                </a>

                            </div>
                        )}

                        {gContext.header.button === "profile" && (
                            <div className="header-btn-devider ml-auto ml-lg-5 pl-2   d-xs-flex align-items-center d-none d-xs-flex">
                                <div className="flex-y-center  media">
                                    Signed in as
                                   
                                        <a href="/dashboard"> <img src={avatar} alt="" className="circle-36 ml-5 mr-2" data-toggle="tooltip" title="Click to view profile"
                                        /></a>
                                    <span className="font-weight-semibold"><a href="/dashboard" className="text-green-2">{userName}</a></span>

                                </div> 
                                <div>

                                    <Dropdown className="show-gr-dropdown py-5 d-none">
                                        <Dropdown.Toggle
                                            as="a"
                                            className="proile media ml-5 flex-y-center"
                                        >
                                            <ReactCountryFlag
                                                countryCode={country}
                                                svg
                                                style={{
                                                    width: '2em',
                                                    height: '1em',
                                                }}
                                              
                                            />    
                                            <i className="fas fa-chevron-down heading-default-color ml-6 d-none"></i>

                                        </Dropdown.Toggle>
                                        {size.width <= 991 ? (
                                            <Dropdown.Menu
                                                className="gr-menu-dropdown border-0 border-width-2 py-2 d-none w-auto bg-default"
                                                key="1"
                                            >

                                                <a className="dropdown-item py-2 font-size-3 font-weight-semibold line-height-1p2 text-uppercase">
                                                    Settings
                          </a>


                                                <a className="dropdown-item py-2 font-size-3 font-weight-semibold line-height-1p2 text-uppercase" href="/profile">
                                                    Edit Profile
                          </a>


                                                <a className=" dropdown-item py-2 text-red font-size-3 font-weight-semibold line-height-1p2 text-uppercase" onClick={() => { gContext.logout(); }}>
                                                    Log Out
                          </a>

                                            </Dropdown.Menu>
                                        ) : (
                                                <div
                                                    className="dropdown-menu gr-menu-dropdown dropdown-right border-0  d-none  border-width-2 py-2 w-auto bg-default"
                                                    key="2"
                                                >

                                                    <a className="dropdown-item py-2 font-size-3 font-weight-semibold line-height-1p2 text-uppercase">
                                                        Settings
                          </a>


                                                    <a className="dropdown-item py-2 font-size-3 font-weight-semibold line-height-1p2 text-uppercase" href="/profile">
                                                        Edit Profile
                          </a>


                                                    <a className=" dropdown-item py-2 text-red font-size-3 font-weight-semibold line-height-1p2 text-uppercase" onClick={() => { gContext.logout(); }}>
                                                        Log Out
                          </a>

                                                </div>
                                            )}
                                    </Dropdown>

                                </div>

                            </div>
                        )}

                        {gContext.header.button === "account" && (
                            <div className="header-btns  ml-auto pr-2 ml-lg-6 d-none d-xs-flex ">
                                <div className="pr-2">    <a
                                    className={`btn btn-${gContext.header.variant}   text-uppercase font-size-3 `}
                                    href="/#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        gContext.toggleSignInModal();
                                    }}
                                >
                                    <i class="fa fa-sign-in-alt mr-2 pt-1" > </i>          Log In
                </a></div>
                                
                            </div>
                        )}

                        <ToggleButton
                            className={`navbar-toggler btn-close-off-canvas ml-3 ${
                                gContext.visibleOffCanvas ? "collapsed" : ""
                                }`}
                            type="button"
                            data-toggle="collapse"
                            data-target="#mobile-menu"
                            aria-controls="mobile-menu"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={gContext.toggleOffCanvas}
                            dark={gContext.header.theme === "dark" ? 1 : 0}
                        >
                            {/* <i className="icon icon-simple-remove icon-close"></i> */}
                            <i className="icon icon-menu-34 icon-burger d-block"></i>
                        </ToggleButton>
                    </nav>
                </Container>
            </SiteHeader>
            <Offcanvas
                show={gContext.visibleOffCanvas}
                onHideOffcanvas={gContext.toggleOffCanvas}
            >
                <NestedMenu menuItems={menuItems} />
            </Offcanvas>
        </>
    );
};
export default Header;