import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import { AdminUsers, AdminStatusUpdate, AdminExpCertStatus, FlagUser } from "../../utils/apiCalls";
import { GetCountry } from "../../utils/GetAPIUtils";
import { Select } from "../../components/Core";
import ReactCountryFlag from "react-country-flag";
import DatePicker from "react-datepicker";
import ModalFlagReason from "./ModalFlagReason";
import { getMonthDateYear } from "../../utils/addDays";
import CustomDatePicker from "../../components/Core/CustomDatePicker";
import TablePagination from "../../components/Table/TablePagination";
import tableHelper from "../../components/Table/tableHelper";

export default function Experts(props) {

    const gContext = useContext(GlobalContext);
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [allusers, setAllusers] = useState([]);
    const [experts, setExperts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [countriesList, setCountriesList] = useState([]);
    const [status, setStatus] = useState([{ value: "", label: "All" }, { value: "true", label: "Completed" }, { value: "false", label: "Not Completed" }]);
    const [currentValue, setCurrentValue] = useState({ index: null, id: null, code: "" });
    const [currentUser, setCurrentUser] = useState(null);
    const [countryUS, setUsCountry] = React.useState(null);
    const [values, setValues] = useState({ profile: { value: "", label: "All" }, assessment: { value: "", label: "All" }, featured: { value: "", label: "All" }, flagged: { value: "", label: "All" }, country: null, name: "" , fromDate: null, toDate: null });
    const [page, setPage] = useState(1);
    const { slice, range } = tableHelper(experts, page, gContext.paginationSize);
    const handleSelectChange = (e, field) => {
        setValues({ ...values, [field]: e });
    }
    const handleChange = (e,field) => {
        setValues({ ...values, [field]: e.target.value });
    }
    function submitFilter() {
        
        if ((values.profile == null || values.profile?.value == "") && (values.assessment == null || values.assessment?.value == "") && (values.featured == null || values.featured?.value == "") && (values.flagged == null || values.flagged?.value == "") && values.name == "" && values.country == null && !values.fromDate && !values.toDate) {
            setExperts(allusers);            
        }
        else
        {
            let data = allusers;
             
            let list, final = data;
            let assessment, featured,flagged,name,country,regDate;
            if (values.profile == null || values.profile.value != "") {
                list = (values.profile.value == "true") ? data.filter(item => item["profilecomplete"] == true) : data.filter(item => (item["profilecomplete"] == false));
                final = list;
            }
            if (values.assessment == null || values.assessment.value != "") {
                assessment = (values.assessment.value == "true") ? final.filter(item => item["assessmentcomplete"] == true) : final.filter(item => (item["assessmentcomplete"] == false || item["assessmentcomplete"] == null));
               final=assessment;
            } 
            if (values.featured == null || values.featured.value != "") {
                featured = (values.featured.value == "true") ? final.filter(item => item["featured"] == true) : final.filter(item => (item["featured"] == false || item["featured"] == null));
                final=featured;
            } 
            if (values.flagged == null || values.flagged.value != "") {
                flagged = (values.flagged.value == "true") ? final.filter(item => item["flagged"] == true) : final.filter(item => (item["flagged"] == false || item["flagged"] == null));
                final=flagged;
            } 
            if (values.name == null || values.name != "") {
                
                name = final.filter(item => (item["firstname"]?.toLowerCase() + " " + item["lastname"]?.toLowerCase()).match(values.name.toLowerCase()));
                final = name;                 
            } 
            if (values.country != null ) {
                country = final.filter(item => item["countrycode"]==values.country.code);
                final = country;
            }
           
            if (values.fromDate && values.toDate) {
               
                regDate = final.filter(item => (new Date(item["datecreated"]) >= new Date(values.fromDate) && new Date(item["datecreated"]) <= new Date(values.toDate)));
                final = regDate;
            }
            if (values.fromDate && !values.toDate) {
                regDate = final.filter(item => new Date(item["datecreated"]) >= new Date(values.fromDate) && new Date(item["datecreated"]) <= new Date());
                final = regDate;
            }
            if (!values.fromDate && values.toDate) {
                regDate = final.filter(item => new Date(item["datecreated"]) <= new Date(values.toDate));
                final = regDate;
            }
           
            setExperts(final);
        }
    }
    async function LoadCountry() {
        try {
            if (countriesList.length <= 1) {
                const resultCountry = await GetCountry();
                if (resultCountry.data && resultCountry.data.countries) {
                    let countlist = [];
                    let usCountry = resultCountry.data.countries.filter(item => item.code == "US");
                    let list = resultCountry.data.countries.filter(item => item.code != "US");
                    usCountry.map((value) => {
                      //  setValues({ ...values, ["country"]: { code: value.code, value: value.id, label: value.name } });
                        setUsCountry({ code: value.code, value: value.id, label: value.name });
                    });
                    usCountry.map((value) => (
                        countlist.push({ code: value.code, value: value.id, label: value.name })));

                    
                    list.map((value) => (
                        countlist.push({ code: value.code, value: value.id, label: value.name })));
                    setCountriesList(countlist);
                }
            }
        } catch (e) {
            console.log(e);

        }
    }
    const handleDateChange = (name, value) => {

        setValues({ ...values, [name]: value });   
    }
    async function LoadExpert() {
        try {
            gContext.setLoaderModalVisible(true);
            const result = await AdminUsers("all", 1);
            if (result.data.adminUsers && result.data.adminUsers.responseData.code == "200") {
                let data = JSON.parse(result.data.adminUsers.responseData.message);
                
                if (data.length > 0) {
                    let list = data.filter(item => item["delete"] == false);
                    setExperts(list); 
                    setAllusers(list);
                  setValues({ profile: { value: "", label: "All" }, assessment: { value: "", label: "All" }, featured: { value: "", label: "All" }, flagged: { value: "", label: "All" }, country: null, name: "" , fromDate: null, toDate: null });
                    
                }
                else {
                    gContext.setLoaderModalVisible(false);
                }
            } else {
                gContext.setLoaderModalVisible(false);
            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
        }
    }

    async function UpdateStatus(status, statusType, userId, reason) {
        try {
            gContext.setLoaderModalVisible(true);
            const result = await AdminStatusUpdate(status, statusType, userId, 1, reason);
            if (result.data.adminStatusUpdate && result.data.adminStatusUpdate.responseData.code == "200") { 
                LoadExpert(); 
            } else {
                gContext.setLoaderModalVisible(false);
            } 
            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
        }
    }
    async function UpdateCertStatus(status,statusType, userId,reason,certId) {
        try {
            gContext.setLoaderModalVisible(true);
            /*const result = await AdminStatusUpdate(status, statusType, userId, 1);
            console.log(result);
            if (result.data.adminStatusUpdate && result.data.adminStatusUpdate.responseData.code == "200") {*/
            const result = await AdminExpCertStatus(certId, userId, status);
            //AdminExpCertStatus(certId, userId, status);
            
            if (result.data.adminUpdateExpCertStatus && result.data.adminUpdateExpCertStatus.responseData.code == "200") {
              
                
                LoadExpert();

            } else {
                gContext.setLoaderModalVisible(false);
            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
        }
    }

    async function submitflag(flagId) {
        if (currentUser) {
            try {
                gContext.setLoaderModalVisible(true);
                const result = await FlagUser(flagId, currentUser, 1);
                 
                if (result.data.flagUser && result.data.flagUser.responseData.code == "200") {
                    LoadExpert();
                    gContext.setLoaderModalVisible(false);

                } else {
                    gContext.setLoaderModalVisible(false);
                }

                gContext.setLoaderModalVisible(false);
            }
            catch (e) {
                gContext.setLoaderModalVisible(false);
            }
        }
        else
            setCurrentUser(null);
    }
    function toggleModal(userId) {
        setCurrentUser(userId);
        setShowModal(!showModal);
    }


    function sociallink(link) {

        var reg = new RegExp("^(http|https)://", "i");

        var match = reg.test(link);
        //alert(link);
        if (match == true) {
            return link;
        }
        else {
            link = 'https://' + link;
            return link;
        }



    }

    const statusUpdate = (status, statusType, userId) => {

        if (userId) {
            if (status == "unflag")
                setCurrentUser(userId);
            let code = (status === "feat") ? "feature" : (status === "unfeat") ? "unfeature" : status;
            gContext.setconfirmModal({ header: " ", ["text"]: "Do you want to " + code + "?", ["confirmed"]: false, id: userId, showButton: true, mode: status, code: statusType,page:"expert" });
            gContext.toggleConfirmModal();
        }
    }

    const certStatusUpdate = (certstatus,status,statusType, userId,certId) => {
        //certStatusUpdate("true","approve", "cert", data.id,cert.id)
        
        if (userId) {
            if (status == "unflag")
                setCurrentUser(userId);
            gContext.setconfirmModal({ header: " ", ["text"]: "Do you want to " + status + "?", ["confirmed"]: false, certstatus:certstatus,  id: userId, showButton: true, mode: status, code: statusType,reason:"",certid:certId});
            gContext.toggleConfirmModal();
        } 
    }

    useEffect(() => {
        if (gContext.confirmModal.confirmed) {

            
            if (gContext.confirmModal.mode == "approve") {
                UpdateCertStatus(gContext.confirmModal.certstatus, gContext.confirmModal.code, gContext.confirmModal.id, gContext.confirmModal.reason ,gContext.confirmModal.certid); 
           }
           else if (gContext.confirmModal.mode == "reject") {
            UpdateCertStatus(gContext.confirmModal.certstatus, gContext.confirmModal.code, gContext.confirmModal.id, gContext.confirmModal.reason,gContext.confirmModal.certid); 
           }
            
            
            
            if (gContext.confirmModal.mode == "unflag") {
                submitflag(-1);
                setCurrentUser(null);
            }

            if (gContext.confirmModal.mode == "approve") {
                UpdateStatus("approve", gContext.confirmModal.code, gContext.confirmModal.id, gContext.confirmModal.reason ); 
           }
           else if (gContext.confirmModal.mode == "reject") {
                UpdateStatus("reject", gContext.confirmModal.code, gContext.confirmModal.id, gContext.confirmModal.reason); 
           }
            else {
                UpdateStatus(gContext.confirmModal.mode, gContext.confirmModal.code, gContext.confirmModal.id);
            }

            gContext.setconfirmModal({
                ...gContext.confirmModal, ["confirmed"]: false, ["id"]: null
            });

        }
        else { setCurrentValue({ index: null, id: null }); }
    }, [gContext.confirmModal]);
    useEffect(() => {
        document.title = "All Experts | Syncuppro";
        LoadCountry();
        LoadExpert();
    }, []);
   
    return (
        <>
            <div
                className="dashboard-main-container mt-24 mt-lg-20 min-h-100vh"
                id="dashboard-body"
            >
                <div className="container">
                    <div className="mb-15 mb-lg-10">
                        <div className="row">
                            <div className="font-size-4 font-weight-semibold  bg-athens  w-100  text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">

                            </div>
                         
                            

                            <div className="col-lg-3 text-left">
                                <label className="form-label  "><i className="fa fa-filter m-2 text-green"></i>Name</label>
                            </div>
                            <div className="col-lg-3 text-left">
                                <label className="form-label "><i className="fa fa-filter m-2 text-green"></i>Location</label>
                            </div>
                            <div className="col-lg-3 text-left"><i className="fa fa-filter m-2 text-green"></i>Featured Status</div>
                            <div className="col-lg-3 text-left"><i className="fa fa-filter m-2 text-green"></i>Flagged Status</div>
                            <div className="col-lg-3 text-left ">
                                <div className="forn-group-input">
                                    <input
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        placeholder="Name" defaultValue={values.name} value={values.name} onChange={(e) => (handleChange(e,"name"))}
                                    />

                                </div>


                            </div>
                            <div className="col-lg-3 text-left  ">
                                <div className="forn-group-input">

                                    <Select options={countriesList} placeholder="All"
                                        value={values.country} 
                                        className={'form-control pl-0 arrow-3 w-100 font-size-4 d-flex align-items-center w-100 mb-4 '}
                                        border={false} onChange={(e) => handleSelectChange(e, "country")}
                                    />

                                </div>
                            </div>

                            <div className="col-lg-3 text-left ">
                                <div className="forn-group-input">

                                    <Select options={[{ value: "", label: "All" }, { value: "true", label: "Featured" }, { value: "false", label: "Not Featured" }]}
                                        placeholder="Select..." value={values.featured}
                                        className={'form-control pl-0 arrow-3 w-100 font-size-4 d-flex align-items-center w-100 mb-4 '}
                                        border={false} onChange={(e) => handleSelectChange(e, "featured")}
                                    />

                                </div>


                            </div>
                            <div className="col-lg-3 text-left ">
                                <div className="forn-group-input">

                                    <Select options={[{ value: "", label: "All" }, { value: "true", label: "Flagged" }, { value: "false", label: "Not Flagged" }]}
                                        placeholder="Select..." value={values.flagged}
                                        className={'form-control pl-0 arrow-3 w-100 font-size-4 d-flex align-items-center w-100 mb-4 '}
                                        border={false} onChange={(e) => handleSelectChange(e, "flagged")}
                                    />

                                </div>
                            </div>
                         
                            <div className="col-lg-3 text-left">
                                <label className="form-label  "><i className="fa fa-filter m-2 text-green"></i> Profile Status</label>
                            </div>
                            <div className="col-lg-3 text-left">
                                <label className="form-label "><i className="fa fa-filter m-2 text-green"></i>Assessment Status</label>
                            </div>
                            <div className="col-lg-3 text-left"><i className="fa fa-filter m-2 text-green"></i>Reg Date - Start</div>
                            <div className="col-lg-3 text-left"><i className="fa fa-filter m-2 text-green"></i>Reg Date - End</div>
                           
                            <div className="col-lg-3 text-left ">
                                <div className="forn-group-input">

                                    <Select options={status}
                                        placeholder="Select..." value={values.profile}
                                        className={'form-control pl-0 arrow-3 w-100 font-size-4 d-flex align-items-center w-100 mb-4 '}
                                        border={false} onChange={(e) => handleSelectChange(e, "profile")}
                                    />

                                </div>


                            </div>
                            <div className="col-lg-3 text-left  ">
                                <div className="forn-group-input">

                                    <Select options={status}
                                        placeholder="Select..." value={values.assessment}
                                        className={'form-control pl-0 arrow-3 w-100 font-size-4 d-flex align-items-center w-100 mb-4 '}
                                        border={false} onChange={(e) => handleSelectChange(e, "assessment")}
                                    />

                                </div>
                            </div>

                            <div className="col-lg-3 text-left">
                                <div className="forn-group-input">
                                 
                                    <CustomDatePicker className="form-control"
                                        selected={values.fromDate}
                                        onChange={(date) => (handleDateChange('fromDate', date))}
                                        selectsStart
                                        startDate={values.fromDate}
                                        endDate={values.toDate}
                                        maxDate={new Date()}
                                    />
                                 
                                </div>
                              

                            </div>
                            <div className="col-lg-3 text-left  ">
                                <div className="forn-group-input">

                                    <CustomDatePicker className="form-control"
                                        selected={values.toDate}
                                        onChange={(date) => (handleDateChange('toDate', date))}
                                        selectsEnd
                                        startDate={values.fromDate}
                                        endDate={values.toDate}
                                        minDate={values.fromDate}
                                        maxDate={new Date()}
                                        
                                    />
                                </div>


                            </div>
                            <div className="col-lg-12   text-right m-b-0 mt-5 mb-lg-10 align-right ">
                                <button type="button"
                                    className="btn btn-green   m-b-0  mt-1  mx-5  font-size-3 font-weight-bold rounded-5 border-0 text-uppercase" onClick={() => {
                                        setValues({ profile: { value: "", label: "All" }, assessment: { value: "", label: "All" }, featured: { value: "", label: "All" }, flagged: { value: "", label: "All" }, country: null, name: "", fromDate: null, toDate: null });
                                    }}>
                                    Clear
                                </button>
                                <button type="button"
                                    className="btn btn-green    m-b-0  mt-1  font-size-3 font-weight-bold rounded-5 border-0 text-uppercase" onClick={() => {
                                        submitFilter();
                                    }}>
                                    Submit
                                </button>
                            </div>
                            
                            
                            <div className="col-xxxl-12  ">
                                <div className="contact-form bg-white shadow-8 ">

                                    <div className="d-flex font-size-6 font-weight-semibold mb-11   bg-gray-opacity-3 pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5 w-100">
                                        <div className="w-50">  <span>All Experts</span> </div> <div className="w-50 text-right"> <span className="rounded bg-green pointer font-size-6 w-10 text-right  " onClick={() => { LoadExpert(); }}> <i className="fa fa-refresh m-5 text-white"></i></span>    </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <label
                                            htmlFor="namedash"
                                            className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                        >
                                        </label>
                                        <div className="bg-white  pt-5   pb-9 px-2">
                                            <div className="table-responsive ">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr className="bg-gray-opacity-2 border border-color-2 py-5">
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Name
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                E-mail
                                                            </th><th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Reg Date
                                                            </th>   <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Location
                                                            </th> <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Photo ID
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Certificates
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5 text-center"
                                                            >

                                                            </th>


 
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {slice && slice.map((data, index) => {
                                                            console.log("",new Date(data.datecreated));
                                                            return (
                                                                 <tr className="border border-color-2" key={index}>

                                                                    <td className="table-y-top py-5 min-width-px-135">
                                                                        <a className="media min-width-px-235 align-items-center pointer" target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_DOMAIN + 'adminexpert/' + data.sub}  >
                                                                            <div className="circle-36 mr-6">
                                                                                <img src={data.avatar? s3URL + data.avatar :"assets/image/avatar.jpg"} alt="" className=" circle-36 w-100" />
                                                                            </div>
                                                                            {data.firstname ? <> <h4 data-toggle="tooltip" style={{ wordWrap: "break-word" }} title={data.firstname + ' ' + data.lastname} className="ellipsis min-width-px-185 font-size-4 mb-0 font-weight-semibold text-black-2 pointer"   >
                                                                                {data.email ? data.email.split('@')[0] : "N/A"}
                                                                            </h4><i className="fa fa-arrow-up-right-from-square font-size-3 text-green pl-5"></i></> : <> <h4 data-toggle="tooltip" style={{ wordWrap: "break-word" }} title={data.email ? data.email.split('@')[0] : "N/A"} className="ellipsis min-width-px-185 font-size-4 mb-0 font-weight-semibold text-black-2 pointer"   >
                                                                                {data.email ? data.email.split('@')[0] : "N/A"}
                                                                            </h4><i className="fa fa-arrow-up-right-from-square font-size-3 text-green pl-5"></i></>}

                                                                        </a>
                                                                    </td> 
                                                                    <td className="table-y-top py-5 min-width-px-135">
                                                                        {data.email}
                                                                    </td> 
                                                                 <td  className="table-y-top py-5  " >

                                                                      <div style={{
                                                                                    width: '100px',
                                                                                    
                                                                        }}>  {getMonthDateYear(data.datecreated)}  </div>
                                                                    </td>    <td className="table-y-top py-5 min-width-px-235 ">
                                                                        {data.city ? <h3 data-toggle="tooltip" title={data.city + ',' + data.state + ',' + data.countrycode} className=" ellipsis min-width-px-184 font-size-4 font-weight-normal text-black-2 pointer mb-0">
                                                                            <ReactCountryFlag
                                                                                countryCode={data.countrycode}
                                                                                svg
                                                                                style={{
                                                                                    width: '2em',
                                                                                    height: '1em', wordWrap: "break-word"
                                                                                }}
                                                                                title={data.countrycode}
                                                                            />         {data.city + ' '}, {data.state} ,{' ' + data.countrycode}
                                                                        </h3> : <span>N/A</span>}
                                                                    </td>
                                                                    <td className="d-none table-y-top py-5 min-width-px-135 text-center">
                                                                        <div className="row"><div className="col-12 text-center media">   {(data.photo && data.photo != "null") && <>  {data.idverified == true &&
                                                                            <a href={s3URL + data.photo} rel="noopener noreferrer" className="text-gray font-weight-semibold" target="_blank" data-toggle="tooltip" title="Click here to view.">Photo ID</a>
                                                                        }
                                                                            {!data.idverified &&
                                                                                <a href={s3URL + data.photo} rel="noopener noreferrer" className="text-green-2 font-weight-semibold" target="_blank" data-toggle="tooltip" title="Click here to view.">Photo ID</a>
                                                                            }
                                                                        </>}
                                                                        </div></div>



                                                                    </td>

                                                                    <td className="table-y-top py-5 min-width-px-185 text-center">
                                                                        <div className="row">
                                                                            <div className="col-12 text-center media"> {(data.photo && data.photo != "null") && <>   {data.idverified == true &&
                                                                                <a href={s3URL + data.photo} rel="noopener noreferrer" className="text-gray font-weight-semibold" target="_blank" data-toggle="tooltip" title="Click here to view.">Photo ID</a>
                                                                            }
                                                                                {!data.idverified &&
                                                                                    <a href={s3URL + data.photo} rel="noopener noreferrer" className="text-green-2 font-weight-semibold" target="_blank" data-toggle="tooltip" title="Click here to view.">Photo ID</a>
                                                                                }

                                                                                <button disabled={data.idverified == true}
                                                                                    className={`border-0 mr-1  font-size-4 ml-5 font-weight-bold justify-content-md-end  px-2 rounded-5  text-green-2  border-width-1 bg-gray-opacity-2   shadow-8 text-uppercase pointer  ${(data.idverified == true) ? 'text-gray bg-gray-opacity-4' : 'text-green-2 bg-gray-opacity-2'}`}
                                                                                    data-toggle="tooltip" title={data.idverified == true ? "Approved" : "Click to Approve"}
                                                                                    onClick={() => statusUpdate("approve", "id", data.id)} >
                                                                                    <i className="fa fa-check "></i>
                                                                                </button>

                                                                                <button disabled={data.idverified == false}
                                                                                    className={`border-0 mr-1  font-size-4  font-weight-bold justify-content-md-end  px-2 rounded-5  text-green-2  border-width-1 bg-gray-opacity-2   shadow-8 text-uppercase pointer  ${(data.idverified == false) ? 'text-gray bg-gray-opacity-4' : 'text-red bg-gray-opacity-2'}`}
                                                                                    data-toggle="tooltip" title={(data.idverified == false) ? "Rejected" : "Click to Reject"}
                                                                                    onClick={() => statusUpdate("reject", "id", data.id)} >
                                                                                    <i className="fa fa-times "></i>
                                                                                </button>
                                                                            </>}
                                                                            </div></div>



                                                                    </td>


                                                                   
                                                                   

                                                                      <td className="table-y-top py-5 min-width-px-235 text-center ">
                                                                         {(data.expertCertificate) && data.expertCertificate.map((cert, index) => {

                                                                            return (
                                                                                <div className="row">{(cert.certname) && <>
                                                                                    <div className="col-8  text-left">
                                                                                        {cert.approved == true && <a href={(cert.certfilename) ? s3URL + cert.certfilename : ((cert.certurl) ? sociallink(cert.certurl) : "")}
                                                                                            rel="noopener noreferrer" className="text-gray font-weight-semibold max-width-px-170 ellipsis"
                                                                                            style={{
                                                                                                width: '2em',
                                                                                                height: '2em', wordWrap: "break-word"
                                                                                            }}
                                                                                            target="_blank" data-toggle="tooltip" title={cert.certname}>{cert.certname}</a>}
                                                                                        {(!cert.approved || cert.approved == false) && <a href={(cert.certfilename) ? s3URL + cert.certfilename : ((cert.certurl) ? sociallink(cert.certurl) : "")}
                                                                                            rel="noopener noreferrer" className="text-green-2 font-weight-semibold max-width-px-170 ellipsis"
                                                                                            style={{
                                                                                                width: '2em',
                                                                                                height: '2em', wordWrap: "break-word"
                                                                                            }}
                                                                                            target="_blank" data-toggle="tooltip" title={cert.certname}>{cert.certname}</a>}</div>
                                                                                    {<div className="col-4 media  text-left">  <button disabled={cert.approved==true} className={`border-0 mr-1  font-size-4 ml-5 font-weight-bold justify-content-md-end  px-2 rounded-5  text-green-2  border-width-1 bg-gray-opacity-2   shadow-8 text-uppercase pointer  ${(cert.approved == true) ? 'text-gray bg-gray-opacity-4' : 'text-green-2 bg-gray-opacity-2'}`} data-toggle="tooltip" title={cert.approved ==true ? "Approved" : "Click to Approve"} onClick={() => certStatusUpdate("true","approve", "cert", data.id,cert.id)} >
                                                                                        <i className="fa fa-check "></i>
                                                                                    </button>    <button disabled={cert.approved==false} className={`border-0 mr-1  font-size-4  font-weight-bold justify-content-md-end  px-2 rounded-5  text-green-2  border-width-1 bg-gray-opacity-2   shadow-8 text-uppercase pointer  ${(cert.approved==false) ? 'text-gray bg-gray-opacity-4' : 'text-red bg-gray-opacity-2'}`} data-toggle="tooltip" title={cert.approved ==false ? "Rejected" : "Click to Reject"} onClick={() => certStatusUpdate("false","reject", "cert", data.id,cert.id)} >
                                                                                            <i className="fa fa-times "></i>
                                                                                        </button> </div> } </>}</div>)
                                                                        })}
                                                                        
                                                                    </td> 

                                                                   <td className="d-none py-5 text-center ">
                                                                    {(data.expertCertificate) && data.expertCertificate.map((data, index) => {

                                                                        return (

                                                                            <li key={index} className="table-y-top text-left ">

                                                                                {(!data.certname) ?'-': 
                                                                                <>
                                                                                <a href={(data.certfilename) ? s3URL + data.certfilename : data.certurl} rel="noopener noreferrer" 
                                                                                className="text-green-2 font-weight-semibold" target="_blank" data-toggle="tooltip" title="Click here to view.">{data.certname}</a>
                                                                                    </>}

                                                                            </li>
                                                                        )
                                                                    })}
                                                                   </td>

                                                                   

                                                                 
                                                                    <td className="table-y-top    pr-0   text-center media justify-content-md-end">
                                                                        {data.idverified && data.profilecomplete && <div data-toggle="tooltip " className="text-right" >
                                                                            {!data.featured && <button className="border font-size-3 font-weight-bold text-green-2 px-5 rounded-5  mr-1 border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" data-toggle="tooltip" title="Click to feature the user" onClick={() => statusUpdate("feat", "feat", data.id)}  >
                                                                                Feature
                                                                            </button>}
                                                                            {data.featured && <button className="border font-size-3 font-weight-bold text-red px-5 rounded-5  mr-1 border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" data-toggle="tooltip" title="Click to unfeature the user" onClick={() => statusUpdate("unfeat", "feat", data.id)} >
                                                                                UnFeature
                                                                            </button>}</div>}



                                                                        <div data-toggle="tooltip " className="text-right" > {!data.flagged && <button className="border font-size-3 font-weight-bold text-red px-5 rounded-5  mr-1 border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" data-toggle="tooltip" title="Click to flag." onClick={(e) => toggleModal(data.id)}  >
                                                                            Flag
                                                                        </button>}
                                                                            {data.flagged && <button className="border font-size-3 font-weight-bold text-green-2 px-5 rounded-5  mr-1 border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" data-toggle="tooltip" title="Click to unflag." onClick={() => statusUpdate("unflag", "unflag", data.id)}>
                                                                                UnFlag
                                                                            </button>}</div>
                                                                    </td>

                                                                </tr>)
                                                        })}


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <TablePagination range={range} slice={slice} setPage={setPage} page={page} />
                <ModalFlagReason submitflag={submitflag} modalvisible={showModal} togglemodal={toggleModal} />
            </div>


        </>
    );
};