import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { ListGroup, Collapse, Dropdown } from "react-bootstrap";
import { FaAngleRight, FaAngleDown } from "react-icons/fa"; 
import GlobalContext from "../../context/GlobalContext";
import ReactCountryFlag from "react-country-flag"
import { useWindowSize } from "../../hooks/useWindowSize";
import { GetExpertPreviewLoad, GetClientPreviewProfile } from "../../utils/apiCalls";
const imgP = "../assets/image/avatar.png";

const NestedMenuContainer = styled.div`
 
  a,
  .label {
    color: ${({ dark, theme }) =>
        dark ? theme.colors.light : theme.colors.heading}!important;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.3s ease-out;

    &:hover,
    &:active {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: none;
    }
  }

  .list-group-item {
    & + .collapse:not(.show) {
      .list-group-item {
        border: none !important;
        border-width: 0 !important;
      }
    }
    & + .collapse.show {
      .list-group-item {
        &:first-child {
          border-top: none !important;
        }
      }
    }
  }
  .collapse + .list-group-item {
    border-top-width: 0;
  }
  /* .list-group-flush:last-child .list-group-item:last-child {
    border-bottom-width: 1px;
  } */
`;

const defaultMenuItems = [
    { name: "home", label: "Home" },
    {
        name: "billing",
        label: "Billing",
        items: [
            { name: "statements", label: "Statements" },
            { name: "reports", label: "Reports" },
        ],
    },
    {
        name: "settings",
        label: "Settings",
        items: [
            { name: "profile", label: "Profile" },
            { name: "insurance", label: "Insurance" },
            {
                name: "notifications",
                label: "Notifications",
                items: [
                    { name: "email", label: "Email" },
                    {
                        name: "desktop",
                        label: "Desktop",
                        items: [
                            { name: "schedule", label: "Schedule" },
                            { name: "frequency", label: "Frequency" },
                        ],
                    },
                    { name: "sms", label: "SMS" },
                ],
            },
        ],
    },
];

const MenuItem = ({
    label,
    isExternal = false,
    name,
    items,
    depthStep = 20,
    depth = 0,
    ...rest
}) => {
    const [open, setOpen] = useState(false);
    const hasSubItems = Array.isArray(items); 
   
    const gContext = useContext(GlobalContext);
    return (
        <>
            {hasSubItems ? (
                <ListGroup.Item
                    {...rest}
                    css={`
            padding-left: ${depth * depthStep}px !important;
            cursor: pointer;
          `}
                    onClick={() => setOpen(!open)}
                    className="d-flex align-items-center justify-content-between"
                >
                    <span className="label">{label}</span>
                    <span>{open ? <FaAngleDown /> : <FaAngleRight />}</span>
                </ListGroup.Item>
            ) : (
                    <ListGroup.Item
                        {...rest}
                        css={`
            padding-left: ${depth * depthStep}px !important;
          `}
                    >
                        {isExternal ? (
                            <a
                                href={`${name}`}
                                onClick={() => {
                                    if (gContext.visibleOffCanvas) {
                                        gContext.toggleOffCanvas();
                                    }
                                }}
                            >
                                {label}
                            </a>
                        ) : (
<>
                                    <a
                                        href={`${name}`}
                                    onClick={() => {
                                        if (gContext.visibleOffCanvas) {
                                            gContext.toggleOffCanvas();
                                        }
                                    }}
                                >
                                    {label}
                                </a>  </>

                            )}
                    </ListGroup.Item>
                )}

            {hasSubItems ? (
                <Collapse in={open}>
                    <ListGroup>
                        {items.map((subItem) => (
                            <MenuItem
                                key={subItem.name}
                                depth={depth + 1}
                                depthStep={depthStep}
                                {...subItem}
                            />
                        ))}
                    </ListGroup>
                </Collapse>
            ) : null}

           
        </>
    );
};

const NestedMenu = ({ menuItems = defaultMenuItems }) => {
    const gContext = useContext(GlobalContext);
    const size = useWindowSize();
   

    const [userName, setUserName] = useState('');
    const [country, setcountryCode] = useState('');
    const [avatar, setAvatar] = useState('../assets/image/avatar.png');

    const s3URL = process.env.REACT_APP_AWS_S3_URL;

    async function ExpertPreviewInfo() {
        try {
            const result = await GetExpertPreviewLoad(null);
            if (result.data.expertAllLoad.responseData.code == "200") {
                //[{"id":66,"sub":"f180bc26-27fa-4a1b-9b75-fee8dd832db0","firstname":"Karthik","lastname":"K","email":"sathya@springinfoservices.com","phone":"","address1":"address","address2":"","city":"LA","state":"CA","countryid":61,"countryname":"India","avatar":"","photo":"","title":"SE","profile":"Apple Inc. is an American multinational technology company specializing in consumer electronics, software and online services headquartered in Cupertino, California, United States. Apple is the largest technology company by revenue (totaling US$365.8 billion in 2021) and, as of June 2022, is the world's biggest company by market capitalization, the fourth-largest personal computer vendor by unit sales and second-largest mobile phone manufacturer. It is one of the Big Five American information technology companies, alongside Alphabet, Amazon, Meta, and Microsoft.","dailyrate":"250.00","otherlangs":"","idverified":false,"timezoneid":586,"timezonecode":"IST","timezonename":"India Standard Time","timezonevalue":"UTC+05:30","online":null,"explevelid":1,"explevelname":"Entry level (0-3 years)","profilecomplete":false,"approved":false,"active":false,"flagged":false,"flaggedreasonid":null,"flaggedreason":null,"avgrating":null,"weeklyhoursid":2,"weeklyhours":"10 hrs/week","resume":"null","skillids":"13,12,9","complianceids":"42,50,44","skills":[{"skill":"Cybersecurity"},{"skill":"Compliance Audit"},{"skill":"Certification audit"}],"compliances":[{"code":"AS9100"},{"code":"CMMI"},{"code":"CE"}]}]

                let profile = JSON.parse(result.data.expertAllLoad.responseData.message);

                if (profile && profile.length > 0) {
                    setcountryCode(profile[0].countrycode);
                }

            }

        }
        catch (e) {

        }

    }
    async function ClientPreviewInfo() {
        try {
            const result = await GetClientPreviewProfile(null);
            console.log(result);
            if (result.data.clientAllLoad.responseData.code == "200") {

                let profile = JSON.parse(result.data.clientAllLoad.responseData.message);

                if (profile && profile.length > 0) {
                    setcountryCode((profile[0].code) ? profile[0].code : "US");
                    setAvatar((profile[0].logo != "") ? s3URL + profile[0].logo : "../assets/image/avatar.png");
                }
            }

        }
        catch (e) {
        }

    }
    useEffect(() => {
        if (gContext.userInfo.userType == "1") {
            ExpertPreviewInfo();
        }
        else {
            ClientPreviewInfo();
        }
    }, []);
    useEffect(() => {

        if (gContext.userInfo.userType == "1") {
            ExpertPreviewInfo();
            const data = gContext.userInfo.profileInfo;
            setUserName((data && data.length > 0 && data[0] != null) ? ((data[0].firstname)?data[0].firstname:"") + " " +( (data[0].lastname)? data[0].lastname.substring(0, 1):"") : (gContext.userInfo.email) ? gContext.userInfo.email.split('@')[0] : '');
            setAvatar((data && data.length > 0 && data[0] != null && data[0].avatar != "") ? s3URL + data[0].avatar : "../assets/image/avatar.png");
        }
        else {
            ClientPreviewInfo();
            const data = JSON.parse(gContext.userInfo.profileInfo);
            setUserName((data && data.length > 0 && data[0] != null) ? ((data[0].firstname) ? data[0].firstname : "") + " " + ((data[0].lastname) ? data[0].lastname.substring(0, 1) : "") : (gContext.userInfo.email) ? gContext.userInfo.email.split('@')[0] : '');
            //setAvatar((data && data.length > 0 && data[0] != null && data[0].logo != "") ? s3URL + data[0].logo : "../assets/image/avatar.png");
        }
    }, [gContext.userInfo]);
    return (
        <NestedMenuContainer>
            <ListGroup variant="flush">
                {gContext.header.button === "profile" && (<>
                   <div className="align-items-center text-center mx-auto "> 
                            

                        <img src={avatar} width="80px" alt="" className="circle-72" style={{ cursor: 'pointer' }} onClick={() => { window.location.href="/profile" }} />  
                            
                    </div> 
                    <div className="align-items-center text-center mx-auto pt-5 pb-10 "> 
                      <a href="/profile" className="text-green-2    font-weight-bold">{userName}</a>  <ReactCountryFlag
                            countryCode={country}
                            svg
                            style={{
                                width: '2em',
                                height: '1em',
                            }}
                            title={country}
                        />    

                    </div> 
                   </>
                    
                )}
                
                {menuItems.map((menuItem, index) => (
                    <MenuItem
                        key={`${menuItem.name}${index}`}
                        depthStep={20}
                        depth={0}
                        {...menuItem}
                    />

                ))}
                {gContext.userInfo.email !== "" && <a
                    className="nav-link  "
                    role="button"
                    aria-expanded="false"
                    target="_blank" rel="noopener noreferrer" href={(gContext.userInfo.userType == "1") ? '/Searchjob' : "/SearchExpert"}
                >
                    <i className="fas fa-magnifying-glass  ml-1 mr-3"></i>  {(gContext.userInfo.userType == "1") && 'Search Job'} {(gContext.userInfo.userType == "2") && 'Search Talent'}
                </a>}
              {/* <!--
            
                {gContext.header.button === "profile" && (<>

                    <ListGroup.Item >     <div className="pr-2">    <a
                        className={`btn btn-outline-primary text-uppercase font-size-3 `}
                        href="/#"
                        onClick={(e) => {
                            e.preventDefault();
                            
                        }}
                    >
                        Post a job
                </a></div> </ListGroup.Item ><ListGroup.Item >
                        <a
                            className={`btn btn-${gContext.header.variant} text-uppercase font-size-3 `}
                            href="/#"
                            onClick={(e) => {
                                e.preventDefault();
                                
                            }}
                        >
                           Logout
                </a>
                    </ListGroup.Item >  
                </>)}  --> */}
                {gContext.header.button === "account" && (<>
                      
                    <ListGroup.Item >
                        <div className="header-btns  ml-auto pr-2 ml-lg-6  d-xs-flex ">
                            <div className="pr-2">    <button
                        className={`btn btn-${gContext.header.variant} text-uppercase font-size-3 text-white `}
                            href="/#"
                            onClick={(e) => {
                                e.preventDefault();
                                gContext.toggleSignInModal();
                                if (gContext.visibleOffCanvas) {
                                    gContext.toggleOffCanvas();
                                }
                            }}
                        >
                                Log In
                </button></div> </div></ListGroup.Item > <ListGroup.Item >
                        <button
                            className={`btn btn-${gContext.header.variant} text-uppercase font-size-3 `}
                            href="/#"
                            onClick={(e) => {
                                e.preventDefault();
                                gContext.toggleSignUpModal();
                                if (gContext.visibleOffCanvas) {
                                    gContext.toggleOffCanvas();
                                }
                            }}
                        >
                            Sign Up
                </button>
                        </ListGroup.Item >
               </> )}

              
            </ListGroup>
           
                 
            
        </NestedMenuContainer>
    );
};

export default NestedMenu;
