import React from "react";
import { Link } from "react-router-dom";

import { HashLink } from "react-router-hash-link";
const imgM = "../assets/image/logo.png";
const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    
      <a href="/#" className={`d-block ${className}`} {...rest}>
          {white ? (
              <><img src={imgM} width="200px"  />   </>
        ) : (
                  <><img src={imgM} width="200px"  />    </>
        )}
      </a>
    
  );
};

export default Logo;
