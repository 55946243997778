import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
const imgLoader = "../../assets/image/svg/spinning-circles.svg";


const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050; 
  }
`;

const ModalLoader = (props) => {

    const gContext = useContext(GlobalContext); 
   
    return (
      
       <> {
            gContext.loaderModalVisible && <> <div className="loaderbase">
            </div><div style={{ zIndex: '99999999999' }} className="square-72 rounded bg-green-2 pos-abs-center-load border-1 shadow-8 ">

                    <img src={imgLoader} width="40" />
                </div></>
        }</>
    );
};

export default ModalLoader;
