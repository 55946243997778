import React from 'react';
import ReactDOM from 'react-dom/client';
import Amplify from 'aws-amplify'
import aws_exports from './aws-exports'
 

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, HashRouter, MemoryRouter } from 'react-router-dom';
const root = ReactDOM.createRoot(document.getElementById('root'));
Amplify.configure(aws_exports)
root.render(
    //<GoogleReCaptchaProvider reCaptchaKey="6LcR9BohAAAAAAfE4RxI6om-aiqsSQppVYi-5W5z">
    <App />
    //</GoogleReCaptchaProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
