import React, { useEffect,useState,useContext} from "react";
import { Nav, Tab } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { GetClientPreviewProfile} from "../../utils/apiCalls";
 
import  StarRating from "../../components/Core/Rating";
import ReactCountryFlag from "react-country-flag";
import DOMPurify from 'isomorphic-dompurify';


const imgP = "../favicond1.png";
const s3URL = process.env.REACT_APP_AWS_S3_URL;

export default function ProfileView(props) {
    const gContext = useContext(GlobalContext);
    const [initialValues, setInitialValues] = React.useState({ industryTpes: null, code:'',avgrating :0,logo:'',industryname:'',countryname:'',desc: '',tagline:'',company:'',city:'',state:''});
    const [values, setValues] = React.useState({ industryTpes:null,code:'',avgrating :0,logo:'',industryname:'',countryname:'',desc: '',tagline:'',company:'',city:'',state:''});
    const subParam = props.sub;
  
    
    useEffect(() => {
        ClientPreviewInfo() ;
       // rating() ;
    }, [])

    useEffect(() => {
    
        document.title = "Profile View | Syncuppro";
    }, []);

    async function ClientPreviewInfo() 
    {
        try { 

            //[{"sub":"f180bc26-27fa-4a1b-9b75-fee8dd832db0","email":"sathya@springinfoservices.com","title":"manager","explevelid":3,"profile":"test","dailyrate":"230.00","weeklyhrsid":3,"skillids":"13,12","compids":"42,50","resume":""}]
            const result = await GetClientPreviewProfile(subParam); 
            if (result.data.clientAllLoad.responseData.code == "200") {

                let profile = JSON.parse(result.data.clientAllLoad.responseData.message);
              
                if (profile && profile.length > 0 ) { 
                    // title: '', expLevelId: null, expLevel: null, dailyRate: null, weeklyHrsId: null, weeklyHrs: null, profileDetails: "", skillSet: "", compliance: "", resume: ''
                     let profileData = { ...values }; 

                     profileData.desc = profile[0].desc;
                     profileData.tagline = profile[0].tagline;
                     profileData.company = profile[0].company;
                     profileData.logo = profile[0].logo;
                     profileData.countryname = profile[0].countryname;
                     profileData.industryname = profile[0].industryname;
                     profileData.city = profile[0].city;
                     profileData.state = profile[0].state;
                    profileData.code = profile[0].code;
                    profileData.industryTpes = profile[0].industryTpes;
                  

                     if(profile[0].avgrating == null)
    {
        profileData.avgrating = 0; 
     }
     else  if(profile[0].avgrating !== null)
     {
        
        profileData.avgrating = profile[0].avgrating; 

     }   
                    setInitialValues(profileData);
                    setValues(profileData);
                } else{

                    if(subParam)
                    window.location.href = "/error" ;
                   
                }
            }
            
        }   catch(e)
            {
                 
            }
        
    }

    return (

       
    <> <div 
    className={` mt-24 mt-lg-31   ${(props.sub) ? '' : 'dashboard-main-container'}`}  
    id="dashboard-body"
> 
            <div className="container">
                <div className="mb-15 mb-lg-23">
          <div className="row ">
              {/* <!-- Company Profile --> */}
              <div className="col-12 col-xl-11 col-lg-11">
                  <div className="bg-white rounded-4 pt-11 shadow-9">
                      <div className="d-xs-flex align-items-center pl-xs-12 mb-8 text-center text-xs-left">
                         
                              <a className="mr-xs-7 mb-5 mb-xs-0">
                             
                                
                                  <img
                                      className="square-72 rounded-6"
                                  src={s3URL + values.logo}
                                      alt=""
                                  />
                              </a>
                         
                          <div className="">
                              <h2 className="mt-xs-n5">
                                 
                                      <a className="font-size-6 text-black-2 font-weight-semibold">
                                         {values.company}
                          </a>
                                 
                              </h2>
                              <span className="mb-0 text-gray font-size-4">
                              {values.tagline}
                      </span>
                          </div>
                      </div>
                      {/* <!-- Tab Section Start --> */}
                          <Tab.Container id="left-tabs-example" defaultActiveKey="company">
                          <Nav
                              className="nav border-bottom border-mercury pl-12"
                              role="tablist"
                          >
                              <li className="tab-menu-items nav-item pr-12">
                                  <Nav.Link
                                      eventKey="company"
                                      className="text-uppercase font-size-3 font-weight-bold text-default-color py-3 px-0 pointer"
                                  >
                                      Company
                        </Nav.Link>
                              </li>
                              <li className="tab-menu-items nav-item pr-12 d-none">
                                  <Nav.Link
                                      eventKey="jobs1"
                                          className="text-uppercase font-size-3 font-weight-bold text-default-color py-3 px-0 pointer"
                                  >
                                                History
                        </Nav.Link></li>
                                            <li className="tab-menu-items nav-item pr-12 ">
                                                <Nav.Link
                                                    eventKey="jobs"
                                                    className="text-uppercase font-size-3 font-weight-bold text-default-color py-3 px-0 pointer"
                                                >
                                                    Jobs
                        </Nav.Link>
                              </li>
                          </Nav>
                          {/* <!-- Tab Content --> */}
                          <Tab.Content className="pl-12 pt-10 pb-7 pr-12 pr-xxl-24">
                              <Tab.Pane eventKey="company">
                                  {/* <!-- Middle Body Start --> */}
                                  <div className="row">
                                      {/* <!-- Single Widgets Start --> */}
                                      <div className="col-12 col-lg-4 col-md-4 col-xs-6">
                                          <div className="mb-8">
                                              <p className="font-size-4">IndustryType</p>
                                              <h5 className="font-size-4 font-weight-semibold text-black-2">
                                                  {values.industryname}
                              </h5>
                                                    </div>  
                                         
                                                  
                                                   
                                      </div>
                                      {/* <!-- Single Widgets End --> */}
                                      {/* <!-- Single Widgets Start --> */}
                                     
                                      {/* <!-- Single Widgets End --> */}
                                                {/* <!-- Single Widgets Start --> */}
                                                <div className=     {(values.avgrating) == ''?'d-none':'col-12 col-lg-4 col-md-4 col-xs-6'} >
                                             
                                                    <div className="mb-8">
                                                        <p className="font-size-4">Rating</p>
                                                        <h5 className="font-size-4 font-weight-semibold text-black-2">
                                                        <div className="mb-8">  <StarRating  rating= {values.avgrating}  />
                                                        &nbsp;&nbsp;<span  className="font-size-2  text-green-2 font-weight-semibold">
                                                        {values.avgrating}/5
                                                            </span></div>
                                                        
                                                        </h5>
                                                    </div></div>
                                                <div className="col-12 col-lg-4 col-md-4 col-xs-6">
                                                    
                                          <div className="mb-8">
                                              <p className="font-size-4">Location <ReactCountryFlag
                                                                        countryCode={values.code}
                                                                        svg
                                                                        style={{
                                                                            width: '2em',
                                                                            height: '1em',
                                                                        }}
                                                                        title={values.code}
                                                                    /></p> 
                                              <h5 className="font-size-4 font-weight-semibold text-black-2">
                                                {values.city} , {values.state} , {values.countryname}
                              </h5>
                                          </div>
                                      </div>
                                      {/* <!-- Single Widgets End --> */}
                                  </div>
                                  {/* <!-- Middle Body End --> */}
                                      {/* <!-- Excerpt Start --> */}


                                  <h4 className="font-size-6 mb-7 text-black-2 font-weight-semibold">
                                          About Company 
                        </h4>
                                      <h5 className="font-size-5 mb-5 text-black-2 font-weight-semibold">
                                               
                        </h5>
                                  <div className="pt-5 ">
                                                <div className="font-size-4 text-black-2 mb-7" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(values.desc) }}>

                                                </div>

                                              
                                      
                                            </div> <div className="row pt-5 ">
                                            <div className="col-md-12 pl-lg-5">
                                                <div className="tags">
                                                    <p className="font-size-4 text-gray mb-3 font-weight-semibold">
                                                        Industry Types
                                                    </p>
                                                    <ul className="d-flex list-unstyled flex-wrap pr-sm-25 pr-md-0">

                                                            {(values && values.industryTpes) && values.industryTpes.map((data, index) => {
                                                            const { name } = data;
                                                            return (<li key={index}>

                                                                <li className="bg-red-opacity-2 mr-3 h-px-33 text-center flex-all-center rounded-15 px-5 font-size-3 text-black-2 mt-2">
                                                                    {name}
                                                                </li>

                                                            </li>)
                                                        })}
                                                    </ul>
                                                </div>
                                            </div></div>
                                  {/* <!-- Excerpt End --> */}
                              </Tab.Pane>
                              <Tab.Pane eventKey="jobs1">
                                  {/* <!-- Middle Body Start --> */}
                                  <div className="d-none row">
                                      {/* <!-- Single Widgets Start --> */}
                                      <div className="col-12 col-lg-4 col-md-4 col-xs-6">
                                          <div className="mb-8">
                                              <p className="font-size-4">No. of Jobs ( in progress)</p>
                                              <h5 className="font-size-4 font-weight-semibold">
                                                  5 jobs
                              </h5>
                                          </div>
                                          <div className="mb-8">
                                                  <p className="font-size-4"> No. of completed Jobs</p>
                                              <h5 className="font-size-4 font-weight-semibold">
                                                      7 jobs
                              </h5>
                                          </div>
                                      </div>
                                      {/* <!-- Single Widgets End --> */}
                                      {/* <!-- Single Widgets Start --> */}
                                      <div className="col-12 col-lg-4 col-md-4 col-xs-6" visibility="hidden">
                                          <div className="mb-8">
                                              <p className="font-size-4">No. of hired (Expert)</p>
                                              <h5 className="font-size-4 font-weight-semibold">
                                                  25 Experts
                              </h5>
                                          </div>
                                              <div className="mb-8">
                                                  <p className="font-size-4">Hiring Percentage</p>
                                                  <h5 className="font-size-4 font-weight-semibold">
                                                      25%
                              </h5>
                                              </div>
                                          </div>
                                          <div className="col-12 col-lg-4 col-md-4 col-xs-6">
                                              <div className="mb-8">
                                                  <p className="font-size-4">No. of applicattion</p>
                                                  <h5 className="font-size-4 font-weight-semibold">
                                                      100 
                              </h5>
                                              </div>
                                            
                                          </div>
                                      {/* <!-- Single Widgets End --> */}
                                      {/* <!-- Single Widgets Start --> */}
                                      
                                      
                                      {/* <!-- Single Widgets End --> */}
                                  </div>
                                  {/* <!-- Middle Body End --> */}
                                  {/* <!-- Excerpt Start --> */}
                                   
                                  {/* <!-- Excerpt End --> */}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="jobs">
                                            {/* <!-- Middle Body Start --> */}
                                           <div className="d-none">

                                           <div className="row">
                                                {/* <!-- Single Widgets Start --> */}
                                                <div className="col-12 col-lg-4 col-md-4 col-xs-6">
                                                    <div className="mb-8">
                                                        <p className="font-size-4">No. of Jobs ( in progress)</p>
                                                        <h5 className="font-size-4 font-weight-semibold">
                                                            5 jobs
                              </h5>
                                                    </div>
                                                    <div className="mb-8">
                                                        <p className="font-size-4"> No. of completed Jobs</p>
                                                        <h5 className="font-size-4 font-weight-semibold">
                                                            7 jobs
                              </h5>
                                                    </div>
                                                </div>
                                                {/* <!-- Single Widgets End --> */}
                                                {/* <!-- Single Widgets Start --> */}
                                                <div className="col-12 col-lg-4 col-md-4 col-xs-6">
                                                    <div className="mb-8">
                                                        <p className="font-size-4">No. of hired (Expert)</p>
                                                        <h5 className="font-size-4 font-weight-semibold">
                                                            25 Experts
                              </h5>
                                                    </div>
                                                    <div className="mb-8">
                                                        <p className="font-size-4">Hiring Percentage</p>
                                                        <h5 className="font-size-4 font-weight-semibold">
                                                            25%
                              </h5>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-4 col-md-4 col-xs-6">
                                                    <div className="mb-8">
                                                        <p className="font-size-4">No. of Applicants</p>
                                                        <h5 className="font-size-4 font-weight-semibold">
                                                            100
                              </h5>
                                                    </div>

                                                </div>
                                                {/* <!-- Single Widgets End --> */}
                                                {/* <!-- Single Widgets Start --> */}


                                                {/* <!-- Single Widgets End --> */}
                                            </div>
                                            <div className="row mb-11 align-items-center">
                                                <div className="col-lg-6 mb-lg-0 mb-2">
                                                    <h3 className="font-size-6 mb-0">Open Jobs (4)</h3>
                                                </div>
                                                <div className="col-lg-6">
                                                   
                                                </div>
                                            </div>
                                            <div className="bg-white  pt-5   pb-9 px-5">
                                                <div className="table-responsive ">
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr className="bg-green-opacity-2  border border-color-2 ">
                                                                <th
                                                                    scope="col"
                                                                    className="pl-5 border-0 font-size-4 font-weight-bold "
                                                                >
                                                                    Job Title
                        </th>
                                                                <th
                                                                    scope="col"
                                                                    className="pl-4 border-0 font-size-4 font-weight-bold"
                                                                >
                                                                    Job Type
                        </th>
                                                                <th
                                                                    scope="col"
                                                                    className="pl-4 border-0 font-size-4 font-weight-bold"
                                                                >
                                                                    City
                        </th>
                                                                <th
                                                                    scope="col"
                                                                    className="pl-4 border-0 font-size-4 font-weight-bold"
                                                                >
                                                                    Created on
                        </th>
                                                                <th
                                                                    scope="col"
                                                                    className="pl-4 border-0 font-size-4 font-weight-bold"
                                                                >
                                                                    Total Applicants
                        </th>
                                                                 
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="border border-color-2">
                                                                <th
                                                                    scope="row"
                                                                    className="pl-6 border-0 py-7 min-width-px-235"
                                                                >
                                                                    <div className="">

                                                                        <a className="font-size-4 mb-0 font-weight-semibold text-black-2" href="/job-details">
                                                                            Senior Jobs Manager
                              </a>

                                                                    </div>
                                                                </th>
                                                                <td className="table-y-middle py-7 min-width-px-135">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        Full-Time
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-125">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        New York
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-155">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        12 July, 2022
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-205">
                                                                    <h3 className="font-size-4 font-weight-bold text-black-2 mb-0">
                                                                        47
                          </h3>
                                                                </td>
                                                                
                                                            </tr>
                                                            <tr className="border border-color-2">
                                                                <th
                                                                    scope="row"
                                                                    className="pl-6 border-0 py-7 min-width-px-235"
                                                                >
                                                                    <div className="">

                                                                        <a href="/job-details" className="font-size-4 mb-0 font-weight-semibold text-black-2">
                                                                            UI Designer
                              </a>

                                                                    </div>
                                                                </th>
                                                                <td className="table-y-middle py-7 min-width-px-135">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        Full-Time
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-125">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        Remote
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-155">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        24 June, 2022
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-205">
                                                                    <h3 className="font-size-4 font-weight-bold text-black-2 mb-0">
                                                                        145
                          </h3>
                                                                </td>
                                                                
                                                            </tr>
                                                            <tr className="border border-color-2">
                                                                <th
                                                                    scope="row"
                                                                    className="pl-6 border-0 py-7 min-width-px-235"
                                                                >
                                                                    <div className="">

                                                                        <a href="/job-details" className="font-size-4 mb-0 font-weight-semibold text-black-2">
                                                                            Head of Marketing
                              </a>

                                                                    </div>
                                                                </th>
                                                                <td className="table-y-middle py-7 min-width-px-135">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        Full-Time
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-125">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        London
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-155">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        15 June, 2022
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-205">
                                                                    <h3 className="font-size-4 font-weight-bold text-black-2 mb-0">
                                                                        62
                          </h3>
                                                                </td>
                                                                
                                                            </tr>
                                                            <tr className="border border-color-2">
                                                                <th
                                                                    scope="row"
                                                                    className="pl-6 border-0 py-7 min-width-px-235"
                                                                >
                                                                    <div className="">

                                                                        <a href="/job-details" className="font-size-4 mb-0 font-weight-semibold text-black-2">
                                                                            Full-Stack Developer
                              </a>

                                                                    </div>
                                                                </th>
                                                                <td className="table-y-middle py-7 min-width-px-135">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        Part-Time
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-125">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        California
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-155">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        29 May, 2022
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-205">
                                                                    <h3 className="font-size-4 font-weight-bold text-black-2 mb-0">
                                                                        184
                          </h3>
                                                                </td>
                                                              
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className="row mb-11 align-items-center">
                                                <div className="col-lg-6 mb-lg-0 mb-2">
                                                    <h3 className="font-size-6 mb-0">Active Jobs (4)</h3>
                                                </div>
                                                <div className="col-lg-6">

                                                </div>
                                            </div>
                                            <div className="bg-white  pt-5   pb-9 px-5">
                                                <div className="table-responsive ">
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr className="bg-green-opacity-2 border border-color-2  ">
                                                                <th
                                                                    scope="col"
                                                                    className="pl-4 border-0 font-size-4 font-weight-bold "
                                                                >
                                                                    Job Title
                        </th>
                                                                <th
                                                                    scope="col"
                                                                    className="pl-4 border-0 font-size-4 font-weight-bold"
                                                                >
                                                                    Job Type
                        </th>
                                                                <th
                                                                    scope="col"
                                                                    className="pl-4 border-0 font-size-4 font-weight-bold"
                                                                >
                                                                    City
                        </th>
                                                                <th
                                                                    scope="col"
                                                                    className="pl-4 border-0 font-size-4 font-weight-bold"
                                                                >
                                                                    Created on
                        </th>
                                                                <th
                                                                    scope="col"
                                                                    className="pl-4 border-0 font-size-4 font-weight-bold"
                                                                >
                                                                    Total Applicants
                        </th> 

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="border border-color-2">
                                                                <th
                                                                    scope="row"
                                                                    className="pl-6 border-0 py-7 min-width-px-235"
                                                                >
                                                                    <div className="">

                                                                        <a className="font-size-4 mb-0 font-weight-semibold text-black-2" href="/job-details">
                                                                            Senior Jobs Manager
                              </a>

                                                                    </div>
                                                                </th>
                                                                <td className="table-y-middle py-7 min-width-px-135">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        Full-Time
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-125">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        New York
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-155">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        12 July, 2021
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-205">
                                                                    <h3 className="font-size-4 font-weight-bold text-black-2 mb-0">
                                                                        47
                          </h3>
                                                                </td>

                                                            </tr>
                                                            <tr className="border border-color-2">
                                                                <th
                                                                    scope="row"
                                                                    className="pl-6 border-0 py-7 min-width-px-235"
                                                                >
                                                                    <div className="">

                                                                        <a href="/job-details" className="font-size-4 mb-0 font-weight-semibold text-black-2">
                                                                            UI Designer
                              </a>

                                                                    </div>
                                                                </th>
                                                                <td className="table-y-middle py-7 min-width-px-135">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        Full-Time
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-125">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        Remote
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-155">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        24 June, 2021
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-205">
                                                                    <h3 className="font-size-4 font-weight-bold text-black-2 mb-0">
                                                                        145
                          </h3>
                                                                </td>

                                                            </tr>
                                                            <tr className="border border-color-2">
                                                                <th
                                                                    scope="row"
                                                                    className="pl-6 border-0 py-7 min-width-px-235"
                                                                >
                                                                    <div className="">

                                                                        <a href="/job-details" className="font-size-4 mb-0 font-weight-semibold text-black-2">
                                                                            Head of Marketing
                              </a>

                                                                    </div>
                                                                </th>
                                                                <td className="table-y-middle py-7 min-width-px-135">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        Full-Time
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-125">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        London
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-155">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        15 June, 2021
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-205">
                                                                    <h3 className="font-size-4 font-weight-bold text-black-2 mb-0">
                                                                        62
                          </h3>
                                                                </td>

                                                            </tr>
                                                            <tr className="border border-color-2">
                                                                <th
                                                                    scope="row"
                                                                    className="pl-6 border-0 py-7 min-width-px-235"
                                                                >
                                                                    <div className="">

                                                                        <a href="/job-details" className="font-size-4 mb-0 font-weight-semibold text-black-2">
                                                                            Full-Stack Developer
                              </a>

                                                                    </div>
                                                                </th>
                                                                <td className="table-y-middle py-7 min-width-px-135">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        Part-Time
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-125">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        California
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-155">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        29 May, 2021
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-205">
                                                                    <h3 className="font-size-4 font-weight-bold text-black-2 mb-0">
                                                                        184
                          </h3>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className="row mb-11 align-items-center">
                                                <div className="col-lg-6 mb-lg-0 mb-2">
                                                    <h3 className="font-size-6 mb-0">Completed Jobs (3)</h3>
                                                </div>
                                                <div className="col-lg-6">

                                                </div>
                                            </div>
                                            <div className="bg-white  pt-5   pb-9 px-5">
                                                <div className="table-responsive ">
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr className="bg-green-opacity-2 border border-color-2  ">
                                                                <th
                                                                    scope="col"
                                                                    className="pl-4 border-0 font-size-4 font-weight-bold "
                                                                >
                                                                    Job Title
                        </th>
                                                                <th
                                                                    scope="col"
                                                                    className="pl-4 border-0 font-size-4 font-weight-bold"
                                                                >
                                                                    Job Type
                        </th>
                                                                <th
                                                                    scope="col"
                                                                    className="pl-4 border-0 font-size-4 font-weight-bold"
                                                                >
                                                                    City
                        </th>
                                                                <th
                                                                    scope="col"
                                                                    className="pl-4 border-0 font-size-4 font-weight-bold"
                                                                >
                                                                    Created on
                        </th>
                                                                <th
                                                                    scope="col"
                                                                    className="pl-4 border-0 font-size-4 font-weight-bold"
                                                                >
                                                                    Total Applicants
                        </th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                             
                                                            <tr className="border border-color-2">
                                                                <th
                                                                    scope="row"
                                                                    className="pl-6 border-0 py-7 min-width-px-235"
                                                                >
                                                                    <div className="">

                                                                        <a href="/job-details" className="font-size-4 mb-0 font-weight-semibold text-black-2">
                                                                            UI Designer
                              </a>

                                                                    </div>
                                                                </th>
                                                                <td className="table-y-middle py-7 min-width-px-135">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        Full-Time
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-125">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        Remote
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-155">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        24 June, 2021
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-205">
                                                                    <h3 className="font-size-4 font-weight-bold text-black-2 mb-0">
                                                                        145
                          </h3>
                                                                </td>

                                                            </tr>
                                                            <tr className="border border-color-2">
                                                                <th
                                                                    scope="row"
                                                                    className="pl-6 border-0 py-7 min-width-px-235"
                                                                >
                                                                    <div className="">

                                                                        <a href="/job-details" className="font-size-4 mb-0 font-weight-semibold text-black-2">
                                                                            Head of Marketing
                              </a>

                                                                    </div>
                                                                </th>
                                                                <td className="table-y-middle py-7 min-width-px-135">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        Full-Time
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-125">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        London
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-155">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        15 June, 2021
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-205">
                                                                    <h3 className="font-size-4 font-weight-bold text-black-2 mb-0">
                                                                        62
                          </h3>
                                                                </td>

                                                            </tr>
                                                            <tr className="border border-color-2">
                                                                <th
                                                                    scope="row"
                                                                    className="pl-6 border-0 py-7 min-width-px-235"
                                                                >
                                                                    <div className="">

                                                                        <a href="/job-details" className="font-size-4 mb-0 font-weight-semibold text-black-2">
                                                                            Full-Stack Developer
                              </a>

                                                                    </div>
                                                                </th>
                                                                <td className="table-y-middle py-7 min-width-px-135">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        Part-Time
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-125">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        California
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-155">
                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                        29 May, 2021
                          </h3>
                                                                </td>
                                                                <td className="table-y-middle py-7 min-width-px-205">
                                                                    <h3 className="font-size-4 font-weight-bold text-black-2 mb-0">
                                                                        184
                          </h3>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {/* <!-- Middle Body End --> */}
                                            {/* <!-- Excerpt Start --> */}

                                            {/* <!-- Excerpt End --> */}

                                           </div>
                                            
                                        </Tab.Pane>
                          </Tab.Content>
                          {/* <!-- Tab Content End --> */}
                          {/* <!-- Tab Section End --> */}
                      </Tab.Container>
                  </div> 
              </div>
              {/* <!-- Company Profile End --> */}
              {/* <!-- Sidebar --> */}
               
              {/* <!-- end Sidebar --> */}
          </div></div></div></div>
         
     
    </>
  );
};