import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import { Select } from "../../components/Core";
import GlobalContext from "../../context/GlobalContext";
import { AdminStatusUpdate,UpdateReportStatus } from "../../utils/apiCalls";
import Message from "../../components/Message"; 
import { GetReportedIssues } from "../../utils/GetAPIUtils";

import DOMPurify from 'isomorphic-dompurify';
const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050; 
  }
`;

const ReportedIssueDetails = (props) => {

    const gContext = useContext(GlobalContext);
    const [reasonId, setReasonId] = useState(null);
    const [error, setError] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [values, setValues] = React.useState({ title:'',issuecategory:'',desc:'',status: '',statusobj:null,error:{status:''}});
    const [reportedIssues, setReportedIssues] = useState([]);
    const [disabled, SetDisabled] = useState(true);
    const [statusList, setStatusList] = useState([
        {value:"NEW", label:"NEW"},
        { value: "IN-PROGRESS", label:"IN-PROGRESS"},
        { value: "COMPLETED", label:"COMPLETED"}
        
    ]);
    const [updateStatus, setUpdateStatus] = useState(false);
   
    const statusOptions = [
        { value: "NEW", label: "NEW" },
        { value: "IN-PROGRESS", label: "IN-PROGRESS" },
        { value: "COMPLETED", label: "COMPLETED" }

    ];


    function checkValidity() {
        let inValid = false;
        let { error, ...rest } = values;

      
        error.status = (rest.status) ? "" : "Status is required";
       
        setValues({ ...values, error });

        Object.values(error).forEach(val => {
            if (val.length > 0) {
                inValid = true;
            }
        });

      
        if (inValid)
            return false;
        else
            return true;

    }
 

    const handleSelectChange = (e) => {
        

       

        let error = { ...values.error };
        switch ("status") {
            case "status":

               
                error.status = "";


                setValues({...values,error,["status"]:e.label,["statusobj"]:e});
                break;

      
        }
    }
       // setExistingStatus(e);
        // gContext.setLoaderModalVisible(false);
    





    async function getUtils() {
        const response = await GetReportedIssues();
        
        let profile = null;
        if (response.data && response.data.issueReportList) {
          
            profile = (response.data.issueReportList);
            
           
            const statusvalue = statusOptions.filter(item => item["label"] == profile[0].status);
            //let list = response.data.issueReportList.filter(item => item.firstname != null);
        
            //console.log('exp',exp);


            let profileData = { ...values };
                    
                  
                    profileData.title = profile[0].title;
                    profileData.issuecategory = profile[0].issuecategory;
                    profileData.desc = profile[0].desc;                  
                    profileData.status = (statusvalue.length > 0) ? statusvalue[0] : null;
           // profileData.statusobj=profileData.status ? { label: profileData.status }:null
            //response.data.reportedIssues.filter(item => item["userid"] == 1).map((value) => (
            //  list.push({ value: value.id, label: value.reason })));

            // response.data.issueReportList.map((value) => (
            //    list.push({ userid: value.userid, avatar: value.avatar,fname:value.firstname,lname:value.lastname,date:value.datecreated,title:value.title,cat:value.issuecategory })));

            // console.log(list);
           // setReportedIssues(response.data.issueReportList);
            setValues({ ...profileData, ["statusobj"]:   profileData.status  });
           


        }
    }



    




    function toggleModal() {

        props.togglemodal();
    }










    useEffect(() => {
        if(props.issue != null && props.issue.status != null)
        { setUpdateStatus(false);
            
      /*  if(props.issue.status == 'NEW')
        {
           // setExistingStatus({label:'NEW',value:'1'});
           setStatusList(statusOptions.filter(item => item.label != 'NEW'));
          
        }
        if(props.issue.status == 'IN-PROGRESS')
        {
            setStatusList(statusOptions.filter(item => item.label != 'IN-PROGRESS'));
        }
        if(props.issue.status == 'COMPLETED')
        {
            setStatusList(statusOptions.filter(item => item.label != 'COMPLETED'));
        }*/
    
    setValues({...values,["status"]:props.issue.status,["statusobj"]: { label:props.issue.status}});}
    }, [props.issue]);

    useEffect(() => {
                
                getUtils();  
    }, []);


    async function updateReportStatus(reportId) {

        
        if (checkValidity()) {
      try{  
        
        
        const response = await GetReportedIssues(); 
       
       // console.log('existingStatus.label',existingStatus);
       
      //  existingStatus.label =   (existingStatus.label != "IN-PROGRESS" && existingStatus.label != "COMPLETED" ? "NEW":existingStatus.label);
      //  setValues((existingStatus.label != "IN-PROGRESS" && existingStatus.label != "COMPLETED" ? "NEW":existingStatus.label));

   //console.log(values);
       // const result = await AdminStatusUpdate(existingStatus.label, existingStatus.value, props.issue.userid, 'usertype', props.issue.desc);
       const result = await UpdateReportStatus(reportId,values.status); 
       //const test = await ReportStatusUpdate(existingStatus.label,props.issue.id);
      
        if (result.data.updateReportStatus.responseData.code == "200") { 
            setUpdateStatus(true);
            gContext.setCurrentPage("8");
            gContext.toggleRefreshDashboard("issues");
           
        }
        else {
           // setErrorMessage(JSON.parse(result.data.clientBusUpdate.responseData.message));
        }}
        catch(e)
        {
             console.log(e);
        }
    }
    }


    return (
        <ModalStyled
            {...props}
            size="lg"
            centered
            show={props.modalvisible}
            onHide={toggleModal}
        >
            <Modal.Body className="p-0">
                <button
                    type="button"
                    className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
                    onClick={toggleModal}
                >
                    <i className="fas fa-times"></i>
                </button>

                {props.issue != null && updateStatus === false && 
                  
                    <div className="row no-gutters w-100"  >
                        

                        <div className="col-md-12 col-12 w-100 px-5 py-5" >

                            <div className="container px-0 py-0">
                            
                                <div className="row justify-content-center" >
                                    <div className="col-xl-12 col-lg-12  ">
                                        <div className="font-size-7 font-weight-semibold    text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                                            Reported Issue
                                        </div>
                                    </div>

                                    

                                    <div className="col-xl-12 col-lg-12  mx-auto " >
                                        <div className="bg-white rounded-4 border border-mercury shadow-9 pb-10">
                                            {/* <!-- Single Featured Job --> */}
                                            <div className="pt-9 pl-sm-9 pl-5 pr-sm-9 pr-5  border-bottom border-width-1 border-default-color light-mode-texts">
                                                <div className="row mb-0">
                                                    <div className="col-lg-4">

                                                        <a className="font-size-5 text-black-2 font-weight-semibold" rel="noopener noreferrer" target="blank">
                                                            Issue
                                                        </a>

                                                    </div>
                                                    <div className="col-lg-4 pl-13">

                                                        <a className="font-size-5 text-black-2 font-weight-semibold" rel="noopener noreferrer" target="blank">
                                                            Issue Category
                                                        </a>

                                                    </div>
                                                    <div className="col-lg-4">

                                                        <a className="font-size-5 text-black-2 font-weight-semibold" rel="noopener noreferrer" target="blank">
                                                            Status
                                                        </a>

                                                    </div>



                                                    <div className=" d-none col-md-6 text-right  mt-md-n1">
                                                        {/* <!-- media date start --> */}
                                                        <div className="media justify-content-md-end">
                                                            <a className="font-size-5 text-black-2 font-weight-semibold" rel="noopener noreferrer" target="blank">
                                                                Issue Category
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>

                                                </div>


                                                <div className="row mb-6">
                                                    
                                                    <div className="col-lg-4">

                                                        <a className=" font-size-4 text-gray mb-0 " rel="noopener noreferrer" target="blank">
                                                            {props.issue.title}
                                                        </a>

                                                    </div>
                                                    <div className="col-lg-4 pl-13">

                                                        <a className=" font-size-4 text-gray mb-0 " rel="noopener noreferrer" target="blank">
                                                            {props.issue.issuecategory}
                                                        </a>

                                                    </div>

                                                    <div className=" d-none col-md-6 text-left pt-7 pt-md-0 mt-md-n1">
                                                        {/* <!-- media date start --> */}
                                                        <div className=" mb-0 ">
                                                            <a className=" font-size-4 text-gray" rel="noopener noreferrer" target="blank">
                                                                {props.issue.issuecategory}
                                                            </a>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4 ">

                                                        <div >
                                                        
                                                        <Select 
                                                         className={`form-control pl-0 arrow-3  font-size-4 d-flex align-items-center w-100  
                                                          ${(values.error.status.length > 0) ? 'form-control-error' : ''}`}

                                                        value={values.statusobj} 
                                                         options={statusList}  border={false}
                                                          disabled={disabled}
                                                           onChange={(e) => handleSelectChange(e,'status')}/>
                                                        {values.error.status.length > 1 && (
                                                                        <span className="error font-size-3">{values.error.status}</span>
                                                                    )}
                                                        </div>

                                                    </div>
                                                </div>

  


                                                <div className="row bg-white  m-0 mt-1 border-bottom border-width-1 border-default-color">
                                                    <div className=" col-12   ">

                                                        <div className="job-details-content pt-3  pr-sm-9 pr-6 pb-5    light-mode-texts">
                                                            <div className="row">

                                                            <div className=" pb-7 mt-5">
                                                        <div className=" font-size-4 text-gray mb-0 " style={{ textOverflow: 'ellipsis', overflow: 'hidden', whitespace: 'nowrap' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.issue.desc).replaceAll(`<p>`, ``).replaceAll(`</p>`, ``) }}>

                                                        </div> 
                                                        
                                                    </div>      
                                                                
                                                                <div className="col-lg-12  justify-content-md-end">
                                                                    <div className="media py-10 w-100  justify-content-md-end ml-6"  >
                                                                        <div className="p-0 m-0 position-relative">  <button
                                                                            type="button"

                                                                            className="btn btn-primary btn-medium    btn-green  text-white  rounded-5 text-uppercase"
                                                                            style={{ minWidth: '60px', height: '34px' }} onClick={() => {
                                                                                updateReportStatus(props.issue.id);

                                                                            }}>

                                                                            Save
                                                                        </button>
                                                                        <button  type="button" 
                                                                          className="btn btn-primary btn-medium    btn-green  text-white  rounded-5 text-uppercase  rounded-5 text-uppercase ml-5 ml-sm-3"
                                                                               style={{ minWidth: '60px', height: '34px' }}
                                                                   
                                                                          onClick={() => {
                                                                          toggleModal();

                                                                                            }}>
                                                                              Cancel
                                                                              </button>

                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>




                                        </div>
                                    </div>
                                </div>
                           
                            </div>



                        </div>


                    </div>
                   


                }

{updateStatus === true &&
                        <div className="row  px-auto  text-center pt-10">
                    <Message message={'The status has been updated successfully'} /> 
                    
                        </div>
                    }

            </Modal.Body>
        </ModalStyled>
    );
};

export default ReportedIssueDetails;   

