import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";

import { GetReportedIssues } from "../../utils/GetAPIUtils";

import StatusEdit from "./StatusEditReportedIssueDetails";
import ReportedIssueDetails from "./ReportedIssueDetails";
import { getMonthDateYear } from "../../utils/addDays";
import TablePagination from "../../components/Table/TablePagination";
import tableHelper from "../../components/Table/tableHelper";

export default function ReportedIssues(props) {
    const imgM = "../assets/image/avatar.png";
    const gContext = useContext(GlobalContext);
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [experts, setExperts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showEditStatusModal, setShowEditStatusModal] = useState(false);
    const [currentValue, setCurrentValue] = useState({ index: null, id: null, code: "" });
    const [issue, setIssueData] = useState(null);
    const [read, setRead] = useState(false);
    const [reportedIssues, setReportedIssues] = useState([]);
    const [id, setID] = useState(null);
    const [showJobs, setShowJobs] = useState("jobs");
    const [tabValue, setTabValue] = useState('expert');
    const [page, setPage] = useState(1);
    const { slice, range } = tableHelper(reportedIssues, page, gContext.paginationSize);








    async function getUtils() {

        try {
            gContext.setLoaderModalVisible(true);


            const response = await GetReportedIssues();


            if (response.data && response.data.issueReportList) {
                console.log('response_re', response);

                //let list = [];
                let list = response.data.issueReportList.filter(item => item.firstname != null);
                if (tabValue == 'expert') {
                    let expert = list.filter(item => item.avatar.substr(0, 6) == 'expert');
                    setReportedIssues(expert);

                }
                else if (tabValue == 'client') {
                    //data.avatar.substr(0,6)
                    let client = list.filter(item => item.avatar.substr(0, 6) == 'client');
                    setReportedIssues(client);
                }




                gContext.setLoaderModalVisible(false);
            }
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
        }
        gContext.setLoaderModalVisible(false);
    }


    async function loadData(tabname) {

        try {
            gContext.setLoaderModalVisible(true);


            const response = await GetReportedIssues();
            console.log(response);

            if (response.data && response.data.issueReportList) {

                console.log('issueReportList',response.data.issueReportList);
                
                let list = response.data.issueReportList.filter(item => item.firstname != null);
                if (tabname == 'expert') {
                    let expert = list.filter(item => item.avatar.substr(0, 6) == 'expert');
                    setTabValue('expert');
                    setReportedIssues(expert);

                }
                else if (tabname == 'client') {
                    //data.avatar.substr(0,6)
                    let client = list.filter(item => item.avatar.substr(0, 6) == 'client');
                    setTabValue('client')
                    setReportedIssues(client);
                }




                gContext.setLoaderModalVisible(false);
            }
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
        }
        gContext.setLoaderModalVisible(false);
    }


    function toggleModalStatus(issuedata) {
        setIssueData(issuedata);
        //setRead(true);
        setShowEditStatusModal(!showEditStatusModal);
    }






    function toggleModal(issuedata) {
        setIssueData(issuedata);
        //setRead(true);
        setShowModal(!showModal);
    }

    useEffect(() => {
      
        if(gContext.refreshDashboard =="issues") 
             
        
        getUtils();
        //gContext.toggleRefreshDashboard("");
    }, [gContext.refreshDashboard]);

    useEffect(() => {
        document.title = "Reported Issues | Syncuppro";
        getUtils();
        console.log(process.env.REACT_APP_DOMAIN);
    }, []);
    return (
        <>



            <div
                className="dashboard-main-container mt-24 mt-lg-31 min-h-100vh"
                id="dashboard-body"
            >
                <div className="container">
                    <div className="mb-15 mb-lg-10">
                        <div className="row">
                            <div className="font-size-4 font-weight-semibold  bg-athens  w-100  text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">

                            </div>
                            <div className="col-xxxl-12  ">
                                <div className="contact-form bg-white shadow-8   rounded-10 border">

                                
                                    <div className="d-flex font-size-6 font-weight-semibold mb-11   bg-gray-opacity-3 pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5 w-100">
                                        <div className="w-50">  <span>Reported Issues</span> </div> <div className="w-50 text-right"> <span className="rounded bg-green pointer font-size-6 w-10 text-right  " onClick={() => { loadData(tabValue); }}> <i className="fa fa-refresh m-5 text-white"></i></span>    </div>
                                    </div>



                                    <div className="row bg-white p-0 m-0 mt-10">
                                        <div className="col-12  bg-athens  p-0 m-0">
                                            <div className="header-btns ml-5  d-xs-flex ">
                                                <div className="pl-0  " >

                                                    <a
                                                        className={`btn ${(tabValue == 'expert') ? 'btn-primary' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-5`}
                                                        href="/#"
                                                        onClick={(e) => {
                                                            e.preventDefault();

                                                            loadData('expert');
                                                        }}
                                                    >
                                                        Experts
                                                    </a>
                                                    <a
                                                        className={`btn ${(tabValue == 'client') ? 'btn-primary' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-5`}
                                                        href="/#"
                                                        onClick={(e) => {
                                                            e.preventDefault();

                                                            loadData('client');

                                                        }}
                                                    >
                                                        Clients
                                                    </a>




                                                </div>

                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-lg-12">
                                        <label
                                            htmlFor="namedash"
                                            className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                        >
                                        </label>
                                        <div className="bg-white  pt-5   pb-9 px-2">
                                            <div className="table-responsive ">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr className="bg-gray-opacity-2 border border-color-2 py-5">
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >Reported by

                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >Reporter email

                                                            </th> 
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Title/Issue
                                                            </th>   <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Date
                                                            </th> <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5 "
                                                            >
                                                                Category
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5 "
                                                            >
                                                                Status
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5 "
                                                            >

                                                            </th>





                                                         </tr>
                                                    </thead>
                                                     <tbody>
                                                        {slice &&slice.map((data, index) => {

                                                            return (
                                                                <tr className="border border-color-2" key={index}>

                                                                    <td className="table-y-middle py-5 min-width-px-135">
                                                                        <a className="media min-width-px-235 align-items-center " target="_blank" rel="noopener noreferrer" href={tabValue == 'expert'?process.env.REACT_APP_DOMAIN+'expert/'+data.sub:process.env.REACT_APP_DOMAIN+'client/'+data.sub }  >
                                                                            <div className="circle-36 mr-6">
                                                                                <img src={s3URL + data.avatar} alt="" className=" circle-36 w-100" />
                                                                            </div>
                                                                            <h4 data-toggle="tooltip"  title= {data.firstname + ' ' + data.lastname} className="ellipsis min-width-px-185  font-size-4 mb-0 font-weight-semibold text-black-2 pointer"    >
                                                                                {data.firstname + ' ' + data.lastname}
                                                                            </h4><i className="fa-solid fa-arrow-up-right-from-square font-size-3 text-green pl-5"></i>
                                                                        </a>
                                                                    </td>

                                                                    <td className={`table-y-middle py-5 min-width-px-125  ${(read) ? 'font-weight-bold' : 'font-weight-normal'}`}>

                                                                        {data.email}  
                                                                    </td>

                                                                   

                                                                    <td className=" min-width-px-185" >
                                                                        < h3 data-toggle="tooltip"
                                                                     target="_blank" 
                                                                     rel="noopener noreferrer" 
                                                                     title= {data.title} 
                                                                     style={{
                                                                        width: '12em',
                                                                        
                                                                    }}
                                                                     className=" ellipsis  font-size-4 font-weight-normal text-black-2 pointer mb-0"
                                                                     onClick={(e) => toggleModal(data)}>

                                                                        {data.title}
                                                                        </h3>

                                                                    </td>
                                                                    <td
                                                                        className={`table-y-middle py-5 min-width-px-125  ${(read) ? 'font-weight-bold' : 'font-weight-normal'}`}>
                                                                        {getMonthDateYear(data.datecreated)}
                                                                    </td>
                                                                    <td className={`table-y-middle py-5 min-width-px-125  ${(read) ? 'font-weight-bold' : 'font-weight-normal'}`}>

                                                                        {data.issuecategory}
                                                                    </td>

                                                                    <td className={`table-y-middle py-5 min-width-px-125  ${(read) ? 'font-weight-bold' : 'font-weight-normal'}`}>

                                                                        {data.status}

                                                                    </td>
                                                                    <td>

                                                                        <button className="  border font-size-3 font-weight-bold text-red  rounded-5  border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" data-toggle="tooltip" title="Click to edit." onClick={(e) => toggleModalStatus(data)}  >
                                                                            Edit
                                                                        </button>
                                                                    </td>



                                                                </tr>)
                                                        })}


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <TablePagination range={range} slice={slice} setPage={setPage} page={page} />
                <ReportedIssueDetails issue={issue} modalvisible={showModal} togglemodal={toggleModal} />
                <StatusEdit issue={issue} modalvisible={showEditStatusModal} togglemodal={toggleModalStatus} />
            </div>


        </>
    );
};