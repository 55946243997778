import React, { useContext, useState, useEffect } from "react";

import PageWrapper from "../components/PageWrapper";
import { API, graphqlOperation } from 'aws-amplify'; 
import { getCountry } from './../graphql/queries'; 
import GlobalContext from "../context/GlobalContext"; 
import SidebarDashboard from "../sections/Dashboard/SidebarDashboard"; 
import Experts from "../sections/Dashboard/Experts"; 
import Clients from "../sections/Dashboard/Clients"; 
import AllExperts from "../sections/Dashboard/AllExperts";
import AllClients from "../sections/Dashboard/AllClients"; 
import AllJobs from "../sections/Dashboard/Jobs"; 
import DeletedExperts from "../sections/Dashboard/DeletedExperts";
import Feedback from "../sections/Dashboard/Feedback";
import DeletedClients from "../sections/Dashboard/DeletedClients"; 
import Users from "../sections/Dashboard/Users"; 
import Dashboard from "../sections/Dashboard/dashboard";
import CompanyDashboard from "../sections/Dashboard/CompanyDashboard";
import ReportedIssues from "../sections/ReportedIssues/ReportedIssues";
import { useLocation } from 'react-router-dom';
export default function Profile() {
    const gContext = useContext(GlobalContext);
    const pathName = useLocation().pathname.toLowerCase();
    const [countriesList, setCountriesList] = useState([{}]); 
    if (!localStorage.getItem("connect")) {
        window.location.href = "/";
    } 
    useEffect(() => {
        document.title = "Dashboard | Syncuppro";
    }, []); 
    useEffect(() => {
      
        if (pathName.trim().toLowerCase() == "/dashboard") {
            gContext.setCurrentPage("1");
        }
        getCountries();
    }, []); 
     
    async function getCountries() {
        const result = await API.graphql(graphqlOperation(getCountry));
        if (result.data && result.data.countries) {
            setCountriesList(result.data.countries)
            let countlist = [];
            result.data.countries.map((value) => (
                countlist.push({ value: value.code, label: value.name })));
            setCountriesList(countlist);
        }
    }

    return (
        <>
            <PageWrapper
                headerConfig={{
                    bgClass: "bg-default",
                    button: "profile",
                    isFluid: true
                }}
            >
                {gContext.currentPage === "1" && <Dashboard /> }
                {gContext.currentPage === "4" && <Experts />}
                {gContext.currentPage === "3" && <Clients />}
                {gContext.currentPage === "5" && <Users />}
                {gContext.currentPage === "6" && <AllExperts />}
                {gContext.currentPage === "7" && <AllClients />}
                {gContext.currentPage === "9" && <DeletedExperts />}
                {gContext.currentPage === "10" && <DeletedClients />}
                {gContext.currentPage === "12" && <CompanyDashboard />}
                {gContext.currentPage === "13" && <Feedback />}
                {gContext.currentPage === "11" && <AllJobs />}
                {gContext.currentPage === "8" && <ReportedIssues />}
           <SidebarDashboard />
                   
            </PageWrapper>
        </>
    );
};