import React, { useState, useContext, useEffect } from "react";
import { APIClass } from "aws-amplify";
 
 
 
const Message = (props) => {
   
    
     
    return (
        <div className="col-12   mb-5 text-green-2  font-weight-semibold   px-5  rounded-5 font-size-5">
            <p className="pl-5 text-green-2"> <i className="fa fa-check font-size-7   "></i> {props.message}</p>

        </div>
                            
                            
    );
};

export default Message;
