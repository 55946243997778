import React, { useContext, useState, useEffect } from "react";
import CountUp from "react-countup";
import LazyLoad from "react-lazyload";
import PageWrapper from "../../components/PageWrapper";
import GlobalContext from "../../context/GlobalContext";
import { GetAdminCompanyList } from "../../utils/GetAPIUtils";
import { GetAdminDashboardCount } from "../../utils/GetAPIUtils";
import { AdminCompanyStats } from "../../utils/apiCalls";
import { Select } from "../../components/Core";
export default function DashboardMain() {
  const gContext = useContext(GlobalContext);
 
  const [jobsCount, setJobCounts] = useState([]);
  const [contractCount, setContractCounts] = useState([]);
  const [proposalCount, setProposalCounts] = useState([]);
  const [offersCount, setOfferCounts] = useState([]);
  const [display, setDisplay] = useState([]);
  
  const [values, setValues] = React.useState({ companylist: '' })

  const [companyList, setCompanyList] = useState([{}]);


  async function getCompanyList() {
    try {
      const result = await GetAdminCompanyList();

      if (result.data && result.data.adminCompanyList) {


        let adminCompanyList = [];
        JSON.parse(result.data.adminCompanyList.responseData.message).map((value) => (
          adminCompanyList.push({ value: value.domain, label: value.company + ' - ' + '(' + value.domain + ')' })));

        setCompanyList(adminCompanyList);
       
      }
    }
    catch (e) {
      console.log(e);
      gContext.setLoaderModalVisible(false);
    }
  }



  async function loadstats(website) {

    try {


    


      gContext.setLoaderModalVisible(true);
      const result = await AdminCompanyStats(website);

      let profile = JSON.parse(result.data.adminCompanyStats.responseData.message);
  
      setContractCounts(profile.contractCounts);
      setJobCounts(profile.jobCounts);
      setProposalCounts(profile.proposalCounts);
      setOfferCounts(profile.offerCounts);

    
    }
    catch (e) {
      console.log(e);

    }
    gContext.setLoaderModalVisible(false);
  }







 


  const handleSelectChange = (e) => {

    const value = e.value;


    setValues({ ...values, ["companylist"]: e });
    setDisplay('true');

    loadstats(value);

  }

  useEffect(() => {
    document.title = "Dashboard | Syncuppro";
    setDisplay('false');
    getCompanyList();
   
  }, []);
  return (
    <>
      <PageWrapper
        headerConfig={{
          button: "profile",
          isFluid: true,
          bgClass: "bg-default",
          reveal: false,
        }}
      >

        <div className="dashboard-main-container mt-25 mt-lg-31 min-h-100vh">
          <div className="container">


            <div className="  pb-10 mb-5  justify-content-between">
              <div className="row mb-0">

              <div className="col-lg-3">


</div>
              
                <div className="col-lg-2 text-center">

                  <label
                    htmlFor="select2"
                    className="d-block text-black-2 font-size-4 font-weight-semibold pl-5  mt-4" 
                  >
                    Select Company
                  </label>

                </div>
                <div className="col-lg-4 text-left">

                  <Select options={companyList}
                    placeholder="Select..." value={values.companylist}
                    className={'form-control pl-0 arrow-3 w-100 font-size-4 d-flex align-items-center w-100 mb-4 '}
                    border={false} onChange={(e) => handleSelectChange(e)} onKeyUp={(e) => handleSelectChange(e)}
                  />

                </div>
                <div className="col-lg-3">


                 </div>
                





              </div>
            </div>





            {display == 'true' ?
            <div className="row mb-7">
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-yellow bg-yellow-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-briefcase"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1 ">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={6} end={(jobsCount) ? jobsCount.total : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Total Jobs
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-green-2 bg-green-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-server"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={3} end={(jobsCount) ? jobsCount.active : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Posted Jobs
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>


                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-pink bg-pink-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-user"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={4} end={(proposalCount) ? proposalCount.total : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Total Applicants
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>


                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-orange bg-orange-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-user-check"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={4} end={(proposalCount) ? proposalCount.shortlisted : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Interviewing 
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-green-3 bg-green-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-user-pen"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={4} end={(offersCount) ? offersCount.total : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Total Offers
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-egg-blue bg-egg-blue-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-address-card"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={4} end={(contractCount) ? contractCount.hired : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Total Hired
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category -->  fa-archive,fa-address-book,fa-map-pin*/}
                        </div>

                       

                        



                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-blue bg-blue-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-file-contract"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                               <CountUp duration={3} end={(contractCount) ? contractCount.active : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Active Contract
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-green  bg-green-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-file-signature"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                            <CountUp duration={3} end={(contractCount) ? contractCount.completed : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Completed Contract
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-red  bg-red-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-ban"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                            <CountUp duration={3} end={(contractCount) ? contractCount.terminated: 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Terminated Contract
                            </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>


                        
                        
                       
                    </div> :''}
           
           
           
           
          

            <div
              className="accordion rounded-10 border  pl-1  mt-10"

            >


            </div>




          </div>
        </div>
      </PageWrapper>
    </>
  );
};