  import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import { SearchExpert} from "../../utils/apiCalls";
import ReactCountryFlag from "react-country-flag";
import { Collapse } from "react-bootstrap";


export default function Users(props) {

    const gContext = useContext(GlobalContext); 
    const [openItem, setOpenItem] = useState({ expert: true, client: true });

    async function LoadExpert() {
        try {  gContext.setLoaderModalVisible(true);
            const result = await SearchExpert({ avgRating: "0", otherLangs: "", countryId: null, country: null, keyword: '', jobTypes: "", expLvlIds: '', skillIds: '', complianceCategoryIds: '', complianceStandardIds: '', weeklyHrsIds: '', projLengthIds: '', period: '', rateRange: '', industryIds: "", dateSince: '', rateRangeFrom: '', rateRangeTo: '', rateType: '' });
            if (result.data.searchTalents.responseData.code == "200") {

                let data = JSON.parse(result.data.searchTalents.responseData.message);
                if (data.length > 0) {
                  //  setExperts(data); 
                }
                else {
                    gContext.setLoaderModalVisible(false);
                }

            } else {
                gContext.setLoaderModalVisible(false);
            }
           
            gContext.setLoaderModalVisible(false);
        }
        catch (e) { 
            gContext.setLoaderModalVisible(false);
        }
    }  

    useEffect(() => { 
        document.title = "Experts | Syncuppro";
        LoadExpert(); 
    }, []);
    return (
        <>
            <div
                className="dashboard-main-container mt-24 mt-lg-31 min-h-100vh"
                id="dashboard-body"
            >
                <div className="container">
                    <div className="mb-15 mb-lg-23">
                        <div className="row">
                            <div className="font-size-4 font-weight-semibold  bg-athens  w-100  text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                                 
                            </div>
                            <div className="col-xxxl-12  "> 
                                <div
                                    className="accordion rounded-10 border  pl-1  mt-10"  >
                                    <div className="border-bottom overflow-hidden ">
                                        <div className={`mb-0 m-0 border-bottom-0  bg-athens `} id="heading2-3">
                                            <button
                                                data-toggle="tooltip" title="Click here to Expand/Collapse" className="btn-reset font-size-6 font-weight-semibold text-green-2 text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                                type="button"
                                                onClick={() => { setOpenItem({ ...openItem, ["expert"]: !openItem.expert });  }}
                                                aria-expanded={openItem.expert}
                                            >
                                                Experts
                        </button>
                                        </div>
                                        <Collapse in={openItem.expert}>
                                             
                                        </Collapse>
                                    </div>
                                    <div className="border-bottom overflow-hidden ">
                                        <div className={`mb-0 m-0 border-bottom-0  bg-athens `} id="heading2-3">
                                            <button
                                                data-toggle="tooltip" title="Click here to Expand/Collapse" className="btn-reset font-size-6 font-weight-semibold text-green-2 text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                                type="button"
                                                onClick={() => { setOpenItem({ ...openItem, ["client"]: !openItem.client }); }}
                                                aria-expanded={openItem.client}
                                            >
                                                Clients
                        </button>
                                        </div>
                                        <Collapse in={openItem.client}>
                                             
                                        </Collapse>
                                    </div>
                                     
                                </div>
                      

                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </>
    );
};