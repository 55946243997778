import React, { useState, useEffect, useContext} from "react";
import PageWrapper from "../components/PageWrapper"; 
import Hero from "../sections/home/Hero";
import GlobalContext from "../context/GlobalContext";
 
export default function MainPage({ zone, navType }) {
    const gContext = useContext(GlobalContext);
    useEffect(() => {
        document.title = "Home | Syncuppro";

        if (localStorage.getItem('connect'))
            window.parent.postMessage(localStorage.getItem('connect'), process.env.REACT_APP_DOMAIN);
        else
            window.parent.postMessage(null, process.env.REACT_APP_DOMAIN);

        const passwordChanged = localStorage.getItem('passwordChanged');      
        if (passwordChanged) {
            localStorage.removeItem('passwordChanged');
            gContext.toggleErrorModal();
        }
    }, [])

  return (
    <>
      <PageWrapper
        headerConfig={{
                  bgClass: "dynamic-sticky-bg",
                  button: navType
        }}
          >  
              <Hero />  
       
      </PageWrapper>
    </>
  );
};

