import React, { useState, useContext, useEffect } from "react";
import StarRating from "../../components/Core/Rating";

import { Nav, Tab } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import ReactCountryFlag from "react-country-flag";
import { getMonthYear } from "../../utils/addDays";
import { GetExpertPreviewLoad } from "../../utils/apiCalls";
import Message from "../../components/Message";
import DOMPurify from 'isomorphic-dompurify';
var imgP = "";
const imgB1 = "../assets/image/l2/png/featured-job-logo-1.png";
const imgB2 = "../assets/image/l1/png/feature-brand-1.png";
const imgB3 = "../assets/image/svg/harvard.svg";
const imgB4 = "../assets/image/svg/mit.svg";
const img1 = "../assets/image/l3/png/fimize.png";
const img2 = "../assets/image/svg/icon-shark-2.svg";
const img3 = "../assets/image/svg/icon-thunder.svg";
const img4 = "../assets/image/l3/png/asios.png";

const imgL = "../assets/image/svg/icon-loaction-pin-black.svg";



const ProfileView = (props) => {
    {
        const gContext = useContext(GlobalContext);
        const subParam = props.sub;
        // const [profileInfo, setProfileInfo] = React.useState({ "id": null, "sub": "", "firstname": "", "lastname": "", "email": "", "phone": "", "address1": "", "address2": "", "city": "", "state": "", "countryid": null, "countryname": "", "avatar": "", "photo": "", "title": "", "profile": "", "dailyrate": "", "otherlangs": "", "idverified": false, "timezoneid": null, "timezonecode": "", "timezonename": "", "timezonevalue": "", "online": null, "explevelid": null, "explevelname": "", "profilecomplete": false, "approved": false, "active": false, "flagged": false, "flaggedreasonid": null, "flaggedreason": null, "avgrating": null, "weeklyhoursid": null, "weeklyhours": "", "resume": "null", "skillids": "", "complianceids": "", "skills": [], "compliances": []});
        const [profileInfo, setProfileInfo] = React.useState({});
        const [rating, setRating] = React.useState(4.5);
        const [show, setShow] = React.useState(false);
        const [message, setMessage] = React.useState(false);



        var localtime;



        function getlocaltime(offset_value) {

            try {
                const now = new Date();
                const timezoneOffset = now.getTimezoneOffset() / 60;

                var sign = offset_value.substring(3, 4);

                sign = sign.replace("−", "-");
                sign = sign.replace("±", "-");

                var UTC_value = offset_value.substring(4, 6);
                var secondpart = offset_value.substring(7, 9);


                var UTC_minutes = UTC_value * 60;


                var total_minutes = parseInt(UTC_minutes) + parseInt(secondpart);

                var total_hours = total_minutes / 60;
                total_hours = sign + total_hours;


                const timezones = [total_hours];


                timezones.forEach(timezone => {
                    const difference = +timezone + timezoneOffset;


                    const time = new Date(now.getTime() + difference * 3600000);
                    localtime = time.toLocaleTimeString([], { timeStyle: 'short', hour12: true });
                    // alert(localtime);
                })


            }
            catch (e) {
                console.log(e);
            }
        }


        async function ExpertPreviewInfo() {
            try {
                setMessage(false);
                const result = await GetExpertPreviewLoad(subParam);
                if (result.data.expertAllLoad.responseData.code == "200") {
                    //[{"id":66,"sub":"f180bc26-27fa-4a1b-9b75-fee8dd832db0","firstname":"Karthik","lastname":"K","email":"sathya@springinfoservices.com","phone":"","address1":"address","address2":"","city":"LA","state":"CA","countryid":61,"countryname":"India","avatar":"","photo":"","title":"SE","profile":"Apple Inc. is an American multinational technology company specializing in consumer electronics, software and online services headquartered in Cupertino, California, United States. Apple is the largest technology company by revenue (totaling US$365.8 billion in 2021) and, as of June 2022, is the world's biggest company by market capitalization, the fourth-largest personal computer vendor by unit sales and second-largest mobile phone manufacturer. It is one of the Big Five American information technology companies, alongside Alphabet, Amazon, Meta, and Microsoft.","dailyrate":"250.00","otherlangs":"","idverified":false,"timezoneid":586,"timezonecode":"IST","timezonename":"India Standard Time","timezonevalue":"UTC+05:30","online":null,"explevelid":1,"explevelname":"Entry level (0-3 years)","profilecomplete":false,"approved":false,"active":false,"flagged":false,"flaggedreasonid":null,"flaggedreason":null,"avgrating":null,"weeklyhoursid":2,"weeklyhours":"10 hrs/week","resume":"null","skillids":"13,12,9","complianceids":"42,50,44","skills":[{"skill":"Cybersecurity"},{"skill":"Compliance Audit"},{"skill":"Certification audit"}],"compliances":[{"code":"AS9100"},{"code":"CMMI"},{"code":"CE"}]}]

                    let profile = JSON.parse(result.data.expertAllLoad.responseData.message);
                    console.log('profileview', profile);
                    if (profile && profile.length > 0) {
                        setProfileInfo(profile[0]);
                        imgP = (profile[0].avatar != "") ? process.env.REACT_APP_AWS_S3_URL + profile[0].avatar : "../ assets/image/avatar.png";
                        console.log({ ...profileInfo });
                    }
                    else {
                        if (subParam)
                            window.location.href = "/error";
                        else
                            setMessage(true);
                    }
                }
                else {
                    setMessage(true);
                }

            }
            catch (e) {
                setMessage(true);
                console.log(e);
            }

        }

        function changeRating(newRating, name) {
            setRating(newRating);

        }
        useEffect(() => {
            const date = new Date().toISOString();
            console.log(date);
            document.title = "Profile | Syncuppro";
            ExpertPreviewInfo();
        }, []);
        useEffect(() => {

            setShow(true);
        }, [profileInfo]);
        return (
            <>{message &&
                <div className="mt-24 mt-lg-31 mr-10 ml-10  dashboard-main-container row ">
                    <Message message="No data found" />
                </div>}
                {(show === true && profileInfo.lastname != null) &&
                    <div
                        className={` mt-24 mt-lg-31   ${(props.sub) ? '' : 'dashboard-main-container'}`}
                        id="dashboard-body"
                    >
                        <div className="container">
                            <div className="mb-15 mb-lg-23">
                                <div className="row ">
                                    {/* <!-- Company Profile --> */}
                                    <div className="col-12 col-xl-11 col-lg-11">

                                        <div className="container">
                                            {/* <!-- back Button --> */}
                                            <div className="row d-none justify-content-center">
                                                <div className="col-12 py-2 mb-5 text-gray  font-weight-semibold d-none  px-5 bg-green-opacity-2 rounded-5 font-size-5">
                                                    <div className="row "><div className="col-1">
                                                        <i className="fas fa-file-circle-check font-size-12 text-green-2 "></i>
                                                    </div><div className="col-10 d-flex align-items-center justify-content-left"><p className="text-gray px-7  pt-5"> Your profile is verified.</p></div></div>
                                                </div>
                                                <div className="col-12 py-2 mb-5 text-red  font-weight-semibold   px-5 bg-gray-opacity-2 rounded-5 font-size-5">
                                                    <div className="row"><div className="col-1">
                                                        <i className="fas fa-file-circle-xmark font-size-12 text-gray "></i>

                                                    </div><div className="col-10 d-flex align-items-center justify-content-left"><p className="text-gray  px-7 pt-5"> Profile is not verified.</p></div></div>
                                                </div>
                                                <div className="col-12 py-2 mb-5 text-gray  font-weight-semibold   px-5 bg-yellow-2-opacity-2 rounded-5 font-size-5">
                                                    <div className="row"><div className="col-1">

                                                        <img src="../icons8-in-progress-48.png" style={{ width: '75px' }} />
                                                    </div><div className="col-10 d-flex align-items-center justify-content-left"><p className="text-gray  px-7 pt-5"> Profile verification  underprogress.</p></div></div>
                                                </div>

                                                <div className="col-12 dark-mode-texts">
                                                    <div className="mb-9">

                                                        {/* <!--         <span className="d-flex align-items-center ml-4" onClick={() => { gContext.setCurrentPage("5"); }}>
                      <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                                  <span className="text-uppercase font-size-3 font-weight-bold text-gray" >
                        Back
                      </span>
                   </span> --> */}


                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 dark-mode-texts">
                                                <div className="mb-9">

                                                    {/* <!--         <span className="d-flex align-items-center ml-4" onClick={() => { gContext.setCurrentPage("5"); }}>
                      <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                                  <span className="text-uppercase font-size-3 font-weight-bold text-gray" >
                        Back
                      </span>
                   </span> --> */}

                                                </div>
                                            </div>
                                            {/* <!-- back Button End --> */}
                                            <div className="row">
                                                {/* <!-- Left Sidebar Start --> */}
                                                <div className="col-12 col-xxl-3 col-lg-4 col-md-5 mb-11 mb-lg-0">
                                                    <div className="pl-lg-5">
                                                        {/* <!-- Top Start --> */}
                                                        <div className=" position-relative bg-white shadow-9 rounded-4">
                                                            <span className="pos-abs-tl circle-48 bg-green font-size-7 mt-n7 ml-n7 d-none ">
                                                                <i className="fas fa-check text-white"></i>
                                                            </span>
                                                            <span className="   d-none font-weight-bold bg-green text-white font-size-3 mt-0 ml-0 px-5 ">
                                                                <i className="fas fa-check text-green-2 font-size-6 mr-1"></i>  Verified
                                                            </span>
                                                            <div className="  pt-11 pb-5 text-center   border-mercury">

                                                                <div className="container">

                                                                    <div className="row ">
                                                                        <div className="col-2  ">

                                                                        </div>

                                                                        <div className="col-8  d-flex align-items-center text-center  ">
                                                                            <div className="ml-10 ml-sm-5 position-relative  d-flex align-items-center text-center  ">
                                                                                <img className="circle-100  " src={imgP} alt="" />
                                                                                {(profileInfo.idverified && profileInfo.profilecomplete) && <div className="  pos-abs-br font-weight-bold  text-green-2 font-size-3  ml-n28 mb-2   ">
                                                                                    <i className="fas fa-circle-check bg-white circle-24 text-green-2 font-size-8  "></i>
                                                                                </div>}
                                                                                {(profileInfo.idverified && !profileInfo.profilecomplete) && <div className="   pos-abs-br font-weight-bold  text-gray font-size-3  ml-n30    ">
                                                                                    <div className="circle-30 bg-yellow text-center"> <i className="fas fa-times text-gray font-size-6  "></i></div>
                                                                                </div>}
                                                                                {(!profileInfo.idverified && !profileInfo.profilecomplete) && <div className="   pos-abs-br font-weight-bold  text-red font-size-3  ml-n30 ">
                                                                                    <div className="circle-30 bg-red text-center"> <i className="fas fa-times text-white font-size-6 "></i></div>
                                                                                </div>}

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-2">

                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div className="px-5 pt-0 pb-0 text-center border-bottom border-mercury">{(profileInfo.idverified && profileInfo.profilecomplete) && <div className="    font-weight-bold  text-green-2 font-size-3  ">
                                                                Verified
                                                            </div>}
                                                                {(profileInfo.idverified && !profileInfo.profilecomplete) && <div className="   font-weight-bold  text-gray font-size-3     ">
                                                                    Pending
                                                                </div>}
                                                                {(!profileInfo.idverified && !profileInfo.profilecomplete) && <div className="     font-weight-bold  text-red font-size-3     ">
                                                                    Not Verified
                                                                </div>} </div>
                                                            <div className="px-5 pt-5 pb-5 text-center border-bottom border-mercury">
                                                                <h4 className="mb-0 mt-5">
                                                                    <a className="text-black-2 font-size-6 font-weight-semibold">
                                                                        {profileInfo.firstname} {profileInfo.lastname}
                                                                    </a>

                                                                </h4>


                                                                <p className="mb-4">

                                                                    <a className="text-gray font-size-4">{profileInfo.title}</a>

                                                                </p>
                                                                <a className="font-size-3 d-none">

                                                                    <i className="fas fa-star mr-1  text-green-2"></i><i className="fas fa-star mr-1  text-green-2"></i><i className="fas fa-star mr-1  text-green-2"></i><i className="fas fa-star mr-1  text-green-2"></i><i className="fas fa-star mr-1  text-gray"></i> <span className="font-size-3  text-green-2 font-weight-semibold"> 4/5  </span>  <span className="font-size-3  text-green-2 font-weight-semibold">   </span>
                                                                </a>

                                                                <p className="mt-0 mb-0 py-0">

                                                                    <StarRating rating={rating} />   </p>
                                                                <p className="mt-0 mb-4 py-0">
                                                                    <span className="font-size-4  text-green-2 font-weight-semibold"> {rating} / 5  </span></p>
                                                                <p className="font-size-4 mt-8 mb-0">
                                                                    Daily Rate
                                                                </p>
                                                            <span className="font-size-4 text-green-2 font-weight-semibold pl-2"> USD {(profileInfo.dailyrate) ? new Intl.NumberFormat('en-US').format(parseInt(profileInfo.dailyrate)) : "0"} </span>

                                                                {((props.mode === "client" && gContext.userInfo.profileInfo != null) || (gContext.userInfo.userType == 2) || (gContext.userInfo.sub == subParam)) && <div className="icon-link d-flex mt-10   align-items-center justify-content-center flex-wrap">

                                                                    {(profileInfo.social) ? profileInfo.social.map((item, index) => {

                                                                        return (<a key={index}>

                                                                            {(item.link == "Facebook") ? <a className="text-white circle-32 bg-marino mr-5  " href={item.url} rel="noopener noreferrer" target="_blank"><i className="fab fa-facebook-f"></i></a> : ''}
                                                                            {(item.link == "LinkedIn") ? <a className="text-white circle-32 bg-allports mr-5  " href={item.url} rel="noopener noreferrer" target="_blank"><i className="fab fa-linkedin-in"></i></a> : ''}
                                                                            {(item.link == "Twitter") ? <a className="text-white circle-32 bg-egg-blue mr-5  " href={item.url} rel="noopener noreferrer" target="_blank"><i className="fab fa-twitter"></i></a> : ''}
                                                                            {(item.link == "YouTube") ? <a className="text-white circle-32 bg-red-2 mr-5   " href={item.url} rel="noopener noreferrer" target="_blank"><i className="fab fa-youtube font-size-5"></i></a> : ''}
                                                                            {(item.link == "Blog") ? <a className="text-white circle-32 bg-orange mr-5 mt-2 " href={item.url} rel="noopener noreferrer" target="_blank"><i className="fa-solid fa-blog "></i></a> : ''}
                                                                            {(item.link == "Vlog") ? <a className="text-white circle-32 bg-yellow mr-5 mt-2  " href={item.url} rel="noopener noreferrer" target="_blank"><i className="fa-solid fa-video"></i></a> : ''}
                                                                            {(item.link == "Others") ? <a className="text-white circle-32 bg-green mr-5 mt-2  " href={item.url} rel="noopener noreferrer" target="_blank"><i className="fas fa-globe mr-3.2"></i></a> : ''}



                                                                        </a>)
                                                                    }) : ''}
                                                                </div>}
                                                            </div>
                                                            {/* <!-- Top End --> */}
                                                            {/* <!-- Bottom Start --> */}
                                                            <div className="px-9 pt-lg-5 pt-9 pt-xl-9 pb-5">
                                                                <h5 className="text-black-2 mb-8 font-size-5">Info</h5>
                                                                <div className="mb-7">
                                                                    <p className="font-size-4 mb-0">Location  <ReactCountryFlag
                                                                        countryCode={profileInfo.countrycode}
                                                                        svg
                                                                        style={{
                                                                            width: '2em',
                                                                            height: '1em',
                                                                        }}
                                                                        title={profileInfo.countrycode}
                                                                    />   </p>
                                                                    <h5 className="font-size-4 font-weight-semibold mb-0 text-black-2 text-break">
                                                                        {' '} {profileInfo.city} ,  {profileInfo.countryname}
                                                                    </h5>
                                                                </div>
                                                                {/* <!-- Single List --> */}
                                                                <div className="mb-7" onLoad={getlocaltime(profileInfo.timezonevalue)}>
                                                                    <p className="font-size-4 mb-0">Local Time</p>
                                                                    <h5 className="font-size-4 font-weight-semibold mb-0 text-black-2 text-break">


                                                                        {localtime}
                                                                    </h5>
                                                                </div>
                                                                <div className="mb-7">
                                                                    <p className="font-size-4 mb-0">Time zone</p>
                                                                    <h5 className="font-size-4 font-weight-semibold mb-0 text-black-2 text-break">
                                                                        ({profileInfo.timezonecode}) - {profileInfo.timezonename}
                                                                    </h5>
                                                                </div>

                                                                <div className="mb-7">
                                                                    <p className="font-size-4 mb-0">Experience</p>
                                                                    <h5 className="font-size-4 font-weight-semibold mb-0 text-black-2 text-break">
                                                                        {profileInfo.explevelname}
                                                                    </h5>
                                                                </div>
                                                                <div className="mb-7">
                                                                    <p className="font-size-4 mb-0">Available weekly hours</p>
                                                                    <h5 className="font-size-4 font-weight-semibold mb-0 text-black-2 text-break">
                                                                        {profileInfo.weeklyhours}
                                                                    </h5>
                                                                </div>

                                                            </div>
                                                            {/* <!-- Bottom End --> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <!-- Left Sidebar End --> */}
                                                {/* <!-- Middle Content --> */}
                                                <div className="col-12 col-xxl-9 col-lg-8 col-md-7 ">
                                                    <Tab.Container id="left-tabs-example" defaultActiveKey="one">
                                                        <div className="bg-white rounded-4 shadow-9">
                                                            {/* <!-- Tab Section Start --> */}
                                                            <Nav
                                                                className="nav border-bottom border-mercury pl-12"
                                                                role="tablist"
                                                            >
                                                                <li className="tab-menu-items nav-item pr-12">
                                                                    <Nav.Link
                                                                        eventKey="one"
                                                                        className="text-uppercase font-size-3 font-weight-bold text-default-color py-3 px-0"
                                                                    >
                                                                        Overview
                                                                    </Nav.Link>
                                                                </li>


                                                                {/* <!--  <li className="tab-menu-items nav-item pr-12">
                        <Nav.Link
                          eventKey="two"
                          className="text-uppercase font-size-3 font-weight-bold text-default-color py-3 px-0"
                        >
                                              Applied Jobs
                        </Nav.Link>
                                      </li>
                                      <li className="tab-menu-items nav-item pr-12">
                                          <Nav.Link
                                              eventKey="three"
                                              className="text-uppercase font-size-3 font-weight-bold text-default-color py-3 px-0"
                                          >
                                              Saved Jobs
                        </Nav.Link>
                                      </li>
                  
                     Tab Content --> */}  </Nav>
                                                            <Tab.Content>
                                                                <Tab.Pane eventKey="one">
                                                                    {/* <!-- Excerpt Start --> */}
                                                                    <div className="pr-xl-0 pr-xxl-14 p-5 px-xs-12 pt-7 pb-5">
                                                                        <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
                                                                            About
                                                                        </h4>
                                                                        <div className="pt-5 ">
                                                                            <div className="font-size-4 text-black-2 mb-7" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(profileInfo.profile) }}>
                                                                            </div>
                                                                        </div>


                                                                    </div>

                                                                    <div className="border-top pr-xl-0 pr-xxl-14 p-5 pl-xs-12 pt-7 pb-5">
                                                                        <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
                                                                            Industry Types
                                                                        </h4>
                                                                        <ul className="list-unstyled d-flex align-items-center flex-wrap">
                                                                            {(profileInfo && profileInfo.industry) && profileInfo.industry.map((data, index) => {
                                                                                const { name } = data;
                                                                                return (<li key={index}>

                                                                                    <li className="bg-red-opacity-2 mr-3 h-px-33 text-center flex-all-center rounded-15 px-5 font-size-3 text-black-2 mt-2">
                                                                                        {name}
                                                                                    </li>

                                                                                </li>)
                                                                            })}
                                                                        </ul>
                                                                    </div>




                                                                    {/* <!-- Excerpt End --> */}
                                                                    {/* <!-- Skills --> */}
                                                                    <div className="border-top pr-xl-0 pr-xxl-14 p-5 pl-xs-12 pt-7 pb-5">
                                                                        <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
                                                                            Skills
                                                                        </h4>
                                                                        <ul className="d-flex list-unstyled flex-wrap pr-sm-25 pr-md-0">

                                                            {(profileInfo && profileInfo.skills) && profileInfo.skills.map((data, index) => {
                                                                const { skill } = data;
                                                                return (<li key={index}>

                                                                    <li className="bg-orange-opacity-2  mr-3 h-px-33 text-center flex-all-center rounded-15 px-5 font-size-3 text-black-2 mt-2">
                                                                        {skill}
                                                                    </li>

                                                                </li>)
                                                            })}
                                                        </ul>
                                                                    </div>


                                                                    <div className="border-top pr-xl-0 pr-xxl-14 p-5 pl-xs-12 pt-7 pb-5">
                                                                        <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
                                                                            Compliance Category
                                                                        </h4>
                                                                        <ul className="list-unstyled d-flex align-items-center flex-wrap">

                                                                            {(profileInfo.complianceCategory) ? profileInfo.complianceCategory.map((item, index) => {

                                                                                return (<li key={index}>

                                                                                    <a className="bg-yellow-opacity-2 text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-15 min-height-32 d-flex align-items-center">
                                                                                        {item.name}
                                                                                    </a>

                                                                                </li>)
                                                                            }) : ''}

                                                                        </ul>
                                                                    </div>


                                                                    <div className="border-top pr-xl-0 pr-xxl-14 p-5 pl-xs-12 pt-7 pb-5">
                                                                        <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
                                                                            Compliance Standards
                                                                        </h4>
                                                                        <ul className="list-unstyled d-flex align-items-center flex-wrap">

                                                                            {(profileInfo.compliances) ? profileInfo.compliances.map((item, index) => {
                                                                                return (<li key={index}>
                                                                                    <a className="bg-green-opacity-2 text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-15 min-height-32 d-flex align-items-center">
                                                                                        {item.code}
                                                                                    </a>
                                                                                </li>)
                                                                            }) : ''}

                                                                        </ul>
                                                                    </div>
                                                                    {/* <!-- Skills End --> */}
                                                                    {/* <!-- Card Section Start --> */}

                                                                    {/* <!-- Card Section End --> */}
                                                                    {/* <!-- Card Section Start --> */}

                                                                    {/* <!-- Card Section End --> */}
                                                                </Tab.Pane>
                                                                <div className="border-top p-5 pl-xs-12 pt-7 pb-5">
                                                                    <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
                                                                        Work Experience
                                                                    </h4>  {(profileInfo.experiences) ? profileInfo.experiences.map((item, index) => {
                                                                        return (

                                                                            <div className="w-100" key={index}>
                                                                                <div className="d-flex align-items-center pr-11 mb-9 flex-wrap flex-sm-nowrap">
                                                                                    <div className="square-72  d-block mr-8 mb-7 mb-sm-0">


                                                                                        <div
                                                                                            id="userActions"
                                                                                            className={` border text-center py-7 px-7`}

                                                                                            style={{ width: "80px", height: "80px" }}><span className="font-size-7 text-green-2 " >   <i className="fa  fa-briefcase "></i> </span></div>
                                                                                    </div>
                                                                                    <div className="w-100 mt-n2">
                                                                                        <h3 className="mb-0">

                                                                                            <a className="font-size-6 text-black-2 font-weight-semibold">
                                                                                                {item.role}
                                                                                            </a>

                                                                                        </h3>

                                                                                        <a className="font-size-4 text-default-color line-height-2">
                                                                                            {item.company}
                                                                                        </a>

                                                                                        <div className="d-flex align-items-center justify-content-md-between flex-wrap">

                                                                                            <a className="font-size-3 text-gray mr-5">
                                                                                                {(item.startdate) ? getMonthYear(item.startdate) : ''}  {(item.enddate) ? ' - ' + getMonthYear(item.enddate) : '- now'} - {item.expyears} years
                                                                                            </a>


                                                                                            <a className="font-size-3 text-gray d-none">
                                                                                                <span
                                                                                                    className="mr-4"
                                                                                                    css={`
                                          margin-top: -2px;
                                        `}
                                                                                                >
                                                                                                    <img src={imgL} alt="" />
                                                                                                </span>
                                                                                                New York, USA
                                                                                            </a>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }) : ''}
                                                                </div>

                                                                {((props.mode === "client" && gContext.userInfo.profileInfo != null) || (gContext.userInfo.userType == 2) || (gContext.userInfo.sub == subParam)) &&

                                                                    <div className="border-top p-5 pl-xs-12 pt-7 pb-5">
                                                                        <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
                                                                            Education
                                                                        </h4>  {(profileInfo.education) ? profileInfo.education.map((item, index) => {
                                                                            return (

                                                                                <div className="w-100" key={index}>
                                                                                    <div className="d-flex align-items-center pr-11 mb-9 flex-wrap flex-sm-nowrap">
                                                                                        <div className="square-72  d-block mr-8 mb-7 mb-sm-0">
                                                                                            <div
                                                                                                id="userActions"
                                                                                                className={` border text-center py-7 px-7`}

                                                                                                style={{ width: "80px", height: "80px" }}><span className="font-size-7 text-green-2 " >   <i className="fa  fa-user-graduate  "></i> </span></div>
                                                                                        </div>
                                                                                        <div className="w-100 mt-n2">
                                                                                            <h3 className="mb-0">

                                                                                                <a className="font-size-6 text-black-2 font-weight-semibold">
                                                                                                    {item.degree + ' - ' + item.major}
                                                                                                </a>

                                                                                            </h3>

                                                                                            <a className="font-size-4 text-default-color line-height-2">
                                                                                                {item.school}
                                                                                            </a>

                                                                                            <div className="d-flex align-items-center justify-content-md-between flex-wrap">

                                                                                                <a className="font-size-3 text-gray mr-5">
                                                                                                    {(item.startdate) ? getMonthYear(item.startdate) : ''}  {(item.enddate) ? ' - ' + getMonthYear(item.enddate) : ''}
                                                                                                </a>


                                                                                                <a className="font-size-3 text-gray ">
                                                                                                    <span
                                                                                                        className="mr-4"
                                                                                                        css={`
                                          margin-top: -2px;
                                        `}
                                                                                                    >
                                                                                                        <img src={imgL} alt="" />
                                                                                                    </span>
                                                                                                    <ReactCountryFlag
                                                                                                        countryCode={item.countrycode}
                                                                                                        svg
                                                                                                        style={{
                                                                                                            width: '2em',
                                                                                                            height: '1em',
                                                                                                        }}
                                                                                                        title={item.countrycode}
                                                                                                    />   {item.countryname}
                                                                                                </a>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }) : ''}
                                                                    </div>
                                                                }
                                                                <Tab.Pane eventKey="two">
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="three">
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="four">
                                                                    {/* <!-- Excerpt Start --> */}
                                                                    <div className="pr-xl-11 p-5 pl-xs-12 pt-9 pb-11">
                                                                        <div className="row justify-content-center">
                                                                            <div className="col-lg-11 col-md-11 col-sm-11 mb-9">
                                                                                {/* <!-- Single Featured Job --> */}
                                                                                <div className="pt-9 px-xl-9 px-lg-7 px-7 pb-7 light-mode-texts bg-white rounded hover-shadow-3">
                                                                                    <div className="media align-items-center">
                                                                                        <div className="square-52 bg-indigo mr-8 rounded">

                                                                                            <a>
                                                                                                <img src={img1.src} alt="" />
                                                                                            </a>

                                                                                        </div>
                                                                                        <div>

                                                                                            <a className="font-size-3 text-default-color line-height-2">
                                                                                                Fimize
                                                                                            </a>

                                                                                            <h3 className="font-size-6 mb-0">

                                                                                                <a className="heading-default-color font-weight-semibold">
                                                                                                    Senior Marketing Expert
                                                                                                </a>

                                                                                            </h3>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex pt-17">
                                                                                        <ul className="list-unstyled mb-1 d-flex flex-wrap">
                                                                                            <li>

                                                                                                <a className="bg-regent-opacity-15 text-denim font-size-3 rounded-15 min-width-px-100 px-3 flex-all-center mr-6 h-px-33 mt-4">
                                                                                                    <i className="icon icon-pin-3 mr-2 font-weight-bold"></i>{" "}
                                                                                                    London
                                                                                                </a>

                                                                                            </li>
                                                                                            <li>

                                                                                                <a className="bg-regent-opacity-15 text-orange font-size-3 rounded-15 min-width-px-100 px-3 flex-all-center mr-6 h-px-33 mt-4">
                                                                                                    <i className="fa fa-briefcase mr-2 font-weight-bold"></i>{" "}
                                                                                                    Full-time
                                                                                                </a>

                                                                                            </li>
                                                                                        </ul>

                                                                                        <a className="bookmark-button toggle-item font-size-6 ml-auto line-height-reset px-0 mt-6 text-default-color  clicked"></a>

                                                                                    </div>
                                                                                </div>
                                                                                {/* <!-- End Single Featured Job --> */}
                                                                            </div>
                                                                            <div className="col-lg-11 col-md-11 col-sm-11 mb-9">
                                                                                {/* <!-- Single Featured Job --> */}
                                                                                <div className="pt-9 px-xl-9 px-lg-7 px-7 pb-7 light-mode-texts bg-white rounded hover-shadow-3">
                                                                                    <div className="media align-items-center">
                                                                                        <div className="square-52 bg-regent mr-8 rounded">

                                                                                            <a>
                                                                                                <img src={img2.src} alt="" />
                                                                                            </a>

                                                                                        </div>
                                                                                        <div>

                                                                                            <a className="font-size-3 text-default-color line-height-2">
                                                                                                Shark
                                                                                            </a>

                                                                                            <h3 className="font-size-6 mb-0">

                                                                                                <a className="heading-default-color font-weight-semibold">
                                                                                                    3D Artist
                                                                                                </a>

                                                                                            </h3>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex pt-17">
                                                                                        <ul className="list-unstyled mb-1 d-flex flex-wrap">
                                                                                            <li>

                                                                                                <a className="bg-regent-opacity-15 text-denim font-size-3 rounded-15 min-width-px-100 px-3 flex-all-center mr-6 h-px-33 mt-4">
                                                                                                    <i className="icon icon-pin-3 mr-2 font-weight-bold"></i>{" "}
                                                                                                    California
                                                                                                </a>

                                                                                            </li>
                                                                                            <li>

                                                                                                <a className="bg-regent-opacity-15 text-orange font-size-3 rounded-15 min-width-px-100 px-3 flex-all-center mr-6 h-px-33 mt-4">
                                                                                                    <i className="fa fa-briefcase mr-2 font-weight-bold"></i>{" "}
                                                                                                    Remote
                                                                                                </a>

                                                                                            </li>
                                                                                        </ul>

                                                                                        <a className="bookmark-button toggle-item font-size-6 ml-auto line-height-reset px-0 mt-6 text-default-color"></a>

                                                                                    </div>
                                                                                </div>
                                                                                {/* <!-- End Single Featured Job --> */}
                                                                            </div>
                                                                            <div className="col-lg-11 col-md-11 col-sm-11 mb-9">
                                                                                {/* <!-- Single Featured Job --> */}
                                                                                <div className="pt-9 px-xl-9 px-lg-7 px-7 pb-7 light-mode-texts bg-white rounded hover-shadow-3">
                                                                                    <div className="media align-items-center">
                                                                                        <div className="square-52 bg-orange-2 mr-8 rounded">

                                                                                            <a>
                                                                                                <img src={img3.src} alt="" />
                                                                                            </a>

                                                                                        </div>
                                                                                        <div>

                                                                                            <a className="font-size-3 text-default-color line-height-2">
                                                                                                Thunder
                                                                                            </a>

                                                                                            <h3 className="font-size-6 mb-0">

                                                                                                <a className="heading-default-color font-weight-semibold">
                                                                                                    Product Manager
                                                                                                </a>

                                                                                            </h3>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex pt-17">
                                                                                        <ul className="list-unstyled mb-1 d-flex flex-wrap">
                                                                                            <li>

                                                                                                <a className="bg-regent-opacity-15 text-denim font-size-3 rounded-3 min-width-px-100 px-3 flex-all-center mr-6 h-px-33 mt-4">
                                                                                                    <i className="icon icon-pin-3 mr-2 font-weight-bold"></i>{" "}
                                                                                                    London
                                                                                                </a>

                                                                                            </li>
                                                                                            <li>

                                                                                                <a className="bg-regent-opacity-15 text-orange font-size-3 rounded-3 min-width-px-100 px-3 flex-all-center mr-6 h-px-33 mt-4">
                                                                                                    <i className="fa fa-briefcase mr-2 font-weight-bold"></i>{" "}
                                                                                                    Full-time
                                                                                                </a>

                                                                                            </li>
                                                                                        </ul>

                                                                                        <a className="bookmark-button toggle-item font-size-6 ml-auto line-height-reset px-0 mt-6 text-default-color"></a>

                                                                                    </div>
                                                                                </div>
                                                                                {/* <!-- End Single Featured Job --> */}
                                                                            </div>
                                                                            <div className="col-lg-11 col-md-11 col-sm-11 mb-9">
                                                                                {/* <!-- Single Featured Job --> */}
                                                                                <div className="pt-9 px-xl-9 px-lg-7 px-7 pb-7 light-mode-texts bg-white rounded hover-shadow-3">
                                                                                    <div className="media align-items-center">
                                                                                        <div className="square-52 bg-helio mr-8 rounded">

                                                                                            <a>
                                                                                                <img src={img4.src} alt="" />
                                                                                            </a>

                                                                                        </div>
                                                                                        <div>

                                                                                            <a className="font-size-3 text-default-color line-height-2">
                                                                                                Shark
                                                                                            </a>

                                                                                            <h3 className="font-size-6 mb-0">

                                                                                                <a className="heading-default-color font-weight-semibold">
                                                                                                    Front-end Developer
                                                                                                </a>

                                                                                            </h3>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex pt-17">
                                                                                        <ul className="list-unstyled mb-1 d-flex flex-wrap">
                                                                                            <li>

                                                                                                <a className="bg-regent-opacity-15 text-denim font-size-3 rounded-3 min-width-px-100 px-3 flex-all-center mr-6 h-px-33 mt-4">
                                                                                                    <i className="icon icon-pin-3 mr-2 font-weight-bold"></i>{" "}
                                                                                                    Alabama
                                                                                                </a>

                                                                                            </li>
                                                                                            <li>

                                                                                                <a className="bg-regent-opacity-15 text-orange font-size-3 rounded-3 min-width-px-100 px-3 flex-all-center mr-6 h-px-33 mt-4">
                                                                                                    <i className="fa fa-briefcase mr-2 font-weight-bold"></i>{" "}
                                                                                                    Full-time
                                                                                                </a>

                                                                                            </li>
                                                                                        </ul>

                                                                                        <a className="bookmark-button toggle-item font-size-6 ml-auto line-height-reset px-0 mt-6 text-default-color  clicked"></a>

                                                                                    </div>
                                                                                </div>
                                                                                {/* <!-- End Single Featured Job --> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <!-- Excerpt End --> */}
                                                                </Tab.Pane>
                                                            </Tab.Content>
                                                            {/* <!-- Tab Content End --> */}
                                                            {/* <!-- Tab Section End --> */}
                                                        </div>
                                                    </Tab.Container>
                                                </div>
                                                {/* <!-- Middle Content --> */}
                                                {/* <!-- Right Sidebar Start 
              <div className="col-12 col-xxl-3 col-md-4 offset-xxl-0 offset-lg-4 offset-md-5 order-3 order-xl-2 mt-xxl-0 mt-md-12">
                <div className="pl-lg-5">
                  <h4 className="font-size-6 font-weight-semibold mb-0">
                    Other experts
                  </h4>
                   
                </div>
              </div>--> */}
                                                {/* <!-- Right Sidebar End --> */}
                                            </div>
                                        </div></div></div></div></div></div>
                }
            </>
        );
    }
};
export default ProfileView;